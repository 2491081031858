import React from "react";
import {RouteComponentProps} from "react-router";
import {inject, observer} from "mobx-react";
import {TracesStore} from "../../stores/traces/tracesStore";
import {InjectNames} from "../../stores/initializeStores";
import {observable} from "mobx";
import {AnnotationStore} from "../../stores/annotationStore/annotationStore";
import RealtimeChart from "./RealTimeChart";
import {Col, Row, Select} from "antd";
import {DevicesStore, IDeviceDto} from "../../stores/devicesStore/devicesStore";
import {DeviceOnlineStatus} from "../../services/api/ProCvtClient";
import FormItem from "antd/es/form/FormItem";
import './PersonRealTimeTrace.css'

interface IProps extends RouteComponentProps {
    tracesStore?: TracesStore
    annotationStore?: AnnotationStore
    devicesStore?: DevicesStore
    subjectId: number
}

@inject(InjectNames.tracesStore, InjectNames.devicesStore)
@observer
export class PersonRealTimeTrace extends React.Component<IProps> {

    @observable deviceId?: string;
    @observable deviceList: IDeviceDto[] = []


    async componentDidMount() {
        this.loadDeviceId();
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.subjectId !== this.props.subjectId) {
            this.loadDeviceId();
        }
    }


    loadDeviceId = async () => {
        this.deviceList = await this.props.devicesStore!.getDevices({personId: this.props.subjectId})
    }


    render() {
        const deviceSelect = <FormItem label={'Select device'}>
            <Select value={this.deviceId} className={'device-select'}
                    onChange={(value: string | undefined) => this.deviceId = value}>
                <Select.OptGroup label={DeviceOnlineStatus.ONLINE}>
                    {this.deviceList
                        .filter(x => x.status === DeviceOnlineStatus.ONLINE)
                        .map(device => (
                            <Select.Option key={device.deviceName}>{device.deviceName}</Select.Option>))}
                </Select.OptGroup>
                <Select.OptGroup label={DeviceOnlineStatus.OFFLINE}>
                    {this.deviceList
                        .filter(x => x.status === DeviceOnlineStatus.OFFLINE)
                        .map(device => (
                            <Select.Option key={device.deviceName}>{device.deviceName}</Select.Option>))}
                </Select.OptGroup>
            </Select>
        </FormItem>
        return <Row>
            <Col span={24}>
                <div>{deviceSelect}</div>
            </Col>
            <Col span={24}>{
                !!this.deviceId && <RealtimeChart key={`${this.deviceId}`} {...this.props} deviceId={this.deviceId!}/>
            }</Col>
        </Row>
    }
}
