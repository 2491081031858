import React from "react";
import {TracesTable} from "../../../components/Traces/TracesTable";
import {RouteComponentProps} from "react-router";
import {Badge, Card, Col, Dropdown, Icon, Menu, Radio, Row} from "antd";
import {IGetTracesInput, TracesStore} from "../../../stores/traces/tracesStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {UserStore} from "../../../stores/user/userStore";
import {action, observable} from "mobx";
import {errorUtils} from "../../../services/utils/ErrorUtils";
import {TraceType} from "../../../services/api/ProCvtClient";
import {ChartStore} from "../../../stores/chartStore/chartStore";
import {AnnotationStore} from "../../../stores/annotationStore/annotationStore";
import {ResidentsStore} from "../../../stores/residents/residentsStore";
import {PersonOverviewScene} from "./PersonOverviewScene";

interface IRouteProps {
    subjectId?: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
    tracesStore?: TracesStore
    annotationStore?: AnnotationStore
    userStore?: UserStore
    residentsStore?: ResidentsStore
    chartStore?: ChartStore
}

@inject(InjectNames.tracesStore, InjectNames.userStore,
    InjectNames.chartStore, InjectNames.annotationStore,
    InjectNames.residentsStore
)
@observer
export class TracesScene extends React.Component<IProps> {
    @observable subjectId?: number;
    @observable tableLoading = true;
    @observable tableTraceType?: TraceType = TraceType.SLEEP;

    async componentDidMount() {
        this.subjectId = !!this.props.match.params.subjectId ? Number.parseInt(this.props.match.params.subjectId) : undefined
        await this.loadTraces()
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.match.params.subjectId !== this.props.match.params.subjectId) {
            this.subjectId = !!this.props.match.params.subjectId ? Number.parseInt(this.props.match.params.subjectId) : undefined
            await this.loadTraces()
        }
    }

    @action
    handelChangeTableTraceType = async (e: any) => {
        this.tableTraceType = e.target.value;
        await this.loadTraces();
    };

    loadTraces = async () => {
        const input: IGetTracesInput = {
            offset: 0,
            /**Fix.*/
            rowCount: 1000,
            subjectId: this.subjectId,
            traceType: this.tableTraceType
        };
        await errorUtils.execute({context: this, loadingFieldName: "tableLoading"}, async () => {
            await this.props.tracesStore!.loadTraces(input)
        })
    };

    render() {
        const tracesTable =
            <TracesTable {...this.props}
                         isShowSubjectName={this.props.userStore!.isPractitioner && !this.subjectId}
                         comparable={true} loading={this.tableLoading}
                         routeToTrace={(subjectId:number) => this.props.history.push(PersonOverviewScene.routePaths.sessionFormat(subjectId))}/>

        const toChart = <Badge style={{background: '#aaaaaa'}} offset={[10, 0]}
                               count={this.props.tracesStore!.selectedTraces.length} showZero={true}>
            <span>
                <Dropdown.Button icon={<Icon type={'down'}/>}
                                 onClick={() => this.props.history.push({pathname: '/traces/compare'})}
                                 trigger={['click']}
                                 overlay={
                                     <Menu>
                                         <Menu.Item key='clear'
                                                    onClick={() => {
                                                        this.props.tracesStore!.clearSelected()
                                                    }}>Clear all selected traces
                                         </Menu.Item>
                                         <Menu.Item key='view-selected'
                                                    onClick={this.props.tracesStore!.moveSelectedToTraces}>View selected
                                             traces
                                         </Menu.Item>
                                     </Menu>
                                 }>To Chart
                </Dropdown.Button>
            </span>
        </Badge>;

        return (
            <React.Fragment>
                <Row style={{paddingBottom: '30px'}}>
                    {toChart}
                </Row>

                <Card title={'Traces table'}>
                    <Col style={{paddingBottom: '15px'}}>
                        <Radio.Group value={this.tableTraceType} disabled={this.tableLoading}
                                     onChange={this.handelChangeTableTraceType}>
                            <Radio.Button value={TraceType.SLEEP}>{TraceType.SLEEP}</Radio.Button>
                            <Radio.Button value={TraceType.STRESS}>{TraceType.STRESS}</Radio.Button>
                            <Radio.Button value={TraceType.FATIGUE}>{TraceType.FATIGUE}</Radio.Button>
                            <Radio.Button value={undefined}>All</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col>
                        {tracesTable}
                    </Col>
                </Card>
            </React.Fragment>
        );
    }
}
