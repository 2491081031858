import * as React from 'react';
import {CSSProperties} from 'react';
import {inject, observer} from "mobx-react";
import {computed, observable} from "mobx";
import {Avatar, Modal} from "antd";
import {InjectNames} from "../../stores/initializeStores";
import {IResidentSummaryDto, ResidentsStore} from "../../stores/residents/residentsStore";
import {RouteComponentProps} from "react-router";
import "./ResidentsCardByRoom.less"
import {AlertsStore} from "../../stores/alertsStore/alertsStore";
import {PersonOverviewScene} from "../../scenes/authorized/Traces/PersonOverviewScene";
import {SpinOnCenter} from "../Loading/SpinOnCenter";

interface IProps extends RouteComponentProps {
    residentsStore?: ResidentsStore
    alertsStore?: AlertsStore
}

@inject(InjectNames.residentsStore, InjectNames.alertsStore)
@observer
class ResidentsCardByRoom extends React.Component<IProps> {
    async componentDidMount() {
        this.load()
    }

    @observable residents: IResidentSummaryDto[] = []
    @observable loading = false

    load = async () => {
        try {
            this.loading = true
            this.residents = await this.props.residentsStore!.getResidentsSummary()
        } finally {
            this.loading = false
        }
    }

    @computed get riskActiveClinical() {
        return this.props.alertsStore!.activeAlertsSortedByRisk.filter(alert => alert.riskType === 'CLINICAL')
    }

    @computed get riskActiveOtherClinical() {
        return this.props.alertsStore!.activeAlertsSortedByRisk.filter(alert => alert.riskType !== 'CLINICAL')
    }


    @computed get summarySorted() {
        return this.residents
            .map(res => {
                const alert = this.props.alertsStore!.activeAlertsSortedByRisk.find(x => x.personId === res.personId)
                return {
                    ...res,
                    active: !!alert?.active ? 1 : 0,
                    riskLevel: alert?.riskLevel ?? 0
                }
            })
            .sort((a, b) => (a.roomName ?? '').localeCompare(b.roomName ?? ''))
            .sort((a, b) => (b.roomOccupied ? 1 : 0) - (a.roomOccupied ? 1 : 0))
            .sort((a, b) => b.riskLevel - a.riskLevel)
            .sort((a, b) => b.active - a.active)
    }

    routeToPerson = (personId: number | undefined) => {
        if (!personId || personId < 0) {
            Modal.error({content: 'Person has not ID.'})
            return
        }
        this.props.history.push(PersonOverviewScene.routePaths.subjectFormat(personId))
    }

    render() {

        const alertBlock = (personId: number | undefined) => {
            let alert = this.props.alertsStore!.activeAlertsSortedByRisk
                .find(alert => alert.personId === personId)
            if (!alert)
                return <div></div>
            else
                return <React.Fragment>
                    <div className={'res-sum-col-1-status'}>
                        {alert.riskName}
                    </div>
                    <div className={'res-sum-col-1-status-description'}>
                        {alert.alertDesc}
                    </div>
                </React.Fragment>
        }

        const alertCardStyle = (personId: number | undefined) => {

            let alert = this.riskActiveClinical
                .find(x => x.personId === personId)

            if (!alert)
                alert = this.riskActiveOtherClinical
                    .find(x => x.personId === personId)

            if (!alert)
                return undefined
            else {
                let style: CSSProperties = {
                    backgroundColor: alert.color,
                    borderColor: alert.color,
                    color: "white"
                }
                return style
            }
        }

        return (
            <React.Fragment>
                {this.loading ? <SpinOnCenter/> : <div className={'res-sum-container'}>
                    {this.summarySorted.map(resSummary => (
                        <div className={'res-sum-card'} style={alertCardStyle(resSummary.personId)}>
                            <div className={'res-sum-body'}>
                                <div className={'res-sum-body-col-1'}>
                                    <div>
                                        {alertBlock(resSummary.personId)}
                                    </div>
                                    <div className={'res-sum-body-col-1-profile'}>
                                        <div className={'res-sum-body-col-1-profile-image'}>
                                            <Avatar shape={"circle"} size={'large'}/>
                                        </div>
                                        <div className={'res-sum-body-col-1-profile-name'}>
                                            {
                                                !resSummary.personId
                                                    ? <div>Unassigned</div>
                                                    : <div className={'res-sum-person'}
                                                           onClick={() => this.routeToPerson(resSummary.personId)}>
                                                        <div>{resSummary.firstName}</div>
                                                        <div>{resSummary.lastName}</div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={'res-sum-body-col-2'}>
                                    <div className={'res-sum-col-2-room-name'}>{resSummary.roomName ?? 'N/A'}</div>
                                    <div className={'res-sum-col-2-room-label'}>ROOM</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>}
            </React.Fragment>
        );
    }
}

export {ResidentsCardByRoom};
