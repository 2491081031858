import {ProCvtClient} from "../../services/api/ProCvtClient";
import {IDisposable} from "../initializeStores";
import {observable} from "mobx";

export class SurveyBotStore implements IDisposable {
    constructor(pbmSleepClient: ProCvtClient) {
        this.pbmSleepClient = pbmSleepClient!;
    }

    private readonly pbmSleepClient: ProCvtClient;

    @observable personId?: number;
    @observable initialization?: boolean;
    @observable loading:number[] = []

    dispose = async () => {
        this.personId = undefined
        this.initialization = undefined
        this.loading = []
    }
}
