import React from "react";
import {RouteComponentProps} from "react-router";
import {IStaffDto, UserStore} from "../../stores/user/userStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";
import {Col, Input, Row, Select, Table, Modal} from "antd";
import {ColumnProps} from "antd/lib/table/interface";
import {action, observable} from "mobx";
import {AccountStatus, StaffSearchType} from "../../services/api/ProCvtClient";
import {errorUtils} from "../../services/utils/ErrorUtils";
import {ActionsButton} from "./ActionsButton";
import {OrgUnitSelect} from "../Select/OrgUnitSelect";

interface IProps extends RouteComponentProps {
    userStore?: UserStore
}

@inject(InjectNames.userStore)
@observer
export class StaffTable extends React.Component<IProps> {
    @observable staffSearchType: StaffSearchType = StaffSearchType.Both;
    @observable orgUnitId?: number = undefined;
    @observable search?: string;
    @observable loading: boolean = false;

    async componentDidMount() {
        await this.loadTable();
    }

    loadTable = async () => {
        await errorUtils.execute({context: this, loadingFieldName: 'loading'}, async () => {
            await this.props.userStore!.loadStaffs({
                offset: 0,
                /**Fix.Hard Need use pagination*/
                rowCount: 1000,
                // rowCount: this.props.practitionerStore!.stats.subjectCount,
                search: this.search,
                staffSearchType: this.staffSearchType,
                orgUnitId: this.orgUnitId
            })
        })
    };
    @action
    handleChangeOrgUnit = (value: number) => {
        this.orgUnitId = value
        this.loadTable()
    }
    @action
    handleChangeStaffSearchType = (value: StaffSearchType) => {
        this.staffSearchType = value;
        this.loadTable()
    };
    @action
    handleSearch = (e: any) => {
        this.search = e.target.value;
        this.loadTable()
    };

    render() {
        const actions = [
            {
                title: 'Edit',
                action: (text: string, record: IStaffDto) => {
                    if(record.accountStatus === AccountStatus.PENDING)
                        Modal.info({content: "Unable edit account if status is Pending"})
                    else  this.props.history.push({pathname: `/staff/edit/${record.personId}`})
                },
            },
        ];

        const columns: ColumnProps<IStaffDto>[] = [
            {title: 'Type', width: 100, dataIndex: 'staffType'},
            {title: 'Email', width: 200, dataIndex: 'email'},
            {title: 'Name', width: 200, dataIndex: 'name'},
            {title: 'Account', width: 200, dataIndex: 'accountStatus'},
            {
                title: 'Actions',
                width: 150,
                render: (text: string, record: any) => (<ActionsButton text={text} record={record} actions={actions}/>),
            },
        ];

        const search = <Row type={"flex"} justify={"end"} gutter={15}>
            <Col>
                <Input placeholder="Search..." style={{marginBottom: '15px', width: '300px'}}
                       value={this.search}
                       onChange={this.handleSearch}/>
            </Col>
            {this.props.userStore!.isSuperUser &&
            <Col>
                <OrgUnitSelect value={this.orgUnitId} onChange={this.handleChangeOrgUnit}/>
            </Col>
            }
            <Col>
                <Select value={this.staffSearchType} onChange={this.handleChangeStaffSearchType}>
                    <Select.Option value={StaffSearchType.Both}>BOTH</Select.Option>
                    <Select.Option value={StaffSearchType.Family}>FAMILY</Select.Option>
                    <Select.Option value={StaffSearchType.Staff}>STAFF</Select.Option>
                </Select>
            </Col>
        </Row>
        return (
            <React.Fragment>
                {search}
                <Table dataSource={this.props.userStore!.staffList} columns={columns}
                       rowKey={record => `${record.userId}`} loading={this.loading}/>
            </React.Fragment>
        );
    }
}
