/**
 * Issues:
 * Doesn't clear "series-line.emphasis.scale" during dragging or zooming https://github.com/apache/echarts/issues/15391
 * */

import * as React from "react";
// @ts-ignore
import ReactEcharts, {EventMap, optsMap} from "echarts-for-react";
import {action, computed, observable} from "mobx";
import {observer} from "mobx-react";
import './Chart.css'
import './Legends.less'
import {Col, Icon, Row} from "antd";
import {
    ChartValueXType,
    FilterMode,
    ICachedChartValue,
    IChartPointMark,
    IChartSeriesInfo,
    IChartValue,
    ILegendOptions,
    IMovingAverageOption,
    IOnPointClickOptions,
    IParallelYAxisOption,
    ISamplingType,
    IToolTipParams,
    MaxAxisType,
    MinAxisType
} from "./IChartSeriesInfo";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";
import {graphic} from 'echarts';
import {EChartsOption} from "echarts-for-react/src/types";

export interface IChartProps {
    sampling?: ISamplingType
    visualMap?: any[],
    rerenderKey?: string
    chartDpi?: (monitorDPI: number) => number;
    notMerge?: boolean,
    chartSeries: IChartSeriesInfo[];
    legendFormatter: (chartInfoList: IChartSeriesInfo[]) => ILegendOptions[];
    displayIndex?: boolean;
    title?: string;
    loading?: boolean;
    opts?: optsMap;
    zoomFilterMode?: FilterMode;
    movingAverageOption?: IMovingAverageOption[];
    disableAutoScale?: boolean;
    hideToolbox?: boolean;
    hideZoom?: boolean;
    showSymbols?: boolean;
    xAxisType: 'time' | 'value' | 'category';
    xAxisMin?: MinAxisType;
    xAxisMax?: MaxAxisType;
    xAxisHideTick?: boolean;
    xAxisHideLabel?: boolean;
    parallelYAxis: IParallelYAxisOption[];
    seriesType?: 'bar' | 'line';
    indexAxisFormatter?: (index: number) => string;
    xAxisFormatter?: (value: ChartValueXType) => string;
    tooltipFormatter?: (values: IToolTipParams[]) => string;
    onSelectedSeries?: (seriesId: string | undefined) => void;
    onDeletedSeries?: (legendId: string) => void;
    onPointClickOptions?: IOnPointClickOptions;
    onMarkClick?: (dataValue: IChartPointMark, seriesId: string) => void;
    onZoomChanged?: () => void;
}


@observer
export class Chart extends React.Component<IChartProps> {
    chartRef: any;
    chartInstance: any;
    readonly cacheForCalcMvAvg: { [value: string]: { key: string, processed: { [x: string]: ICachedChartValue } } } = {};
    readonly defaultZoomFilterMode: FilterMode = "filter";
    readonly defaultOpts: optsMap = {
        renderer: 'canvas',
        width: undefined,
        height: 500,
    };
    readonly gridMarginX = 80

    /*@observable - chartInstance.setOption instead MobX*/ actionButtons: any[] = []
    zoomValueStart: number | undefined = undefined;
    zoomValueEnd: number | undefined = undefined;
    zoomPercentStart = 0;
    zoomPercentEnd = 100;
    private monitorDPI = this.calcScreenDPI()
    /**
     * This is the main option to manage chart DPI
     * */
    private readonly chartDPI = !!this.props.chartDpi ? this.props.chartDpi(this.monitorDPI) : this.monitorDPI / 2;
    @observable maxPoints: number = 100;

    @computed get maxDataCount() {
        let max = 0
        this.props.chartSeries.forEach(x => {
            if (x.data.length > max) max = x.data.length
        })
        return max;
    }

    @computed get movingAverage() {
        return !this.props.movingAverageOption?.length
            ? []
            : this.props.movingAverageOption?.slice().map(x => ({value: x.value, movingAverageId: x.movingAverageId}))
    }

    @computed get showSymbols() {
        return this.props.showSymbols
    }

    @observable _selectedSeriesId?: string;
    @observable selectedMouseSeriesId?: string;

    componentDidMount(): void {
        window.addEventListener("resize", this.updateMaxPointsCount)
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.updateMaxPointsCount)
    }

    componentDidUpdate(prevProps: Readonly<IChartProps>, prevState: Readonly<{}>, snapshot?: any): void {
        this.insertZoomPercentToChartAndGetValues()
    }

    // Chart sampling
    @computed get sampling() {
        return !!this.props.sampling ? this.props.sampling : "custom"
    }

    // Chart sampling - end

    // Chart hooks/events setter

    handleChartReady = (args: any) => {
        if (!!this.chartInstance) {
            // This may call this.someHook('off');
        }
        this.chartInstance = args;
        // This may call this.someHook('on');


        this.updateMaxPointsCount();
        this.chartInstance.getZr().on('click', this.handleZrClick)
        this.chartInstance.getZr().on('globalout', this.handleZrGlobalOut)

        this.insertZoomPercentToChartAndGetValues()
    };

    /*How can use chartInstance.getZr() events http://www.programmersought.com/article/421699134/
     * Need manual on/off ("this.chartInstance.getZr().on('mousemove', (params: any) => {})") instead of <ReactEcharts EventsDict= {EventsDict}/>
     * */

    /*Events and Actions in ECharts
    * http://man.hubwiz.com/docset/ECharts.docset/Contents/Resources/Documents/en/tutorial.html
    * */

    get EventsDict() {
        let events: EventMap = {};
        events.click = this.handleMouseClick;
        events.dataZoom = this.handleDataZoom;
        events.restore = this.handleDataZoom;
        return events;
    }

    // Chart hooks/events setter end

    // LegendHandler
    handleLegendSelect = (seriesId: string | undefined) => {
        if (this.selectedSeriesId === seriesId)
            this.selectedSeriesId = undefined;
        else
            this.selectedSeriesId = seriesId
    };
    handleLegendMouse = (seriesId: string | undefined) => {
        this.selectedMouseSeriesId = seriesId
    };

    @computed get selectedSeriesId() {
        return this._selectedSeriesId
    }

    set selectedSeriesId(seriesId) {
        this._selectedSeriesId = seriesId;
        if (this.props.onSelectedSeries !== undefined)
            this.props.onSelectedSeries(seriesId);
    }

    onDeletedSeries = (legendId: string, legendOptions: ILegendOptions) => {
        if (!!this.props.onDeletedSeries) {
            this.props.onDeletedSeries(legendId)
        }

        if (legendOptions.legends.some(x => x.seriesId === this.selectedSeriesId))
            this._selectedSeriesId = undefined
    }

    // LegendHandler end

    // MouseHandler
    handleZrClick = (_params: any) => {
        this.hideActionButtons()
        /*// Try find approximate data by x axis for other axisies
        const x: number = this.chartInstance.convertFromPixel("grid", [params.offsetX])[0]
        this.dataList.forEach(dataInfo => {
            dataInfo.data.forEach((data, index, dataSource) => {
                if ((x > (dataSource[index]?.x as Date).getTime() && x < (dataSource[index + 1]?.x as Date).getTime()) || x === (dataSource[index]?.x as Date).getTime()) {
                }
            });
        })*/
    };
    handleZrGlobalOut = (_params: any) => {
        this.hideActionButtons()
    };
    handleMouseClick = async (eventParams: any) => {
        // e.g., 'series', 'markLine', 'markPoint', 'timeLine' | http://man.hubwiz.com/docset/ECharts.docset/Contents/Resources/Documents/en/tutorial.html
        if (eventParams.componentType === "markPoint") {
            this.handleMarkClick(eventParams)
        }
        if (eventParams.componentType === "series") {
            this.handlePointClick(eventParams)
        }
    };
    handlePointClick = (eventParams: any) => {
        this.showActionButtons(eventParams)
    }
    handleMarkClick = (eventParams: any) => {
        const markPoint = this.dataList[eventParams.seriesIndex].pointMarks!.find(mark => mark.markPointId === eventParams.data.markPointId)!;
        if (this.props.onMarkClick !== undefined)
            this.props.onMarkClick(markPoint, this.dataList[eventParams.seriesIndex].seriesId);
    }
    readonly actionsGraphicKey = {
        actionGroup: "actionGroup",
        actionImage: "actionImage",
        actionText: "actionText",
    }
    // private latestActionButton: 'show' | 'hide' = "hide"
    showActionButtons = (eventParams: any) => {
        const options: IOnPointClickOptions | undefined | null = this.props.onPointClickOptions;
        const seriesPoint = this.props.displayIndex ? this.props.chartSeries[eventParams.seriesIndex]?.data[eventParams.data[2]] : this.dataList[eventParams.seriesIndex]?.data[eventParams.dataIndex];
        if (!!options?.actions?.length && !!seriesPoint) {
            const position = [eventParams.event.offsetX, eventParams.event.offsetY];
            const positionWithOffset = [(position[0]) - 20, position[1] - 40]

            const children: any[] = []
            options!.actions.forEach((itemOption, actionIndex, _dataSource) => {
                    children.push({
                        id: this.actionsGraphicKey.actionText + actionIndex,
                        type: 'text',
                        z: 100,
                        zlevel: 100,
                        ...itemOption.title,
                        onclick: () => {
                            itemOption.action(seriesPoint, this.dataList[eventParams.seriesIndex].seriesId);
                            this.hideActionButtons()
                        }
                    })
                    children.push({
                        id: this.actionsGraphicKey.actionImage + actionIndex,
                        type: 'image',
                        z: 100,
                        zlevel: 100,
                        ...itemOption.image,
                        onclick: () => {
                            itemOption.action(seriesPoint, this.dataList[eventParams.seriesIndex].seriesId);
                            this.hideActionButtons()
                        }
                    })
                }
            );
            this.actionButtons = [{
                id: this.actionsGraphicKey.actionGroup,
                type: 'group',
                draggable: true,
                // position: positionWithOffset,
                x: positionWithOffset[0],
                y: positionWithOffset[1],
                children: children,
            }]
            this.chartInstance.setOption({
                graphic: this.actionButtons
            }, false)
        }
        // this.latestActionButton = "show"
    };
    hideActionButtons = () => {
        // if (this.latestActionButton !== "hide") {
        //     const action = 'remove';
        //     this.actionButtons = [
        //         {id: this.actionsGraphicKey.actionGroup, $action: action},
        //         ...this.props.onPointClickOptions!.actions.map((item, index) => ({
        //             id: this.actionsGraphicKey.actionText + index,
        //             $action: action
        //         })),
        //         ...this.props.onPointClickOptions!.actions.map((item, index) => ({
        //             id: this.actionsGraphicKey.actionImage + index,
        //             $action: action
        //         })),
        //     ]
        this.actionButtons = []
        this.chartInstance.setOption({
            ...this.chartInstance.getOption(),
            graphic: this.actionButtons
        }, true)
        // }
        // this.latestActionButton = 'hide'
    }
    // MouseHandler end

    // ZoomHandler
    @observable private zoomPercent = this.zoomPercentEnd - this.zoomPercentStart

    calcScreenDPI() {
        const el: any = document.createElement('div');
        el.style = 'width: 1in;'
        document.body.appendChild(el);
        const dpi = el.offsetWidth;
        document.body.removeChild(el);
        return dpi;
    }

    @action updateMaxPointsCount = () => {
        if (!!this.chartInstance) {
            const chartInch = this.chartInstance.getWidth() / this.monitorDPI;
            this.maxPoints = Math.round(chartInch * this.chartDPI)
        }
    }

    @action
    handleDataZoom = (params: any) => {
        if (params.type === 'restore') {
            this.chartInstance.dispatchAction({
                type: 'dataZoom',
                start: 0,
                end: 100,
            })
        } else if (params.batch !== undefined && params.batch[0] !== undefined && params.batch[0].startValue !== undefined) {
            // handleDataZoomToolbox
            this.chartInstance.dispatchAction({
                type: 'dataZoom',
                startValue: params.batch[0].startValue,
                endValue: params.batch[0].endValue,
            })
        } else {
            this.retrieveZoomFromChart()
        }
    };


    private retrieveZoomFromChart = () => {
        const chrZoom: any = this.chartInstance.getOption().dataZoom[0]

        let zoom = {
            zoomPercentStart: chrZoom.start,
            zoomPercentEnd: chrZoom.end,
            zoomValueStart: chrZoom.startValue,
            zoomValueEnd: chrZoom.endValue
        }

        // set to 0 - 100 percent
        if (zoom.zoomValueStart === undefined || zoom.zoomValueStart === null || isNaN(zoom.zoomValueStart) ||
            zoom.zoomValueEnd === undefined || zoom.zoomValueEnd === null || isNaN(zoom.zoomValueEnd)) {
            let min: number | undefined = undefined
            let max: number | undefined = undefined

            if (this.props.displayIndex) {
                min = 0
                this.dataList?.forEach(x => {
                    if (!!x.data?.length) {
                        if (max === undefined || x.data.length - 1 > max) {
                            max = x.data.length - 1
                        }
                    }
                })
            } else {
                this.props.chartSeries?.forEach(x => {
                    if (!!x.data?.length) {
                        const startItem: number = x.data[0].x instanceof Date ? (x.data[0].x as Date).getTime() : x.data[0].x as number;
                        if (min === undefined || startItem < min) {
                            min = startItem
                        }

                        const endItem: number = x.data[x.data.length - 1].x instanceof Date ? (x.data[x.data.length - 1].x as Date).getTime() : x.data[x.data.length - 1].x as number;
                        if (max === undefined || endItem > max) {
                            max = endItem
                        }
                    }
                })
            }


            if (zoom.zoomValueStart === undefined || zoom.zoomValueStart === null || isNaN(zoom.zoomValueStart)) {
                // @ts-ignore
                zoom.zoomValueStart = min
            }
            if (zoom.zoomValueEnd === undefined || zoom.zoomValueEnd === null || isNaN(zoom.zoomValueEnd)) {
                // @ts-ignore
                zoom.zoomValueEnd = max
            }
        }

        if (this.props.displayIndex) {
            if ((this.props.chartSeries?.length && this.dataList?.length)) {
                // @ts-ignore
                zoom.zoomValueStart = this.props.chartSeries[0].data[this.dataList[0].data[zoom.zoomValueStart].index].x
                // @ts-ignore
                zoom.zoomValueEnd = this.props.chartSeries[0].data[this.dataList[0].data[zoom.zoomValueEnd].index].x
            }
        }

        this.zoomPercentStart = zoom!.zoomPercentStart;
        this.zoomPercentEnd = zoom!.zoomPercentEnd;

        this.zoomValueStart = zoom.zoomValueStart ? Math.round(zoom.zoomValueStart) : zoom.zoomValueStart;
        this.zoomValueEnd = zoom.zoomValueEnd ? Math.round(zoom.zoomValueEnd) : zoom.zoomValueEnd;

        this.updateZoomPercent()
    };

    private insertZoomPercentToChartAndGetValues = () => {
        if (!!this.props.hideZoom) {
            this.updateZoomPercent()
            return
        }
        this.chartInstance.setOption({
            dataZoom: this.chartInstance.getOption()?.dataZoom?.map((x: any) => ({
                ...x,
                start: this.zoomPercentStart,
                end: this.zoomPercentEnd,
            }))
        })
        const dataZoom = this.chartInstance.getOption()?.dataZoom[0]

        this.zoomValueStart = dataZoom.startValue
        this.zoomValueEnd = dataZoom.endValue
        this.updateZoomPercent()
    }

    // for "custom" sampling
    private updateZoomPercent = () => {
        if (this.sampling === "custom") {
            const currentZoomRound = Math.round(this.zoomPercent)
            const newZoomRound = Math.round(this.zoomPercentEnd - this.zoomPercentStart)
            const diff = Math.abs(currentZoomRound - newZoomRound)
            // percent values are reactive - do not change to prevent re-render
            if (diff !== 0) {
                // TODO toolbox zoom is disabled after this action
                this.zoomPercent = this.zoomPercentEnd - this.zoomPercentStart;
            }
        }
        if (!!this.props.onZoomChanged)
            this.props.onZoomChanged()
    }

    // ZoomHandler end

    // Chart config
    @computed get graphic() {
        let result = [];
        result.push(...this.actionButtons)
        return result;
    }

    @computed get opts() {
        return !!this.props.opts ? this.props.opts : this.defaultOpts
    }

    getColor = (seriesIndex: number, color: string, colorOpacity: string) => {
        if (this.isOpacityByIndex(seriesIndex)) {
            return colorOpacity
        }
        return color;
    };

    isOpacityByIndex(seriesIndex: number) {
        return (this.selectedSeriesId !== undefined &&
            this.dataList[seriesIndex].seriesId !== this.selectedSeriesId)
    };

    private calculateChartStep = (pointsCount: number) => {
        if (this.sampling === "custom") {
            const pointCountProportion = this.maxDataCount / pointsCount
            const zoomPercentForCurrentSeries = (this.zoomPercent * pointCountProportion) / 100
            let chartStep = Math.round((pointsCount * zoomPercentForCurrentSeries) / this.maxPoints);
            return chartStep < 1 ? 1 : chartStep;
        } else {
            return undefined
        }
    };

    @computed get isEmptyData(): boolean {
        return !this.dataList?.length
    }

    /**
     * That public value, because should be able to retrieve avg mv-ed and filtered by step data.
     * */
    @computed
    public get dataList(): IChartSeriesInfo[] {
        if (this.props.loading || !this.props.chartSeries?.length)
            return [];
        return this.props.chartSeries.map((chartInfo) => {
            return {
                ...chartInfo,
                data: this.chartByMovingAverageAndStepByZoom(chartInfo),
            }
        });
    }

    private chartByMovingAverageAndStepByZoom(chartInfo: IChartSeriesInfo) {
        const chartValues: IChartValue[] = !this.props.displayIndex ? chartInfo.data : chartInfo.data.map((item, index, _dataSource) => ({
            x: index,
            y: item.y,
            index: item.index,
            json: item.json
        }))
        let result: ICachedChartValue[] = [];
        const chartStep = this.calculateChartStep(chartValues.length);
        let movingAverageIndex: number | undefined = this.movingAverage.findIndex(x => x.movingAverageId === chartInfo.movingAverageId)

        const key = `${chartStep}_${movingAverageIndex !== -1 ? this.movingAverage[movingAverageIndex].value : -1}`
        const useDynamicCache = chartInfo.useCachedMvAvg

        chartValues.forEach((chartValue, chartValueIndex) => {
            if (chartValueIndex === 0) {
                result.push({...chartValue, rowY: chartValue.y});
            } else if (this.sampling !== "custom" || chartValueIndex % chartStep! === 0 || chartValueIndex === chartValues.length - 1) {
                if (movingAverageIndex === -1 || !this.movingAverage?.length)
                    result.push({...chartValue, rowY: chartValue.y});
                else if (this.movingAverage[movingAverageIndex!].value <= 1) {
                    result.push({...chartValue, rowY: chartValue.y});
                } else if (useDynamicCache === true &&
                    this.cacheForCalcMvAvg[chartInfo.seriesId]?.key === key &&
                    this.cacheForCalcMvAvg[chartInfo.seriesId]?.processed?.[chartValue.x.toString()]?.rowY === chartValue.y) {
                    result.push(this.cacheForCalcMvAvg[chartInfo.seriesId]?.processed?.[chartValue.x.toString()])
                } else {
                    let movingAvgStart = chartValueIndex - this.movingAverage[movingAverageIndex!].value;
                    const movingAvgEnd = chartValueIndex + 1;/*+1 because "slice" exclude the end value*/
                    if (movingAvgStart <= 0) {
                        movingAvgStart = 0;
                    }
                    let slicedInterval = chartValues.slice(movingAvgStart, movingAvgEnd);
                    let slicedSum = 0;
                    let slicedCount = 0;
                    slicedInterval.forEach((slicedValue) => {
                        // Do not calculate if null or 0;
                        if (!!slicedValue.y) {
                            slicedSum += slicedValue.y;
                            slicedCount++;
                        }
                    });
                    let avg: ICachedChartValue = {
                        x: chartValue.x,
                        y: (slicedSum / (slicedCount === 0 ? 1 : slicedCount)),
                        index: chartValue.index,
                        json: chartValue.json,
                        rowY: chartValue.y
                    };
                    result.push(avg);
                }
            }
        });
        if (useDynamicCache) {
            this.cacheForCalcMvAvg[chartInfo.seriesId] = {key: key, processed: {}}
            result.slice().forEach(x => this.cacheForCalcMvAvg[chartInfo.seriesId].processed[x.x.toString()] = x)
        }
        return result;
    };

    @computed get tooltipFormatter() {
        return !this.props.tooltipFormatter ? this.defaultTooltipFormatter : (params: any) => {
            return this.props.tooltipFormatter!(params.map((item: any) => {
                const chartValue: IToolTipParams = {
                    x: item.data[0],
                    y: item.data[1],
                    index: item.data[2],
                    json: item.data[3],
                    params: item
                };
                return chartValue;
            }))
        }
    }

    @computed get defaultTooltipFormatter() {
        return undefined
    }

    @computed get xAxisFormatter() {
        return !!this.props.xAxisFormatter
            ? this.props.xAxisFormatter!
            : this.defaultXAxisFormatter!
    }

    @computed get sliderZoomXAxisFormatter() {
        return this.props.displayIndex ? (value: any, valueStr: string) => this.xAxisFormatter(Number.parseInt(valueStr)) : this.xAxisFormatter
    }

    @computed get defaultXAxisFormatter() {
        return this.props.xAxisType === 'time'
            ? (value: Date | number) => dateTimeHelper.day_month_year(value as Date)
            : undefined
    }

    @computed get option() {
        let result: EChartsOption = {
            grid: {
                right: this.gridMarginX,
                left: this.gridMarginX
            },
            // hoverLayerThreshold: 30,
            // legend: {
            //     data: [],
            //     type: 'scroll',
            //     width: '60%',
            //     selectedMode: 'multiple',
            //     selector: this.dataList.length > 1 && [
            //         {
            //             type: 'all',
            //             // can be any title you like
            //             title: 'All'
            //         },
            //         {
            //             type: 'inverse',
            //             title: 'INVERSE'
            //         }
            //     ],
            //     selectorLabel: {fontSize: 14, padding: 5, borderRadius: 0}
            // },
            tooltip: {
                trigger: 'axis',
                position: function (pos: [number, number], params: any, dom: any, rect: any, size: { contentSize: [number, number], viewSize: [number, number] }) {
                    const offset = 60
                    if (pos[0] + offset + size.contentSize[0] > size.viewSize[0])
                        return [pos[0] - offset - size.contentSize[0], '10%'];
                    else
                        return [pos[0] + offset, '10%'];
                },
                formatter: this.tooltipFormatter,
                backgroundColor: 'rgba(255,255,255,0.9)',
                textStyle: {
                    color: '#000000'
                },
            },
            title: {
                left: 'left',
                text: this.props.title,
            },
            toolbox: !this.props.hideToolbox && {
                showTitle: true,
                right: this.gridMarginX,
                feature: {
                    // dataView: {},
                    // TODO see issue with 'this.zoomPercent = this.zoomPercentEnd - this.zoomPercentStart;'
                    // dataZoom: {
                    //     title: {
                    //         zoom: 'ZOOM',
                    //         // Only for toolbox zoom
                    //         back: 'RESTORE'
                    //     },
                    //     yAxisIndex: 'none',
                    //     filterMode: !this.props.zoomFilterMode ? this.defaultZoomFilterMode : this.props.zoomFilterMode,
                    // },
                    restore: {title: 'RESTORE'},
                    saveAsImage: {title: 'IMAGE'}
                }
            },
            dataZoom: !this.props.hideZoom && [
                {
                    type: 'inside',
                    // throttle: 100,
                    xAxisIndex: [0, 1],
                    filterMode: !this.props.zoomFilterMode ? this.defaultZoomFilterMode : this.props.zoomFilterMode,
                },
                {
                    xAxisIndex: [0, 1],
                    type: 'slider',
                    throttle: 100,
                    // Whether to update view while dragging. If it is set as false, the view will be updated only at the end of dragging.
                    realtime: false,
                    // handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    },
                    filterMode: !this.props.zoomFilterMode ? this.defaultZoomFilterMode : this.props.zoomFilterMode,
                    labelFormatter: this.sliderZoomXAxisFormatter
                }
            ],
            xAxis: [{
                type: this.props.displayIndex ? "category" : this.props.xAxisType,
                min: !!this.props.xAxisMin ? this.props.xAxisMin : "dataMin",
                max: !!this.props.xAxisMax ? this.props.xAxisMax : "dataMax",
                axisTick: {
                    show: !this.props.xAxisHideTick
                },
                axisLabel: {
                    formatter: this.xAxisFormatter,
                    show: !this.props.xAxisHideLabel
                },
            },
                {
                    data: [], type: "category", axisLabel: {
                        formatter: this.props.indexAxisFormatter
                    }
                }
            ],
            yAxis: [
                ...(!this.props.parallelYAxis?.length ? [] : this.props.parallelYAxis!.map((axis, _axisIndex) => ({
                    type: 'value',
                    show: !axis.hideAll,
                    name: !axis.hideName ? axis.yAxisName : undefined,
                    min: axis.min,
                    max: axis.max,
                    position: axis.position,
                    offset: axis.offset,
                    axisTick: {
                        show: !axis.yAxisHideTick
                    },
                    axisLabel: {
                        show: !axis.yAxisHideValue
                    },
                }))),
            ],
            series: [],
            visualMap: [...(this.props.visualMap ?? [])],
        };

        this.dataList.forEach((chartInfo, seriesIndex) => {
            // if (!this.props.hideLegend) {
            //     result.legend.data.push({name: chartInfo.name});
            // }

            const symbolSize = 4;
            const shared = {
                animation: false,
                smooth: false,
                large: true,
                sampling: this.sampling,
                // selectedMode: 'multiple',
                emphasis: {
                    scale: true,
                    lineStyle: {
                        width: 1,
                    },
                },
                lineStyle: {
                    width: 1,
                },
                // itemStyle: {color: this.getColor(seriesIndex, chartInfo.color)},
                itemStyle: {
                    color: chartInfo.gradientX === undefined
                        ? this.getColor(seriesIndex, chartInfo.color, chartInfo.colorOpacity)
                        : new graphic.LinearGradient(
                            0, 0, 1, 0,
                            chartInfo.gradientX.map(item => ({offset: item.offsetPercent, color: item.color}))
                        )
                },
                areaStyle: !chartInfo.showArea ? undefined : {
                    color: this.getColor(seriesIndex, chartInfo.color, chartInfo.colorOpacity)
                },
                name: chartInfo.name,
                type: this.props.seriesType || 'line',
                symbolSize: this.selectedMouseSeriesId === chartInfo.seriesId
                    ? this.showSymbols ? symbolSize + 2 : symbolSize
                    : this.showSymbols ? symbolSize : 0.1,
            }

            if (!!chartInfo.lineMark?.length) {
                const getValueFromDateOrReturnNumber = (value: Date | number | null | undefined) => value instanceof Date ? (value as Date)?.getTime() : (value as number)
                const whenIndex = (value: number) => this.props.chartSeries[seriesIndex].data.findIndex((seriesValue, index, dataSource) => {
                    if (dataSource[index]?.x instanceof Date)
                        return (value > (dataSource[index]?.x as Date)?.getTime() && value < (dataSource[index + 1]?.x as Date)?.getTime()) || value === (dataSource[index]?.x as Date)?.getTime()
                    return (value > dataSource[index]?.x && value < dataSource[index + 1]?.x) || value === dataSource[index]?.x
                })
                const chartDataReversed = chartInfo.data.slice().reverse()
                result.visualMap.push({
                    show: false,
                    type: 'piecewise',
                    pieces: chartInfo.lineMark?.map(lineMark => {
                        const startWhenIndex = whenIndex(lineMark.start)
                        const endWhenIndex = whenIndex(lineMark.end)

                        const piece = {
                            min: !this.props.displayIndex
                                ? this.sampling === "custom" ? getValueFromDateOrReturnNumber(chartDataReversed.find(x => (getValueFromDateOrReturnNumber(x.x) < lineMark?.start))?.x) : lineMark.start
                                : chartInfo.data.findIndex((seriesValue, index, dataSource) =>
                                    (startWhenIndex > dataSource[index]?.x && startWhenIndex < dataSource[index + 1]?.x) || startWhenIndex === dataSource[index]?.x),
                            max: !this.props.displayIndex
                                ? this.sampling === "custom" ? getValueFromDateOrReturnNumber(chartInfo.data.find(x => (getValueFromDateOrReturnNumber(x.x) > lineMark?.end))?.x) : lineMark.end
                                : chartInfo.data.findIndex((seriesValue, index, dataSource) =>
                                (endWhenIndex > dataSource[index]?.x && endWhenIndex < dataSource[index + 1]?.x) || endWhenIndex === dataSource[index]?.x),
                            color: this.getColor(seriesIndex, lineMark.color, lineMark.colorOpacity)
                        }
                        return (piece.min !== undefined && piece.min !== -1 && piece.max !== undefined && piece.min !== -1) ? piece : undefined
                    }).filter(piece => piece !== undefined),
                    dimension: 0,
                    seriesIndex: seriesIndex,
                    // inRange: {color: [this.getColor(seriesIndex)]},
                    outOfRange: {color: [this.getColor(seriesIndex, chartInfo.color, chartInfo.colorOpacity)]}
                })
            }

            if (chartInfo.parallelYAxisNumber === null || chartInfo.parallelYAxisNumber === undefined) {
                result.series.push({
                    ...shared,
                    data: chartInfo.data.map((data) => [data.x, data.y === null ? 0 : data.y, data.index, data.json, false /*parallelYAxis*/]),
                    markPoint: {
                        label: {
                            normal: {
                                formatter: function (param: any) {
                                    return param.value;
                                }
                            }
                        },
                        data: chartInfo.pointMarks?.map(mark => {
                            const emptyData = {};
                            const sourceData: IChartSeriesInfo | undefined = this.props.chartSeries[this.props.chartSeries.findIndex(x => x.seriesId === chartInfo.seriesId)];
                            if ((mark.dataIndex) < 0) {
                                return emptyData;
                            }
                            if ((mark.dataIndex) >= sourceData?.data?.length) {
                                console.error(`Mark sequence can not be >= series data length! Series length = ${sourceData?.data?.length}. Mark sequence = ${mark.dataIndex}`);
                                return emptyData;
                            }
                            const markPointX = this.props.displayIndex
                                ? mark.dataIndex
                                : sourceData?.data[mark.dataIndex].x;
                            const approximateIndexOfMarkPoint = chartInfo.data
                                .findIndex((_, index) => {
                                    const chartInfoX = chartInfo.data[index].x;
                                    const chartInfoXNext = chartInfo.data[index + 1]?.x;
                                    return (markPointX > chartInfoX && markPointX < chartInfoXNext) || markPointX === chartInfoX;
                                });
                            if (approximateIndexOfMarkPoint === -1) {
                                console.error(`approximateIndexOfMarkPoint not found! Series length = ${sourceData?.data?.length}. Mark sequence = ${mark.dataIndex}`);
                                return emptyData;
                            }
                            const x = chartInfo.data[approximateIndexOfMarkPoint].x;
                            const y = chartInfo.data[approximateIndexOfMarkPoint].y;
                            const coord = [
                                this.props.xAxisType === "category" || this.props.displayIndex ? x.toString() : x,
                                y
                            ];
                            return {
                                name: 'Mark',
                                coord: coord,
                                value: `${mark.displayValue}`,
                                seriesId: chartInfo.seriesId,
                                markPointId: mark.markPointId,
                                json: mark.json
                            }
                        })
                    },
                });
                if (this.props.displayIndex) {
                    let data: any[] = [];
                    result.series.forEach((series: any) => series.data.forEach((x: any) => data.push(x[0])))
                    const chartStep = this.calculateChartStep(data.length)
                    if (this.sampling === "custom")
                        result.xAxis[1].data = data.filter((_, index) => index % chartStep! === 0)
                    else
                        result.xAxis[1].data = data
                }
            } else {
                result.series.push({
                    ...shared,
                    data: chartInfo.data.map(data => [data.x, data.y === null ? 0 : data.y, data.index, data.json, true /*parallelYAxis*/]),
                    yAxisIndex: chartInfo.parallelYAxisNumber,
                });
            }
        });
        return result;
    }

    // Chart config end

    render() {
        const chart = () => {
            return <ReactEcharts
                // Need rerender when changed axis type or dataList count ot other params
                key={`${this.props.xAxisType}${this.props.chartSeries.length}${this.props.displayIndex}${this.props.hideZoom}_${this.props.rerenderKey}`}
                notMerge={this.props.notMerge !== undefined ? this.props.notMerge : true}
                ref={refs => this.chartRef = refs}
                option={this.option}
                lazyUpdate={true}
                showLoading={this.props.loading}
                // theme={"light"}
                onChartReady={this.handleChartReady}
                onEvents={this.EventsDict}
                opts={this.opts}/>;
        }

        const legend = () => {
            if (this.dataList?.length < 1)
                return undefined;


            let groupedByLegendId: ILegendOptions[] = this.props.legendFormatter(this.dataList)

            let legends: any[] = []
            groupedByLegendId?.forEach(legendGroup => {
                const legendsItem = legendGroup.legends.map(dataItem => {
                    const itemLegendStyle = (this.selectedSeriesId !== undefined && this.selectedSeriesId === dataItem.seriesId)
                        ? ' legend-item-selected' : ''
                    return <div key={dataItem.seriesId} className={'legend-item' + itemLegendStyle}
                                onMouseOver={() => this.handleLegendMouse(dataItem.seriesId)}
                                onMouseLeave={() => this.handleLegendMouse(undefined)}
                                onClick={() => this.handleLegendSelect(dataItem.seriesId)}>
                        <div className="legend-item-icon-container">
                            <div className="legend-item-icon" style={{
                                backgroundColor: dataItem.legendInfo.color,
                                borderColor: dataItem.legendInfo.color
                            }}/>
                        </div>
                        <div className="legend-item-name">{dataItem.legendInfo.name}</div>
                    </div>
                })

                if (!!legendsItem.length) {
                    const legendsContainerItem = <div key={legendGroup.legendId} className={'legend-group-container'}>
                        <div className={'group-title-container'}>
                            <div className={'group-title'}>{legendGroup.container.title}</div>
                            <div className={'group-subtitle'}>{legendGroup.container.subTitle}</div>
                        </div>
                        <div className={'group-items'}>
                            <div className={'legend-item-container'}>{legendsItem.map(x => x)}</div>
                        </div>
                        <div className={'group-close'}
                             onClick={() => this.onDeletedSeries(legendGroup.legendId, legendGroup)}>
                            <div>x</div>
                        </div>
                    </div>
                    legends.push(legendsContainerItem)
                }
            })

            if (!!legends?.length)
                legends.push(
                    <div key={'clear-color'} className={'legend-group-container'}>
                        <div className={'legend-restore-colour'}
                             onClick={() => this.handleLegendSelect(undefined)}
                             title={'Restore series colour'}>
                            <Icon type={'bg-colors'}/>
                        </div>
                    </div>
                )

            return (!!legends?.length) &&
                <Row gutter={15}>
                    <Col span={24}>
                        <Row type={'flex'} justify={'center'} gutter={15}
                             style={{paddingBottom: 15}}>
                            {legends}
                        </Row>
                    </Col>
                </Row>
        }

        return <React.Fragment>
            <Row>
                <Col span={24}>
                    {chart()}
                </Col>
                <Col span={24}>
                    {legend()}
                </Col>
            </Row>
        </React.Fragment>;
    }
}
