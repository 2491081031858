import {action, computed, observable} from 'mobx';
import {
    AccountStatus, GetStaffListRequest,
    GetUserListRequest,
    ProCvtClient, StaffSearchType, StaffType, UpdateStaffRequest,
    UpdateUserRequest,
    UserType
} from "../../services/api/ProCvtClient";
import {IDisposable, RootStore} from "../initializeStores";
import {regexHelper} from "../../services/utils/RegexHelper";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";

export class UserStore implements IDisposable {
    constructor(pbmSleepClient: ProCvtClient, rootStore: RootStore) {
        this.pbmSleepClient = pbmSleepClient!;
        this.rootStore = rootStore;
    }

    private readonly pbmSleepClient: ProCvtClient;
    private readonly rootStore: RootStore;

    @observable userList: IUserBySearchDto[] = [];
    @observable staffList: IStaffDto[] = [];

    @observable userDetails?: IMyDetailsDto;

    @computed get userName() {
        return this.userDetails?.userName
    }

    @computed get userLogo() {
        return ''
    }

    @computed get userType() {
        return this.userDetails?.userType
    }

    @computed get isSuperUser() {
        return this.userType === UserType.Super_User
    }

    @computed get isSubjectOrThirdParty() {
        return this.isSubject || this.isThirdParty
    }

    @computed get isSubject() {
        return this.userType === UserType.Subject
    }

    @computed get isThirdParty() {
        return this.userType === UserType.Third_party
    }

    @computed get isPrimary() {
        return this.userType === UserType.Primary
    }

    @computed get isSecondary() {
        return this.userType === UserType.Secondary
    }

    @computed get isSecondaryOrThirdParty() {
        return this.isSecondary || this.isThirdParty
    }

    @computed get isPrimaryOrSecondary() {
        return this.isPrimary || this.isSecondary
    }

    @computed get isSuperOrPrimary() {
        return this.isSuperUser || this.isPrimary
    }

    @computed get isPractitioner() {
        return this.isSuperUser || this.isPrimary || this.isSecondary || this.isThirdParty
    }

    @action
    getUserDetails: (personId: number) => Promise<IUserDetailsDto> = async (personId: number) => {
        return (await this.pbmSleepClient.user_GetUserDetails(personId))!
    };

    @action
    getStaffDetails: (personId: number) => Promise<IStaffDetailsDto> = async (personId: number) => {
        return (await this.pbmSleepClient.user_GetStaffDetails(personId))!
    };

    @action
    updateUser: (input: IUpdateUserInput) => Promise<void> = async (input: IUpdateUserInput) => {
        const request = new UpdateUserRequest(input)
        dateTimeHelper.overrideToISOStringWithTimezone(request.modifiedTime)
        await this.pbmSleepClient.user_UpdateUser(request)
    };

    @action
    updateStaff: (input: IUpdateStaffInput) => Promise<void> = async (input: IUpdateStaffInput) => {
        const request = new UpdateStaffRequest(input)
        dateTimeHelper.overrideToISOStringWithTimezone(request.modifiedTime)
        await this.pbmSleepClient.user_UpdateStaff(request)
    };

    @action
    loadUsers: (input: IGetUsersInput) => Promise<void> = async (input: IGetUsersInput) => {
        if (regexHelper.isEmptyString(input.search)) {
            input.search = undefined;
        }
        this.userList = (await this.pbmSleepClient.user_GetUsersList(new GetUserListRequest(input)))!
    };

    @action
    loadStaffs: (input: IGetStaffListInput) => Promise<void> = async (input: IGetStaffListInput) => {
        if (regexHelper.isEmptyString(input.search)) {
            input.search = undefined;
        }
        this.staffList = (await this.pbmSleepClient.user_GetStaffsList(new GetStaffListRequest(input)))!
    };

    @action
    loadMyDetails = async () => {
        this.userDetails = (await this.pbmSleepClient.user_GetMyDetails())!;
    };


    @action
    getCountryList: () => Promise<ICountryDto[]> = async () => {
        return (await this.pbmSleepClient.user_GetCountriesList())!
    };

    @action
    getOrgUnitList: () => Promise<ICountryDto[]> = async () => {
        return (await this.pbmSleepClient.user_GetCountriesList())!
    };

    setTimeZone = async (timeZone: string) => {
        await this.pbmSleepClient.user_SetTimezone(timeZone);
    };

    @action
    dispose = async () => {
        this.userDetails = undefined
        this.staffList = []
        this.userList = []
    };
}

export interface IDictionary<T> {
    [key: string]: T;
}

export interface IGetUsersInput {
    orgUnitId?: number | undefined;
    search?: string | undefined;
    userType?: UserType | undefined;
    offset: number;
    rowCount: number;
}

export interface IUserBySearchDto {
    userId: number;
    email?: string | undefined;
    userType: UserType;
    orgUnitName?: string | undefined;
    userName?: string | undefined;
    address?: string | undefined;
}


export interface IUpdateUserInput extends IUserDetailsDto {
}


export interface ICountryDto {
    countryId: number;
    countryName?: string | undefined;
}

export interface IMyDetailsDto {
    userId: number;
    personId: number;
    userType: UserType;
    orgUnitId?: number | undefined;
    householdId?: number | undefined;
    userName?: string | undefined;
    email?: string | undefined;
}

export interface IUpdateStaffInput {
    personId: number;
    firstName?: string | undefined;
    lastName?: string | undefined;
    maxFamilyMembers?: number | undefined;
    accountStatus: AccountStatus;
    modifiedTime?: Date | undefined;
}

export interface IGetStaffListInput {
    search?: string | undefined;
    orgUnitId?: number | undefined;
    staffSearchType: StaffSearchType;
    offset: number;
    rowCount: number;
}

export interface IStaffDto {
    personId?: number | undefined;
    userId?: number | undefined;
    email?: string | undefined;
    name?: string | undefined;
    staffType: StaffType;
    accountStatus: AccountStatus;
}
export interface IStaffDetailsDto {
    personId: number;
    maxFamilyMembers?: number | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    accountStatus: AccountStatus;
    modifiedTime?: Date | undefined;
}
export interface IUserDetailsDto {
    userId: number;
    userType: UserType;
    email?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    firstAddress?: string | undefined;
    secondAddress?: string | undefined;
    town?: string | undefined;
    region?: string | undefined;
    postCode?: string | undefined;
    orgUnitId?: number | undefined;
    orgUnitName?: string | undefined;
    countryId?: number | undefined;
    countryName?: string | undefined;
    accountStatus: AccountStatus;
    modifiedTime?: Date | undefined;
}
