import React from "react";
import {RouteComponentProps} from 'react-router-dom';
import {UsersTable} from "../../../components/Tables/UsersTable";
import {Card} from "antd";

interface IProps extends RouteComponentProps {

}

export class UsersTableScene extends React.Component<IProps, any> {
    render() {
        return <Card title={'Users'}>
            <UsersTable {...this.props}/>
        </Card>;
    }
}
