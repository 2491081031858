import {regexHelper} from "../../services/utils/RegexHelper";

const rules = {
    annotation: [
        {max: 200},
        {validator: (_: any, value: string | undefined) => regexHelper.isEmptyString(value) ? Promise.reject('This field is required') : Promise.resolve()}
    ],
};

export default rules;
