import React from "react";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";
import {AnnotationStore} from "../../stores/annotationStore/annotationStore";
import {computed} from "mobx";
import {CollapseView} from "./CollapseView";

interface IProps {
    annotationStore?: AnnotationStore
    traceId: number;
    collapseContent: any
    collapseTitle: any
}

@inject(InjectNames.annotationStore)
@observer
export class CollapseTrace extends React.Component<IProps> {
    @computed get collapsed() {
        const isTraceSelected =  this.props.annotationStore!.selectedTraces.findIndex(id => id === this.props.traceId) === -1
        return isTraceSelected
    }

    handleCollapse = () => {
        if (this.collapsed)
            this.props.annotationStore!.selectedTraces = [...this.props.annotationStore!.selectedTraces, this.props.traceId]
        else
            this.props.annotationStore!.selectedTraces = this.props.annotationStore!.selectedTraces.filter(id => id !== this.props.traceId)
    }

    render() {
        return <CollapseView collapsed={this.collapsed} collapseContent={this.props.collapseContent}
                             collapseTitle={this.props.collapseTitle} handleCollapse={this.handleCollapse}/>
    }
}

// @inject(InjectNames.annotationStore)
// @observer
// export class CollapseTrace extends React.Component<IProps> {
//     @computed get collapsed() {
//         return this.props.annotationStore!.selectedTraces.findIndex(id => id === this.props.traceId) === -1
//     }
//
//     handleCollapse = () => {
//         if (this.collapsed)
//             this.props.annotationStore!.selectedTraces = [...this.props.annotationStore!.selectedTraces, this.props.traceId]
//         else
//             this.props.annotationStore!.selectedTraces = this.props.annotationStore!.selectedTraces.filter(id => id !== this.props.traceId)
//     }
//
//     render() {
//         return <Collapse activeKey={this.collapsed ? 0 : 1}>
//             <Collapse.Panel key={1}
//                             header={<div onClick={this.handleCollapse}>
//                                 {this.props.collapseTitle}
//                             </div>}>
//                 <div>
//                     {this.props.collapseContent}
//                 </div>
//             </Collapse.Panel>
//         </Collapse>
//     }
// }
