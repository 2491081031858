import {AuthStore} from "./auth/authStore";
import {UserStore} from "./user/userStore";
import {proCvtClientInstance} from "../services/api/ProCvtClientInstance";
import {TracesStore} from "./traces/tracesStore";
import {ManualUploadStore} from "./manualUpload/manualUploadStore";
import {ChartStore} from "./chartStore/chartStore";
import {AnnotationStore} from "./annotationStore/annotationStore";
import {InvitationStore} from "./invitationStore/invitationStore";
import {OrgUnitsStore} from "./orgUnitsStore/orgUnitsStore";
import {RoomsStore} from "./roomsStore/roomsStore";
import {ResidentsStore} from "./residents/residentsStore";
import {SurveyBotStore} from "./surveyBotStore/surveyBotStore";
import {DevicesStore} from "./devicesStore/devicesStore";
import {PersonsStore} from "./personsStore/personsStore";
import {AlertsStore} from "./alertsStore/alertsStore";
import {CompareTracesStore} from "./traces/compareTracesStore";

export interface IOnLogOut {
    onLogOut: () => Promise<void>;
}

export interface IOnSpoof {
    onSpoof: () => Promise<void>;
}

export interface IDisposable {
    dispose: () => Promise<void>;
}

export class RootStore implements IOnSpoof, IOnLogOut {
    constructor() {
        this.userStore = new UserStore(proCvtClientInstance, this)
        this.authStore = new AuthStore(proCvtClientInstance, this)
        this.tracesStore = new TracesStore(proCvtClientInstance, this)
        this.compareTracesStore = new CompareTracesStore(this)
        this.manualUploadStore = new ManualUploadStore(proCvtClientInstance, this)
        this.chartStore = new ChartStore()
        this.annotationStore = new AnnotationStore(proCvtClientInstance, this)
        this.invitationStore = new InvitationStore(proCvtClientInstance, this)
        this.orgUnitsStore = new OrgUnitsStore(proCvtClientInstance, this)
        this.roomsStore = new RoomsStore(proCvtClientInstance, this)
        this.residentsStore = new ResidentsStore(proCvtClientInstance, this)
        this.surveyBotStore = new SurveyBotStore(proCvtClientInstance)
        this.devicesStore = new DevicesStore(proCvtClientInstance, this)
        this.personsStore = new PersonsStore(proCvtClientInstance, this)
        this.alertsStore = new AlertsStore(proCvtClientInstance, this)
    }

    userStore!: UserStore;
    authStore!: AuthStore;
    tracesStore!: TracesStore;
    compareTracesStore!: CompareTracesStore;
    manualUploadStore!: ManualUploadStore;
    chartStore!: ChartStore;
    annotationStore!: AnnotationStore;
    invitationStore!: InvitationStore;
    orgUnitsStore!: OrgUnitsStore;
    roomsStore!: RoomsStore;
    residentsStore!: ResidentsStore;
    surveyBotStore!: SurveyBotStore;
    devicesStore!: DevicesStore;
    personsStore!: PersonsStore;
    alertsStore!: AlertsStore;


    onLogOut = async () => {
        await this.clearData();
    };

    onSpoof = async () => {
        await this.clearData();
    };

    clearData = async () => {
        await this.userStore.dispose();
        await this.tracesStore.dispose();
        await this.compareTracesStore.dispose();
        await this.manualUploadStore.dispose();
        await this.annotationStore.dispose();
        await this.invitationStore.dispose();
        await this.orgUnitsStore.dispose();
        await this.roomsStore.dispose();
        await this.residentsStore.dispose();
        await this.surveyBotStore.dispose();
        await this.devicesStore.dispose();
        await this.personsStore.dispose();
        await this.alertsStore.dispose();
    }
}

const rootStore = new RootStore();

export const InitializedStores = {
    authStore: rootStore.authStore,
    userStore: rootStore.userStore,
    tracesStore: rootStore.tracesStore,
    compareTracesStore: rootStore.compareTracesStore,
    manualUploadStore: rootStore.manualUploadStore,
    chartStore: rootStore.chartStore,
    annotationStore: rootStore.annotationStore,
    invitationStore: rootStore.invitationStore,
    orgUnitsStore: rootStore.orgUnitsStore,
    roomsStore: rootStore.roomsStore,
    residentsStore: rootStore.residentsStore,
    surveyBotStore: rootStore.surveyBotStore,
    devicesStore: rootStore.devicesStore,
    personsStore: rootStore.personsStore,
    alertsStore: rootStore.alertsStore,
}


export const InjectNames = {
    authStore: 'authStore',
    userStore: 'userStore',
    tracesStore: 'tracesStore',
    compareTracesStore: 'compareTracesStore',
    manualUploadStore: 'manualUploadStore',
    chartStore: 'chartStore',
    annotationStore: 'annotationStore',
    invitationStore: 'invitationStore',
    orgUnitsStore: 'orgUnitsStore',
    roomsStore: 'roomsStore',
    residentsStore: 'residentsStore',
    surveyBotStore: 'surveyBotStore',
    devicesStore: 'devicesStore',
    personsStore: 'personsStore',
    alertsStore: 'alertsStore',
}

