import * as React from 'react';
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {RouteComponentProps} from "react-router";
import {DevicesStore} from "../../../stores/devicesStore/devicesStore";
import RegisterDevice from "../../../components/Forms/Devices/RegisterDevice";
import {action} from "mobx";

interface IProps extends RouteComponentProps {
    devicesStore: DevicesStore;
}

@inject(InjectNames.devicesStore)
@observer
class RegisterDeviceScene extends React.Component<IProps> {
    @action routeToMain = () => {
        this.props.history.push('/devices')
    }

    @action routeToAssign = (deviceName: string) => {
        this.props.history.push(`/devices/assign/${deviceName}`)
    }

    render() {
        return (
            <React.Fragment>
                <RegisterDevice {...this.props} onCanceled={this.routeToMain} onRegistered={this.routeToAssign}/>
            </React.Fragment>
        );
    }
}

export default RegisterDeviceScene;
