import * as React from 'react';
import {inject, observer} from "mobx-react";
import {ResidentsCardByRoom} from "../../../../components/Residents/ResidentsCardByRoom";
import {RouteComponentProps} from "react-router";
import './PractitionerDashboardScene.less'

interface IProps extends RouteComponentProps {
}

@inject()
@observer
class PractitionerDashboardScene extends React.Component<IProps> {
    render() {

        return (
            <React.Fragment>
                <div className={'dashboard-practitioner'}>
                    <div className={'dashboard-pract-residents-card'}>
                        <ResidentsCardByRoom {...this.props}/>
                    </div>
                    {/*<div className={'dashboard-pract-alerts-sidebar'}>*/}
                    {/*    <AlertsSidebar {...this.props} options={{alertOptions: {subjectId: undefined}}}/>*/}
                    {/*</div>*/}
                </div>
            </React.Fragment>
        );
    }
}

export default PractitionerDashboardScene;
