import React from "react";
import {Select} from "antd";
import {action, observable} from "mobx";
import {IOrgUnitDto, OrgUnitsStore} from "../../stores/orgUnitsStore/orgUnitsStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";
import {errorUtils} from "../../services/utils/ErrorUtils";

interface IProps {
    orgUnitsStore?: OrgUnitsStore;
    onChange?: (value: number, option: React.ReactElement<any> | React.ReactElement<any>[]) => void;
    value?: number;
}

@inject(InjectNames.orgUnitsStore)
@observer
export class OrgUnitSelect extends React.Component<IProps, any> {
    constructor(props: any) {
        super(props);
        this.loadOrgUnits('')
    }

    @observable loading = false

    @observable orgUnitsList: IOrgUnitDto[] = []

    @action loadOrgUnits = async (search: string) => {
        await errorUtils.execute({context:this, loadingFieldName:'loading'}, async () =>
            this.orgUnitsList = (await this.props.orgUnitsStore!.getOrgUnitsList(search)).filter(x => x.id !== null && x.id !== undefined))
    }

    render() {
        return <Select showSearch={true} allowClear={true} optionFilterProp={'children'}
                       loading={this.loading}
                       style={{minWidth: 200, width: '100%'}}
                       placeholder={'Org. unit'}
                       onSearch={value => this.loadOrgUnits(value)}
                       value={this.loading ? undefined : this.props.value}
                       onChange={this.props.onChange}
        >
            {this.orgUnitsList.map(orgUnit => (
                <Select.Option key={orgUnit.id}
                               value={orgUnit.id}>{orgUnit.name}</Select.Option>
            ))}
        </Select>
    }
}
