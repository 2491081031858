import React from "react";
import {FormComponentProps} from "antd/lib/form";
import {errorUtils, IHasValidationErrorsProperty} from "../../services/utils/ErrorUtils";
import rules from "./inputRules";
import Search from "antd/es/input/Search";
import {Button, Col, Form, Row} from "antd";
import FormItem from "antd/es/form/FormItem";
import {computed, observable} from "mobx";
import {
    AnnotationStore,
    IAddAnnotationDto,
    IAddAnnotationTraceInfo
} from "../../stores/annotationStore/annotationStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";

interface IProps extends FormComponentProps {
    annotationStore?: AnnotationStore,
    addInputWithoutText: IAddAnnotationDto
    onAdded?: (annotationId: number) => void
    buttonName?: string
    traceInfo: IAddAnnotationTraceInfo
}

@inject(InjectNames.annotationStore)
@observer
class AnnotationReplyOrCreateForm extends React.Component<IProps, any> implements IHasValidationErrorsProperty {
    @observable validationErrors = {text: ''}
    @observable loading = false

    @computed get buttonName() {
        return this.props.buttonName ? this.props.buttonName : 'Reply'
    }

    @computed get autoFocus() {
        if (!this.props.addInputWithoutText.parentAnnotationId)
            return false
        else
            return true
    }

    render() {
        return <Form onSubmit={e => {
            e.preventDefault();
            this.props.form.validateFields(async (err: any, values: any) => {
                if (!err) {
                    await errorUtils.execute({context: this, loadingFieldName: 'loading'}, async () => {
                        const addedId = await this.props.annotationStore!.add({
                            text: values.text,
                            ...this.props.addInputWithoutText
                        }, this.props.traceInfo)
                        if (!!this.props.onAdded)
                            this.props.onAdded(addedId)
                    });

                    this.props.form.resetFields()
                }
            });

        }}>
            <Row type={'flex'} justify={"end"}>
                <Col span={24}>
                    <FormItem
                        {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.text, 'text', this.props.form)}>
                        {this.props.form.getFieldDecorator('text', {rules: rules.annotation})(
                            <Search
                                autoFocus={this.autoFocus}
                                placeholder='Type some text...'
                                enterButton={<Button htmlType={'submit'}
                                                     loading={this.loading}>{this.buttonName}</Button>}
                                size="default"
                                onSearch={value => errorUtils.resetValidationErrorState('text', this)}
                            />
                        )}
                    </FormItem>
                </Col>
            </Row>
        </Form>
    }
}

export default Form.create<IProps>()(AnnotationReplyOrCreateForm);
