import React from "react";
import {AnnotationStore, ITraceAnnotationsDto} from "../../stores/annotationStore/annotationStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";
import {Modal} from "antd";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";
import ReplyForm from "./AnnotationReplyForm";
import {CollapseTrace} from "./CollapseTrace";
import {AnnotationParent} from "./AnnotationParent";

interface IProps {
    annotationStore?: AnnotationStore
    annotations: ITraceAnnotationsDto[];
    visible: boolean;
    onClose: () => void;
}

@inject(InjectNames.annotationStore)
@observer
export class AnnotationTraceList extends React.Component<IProps, any> {
    onClose = () => {
        this.props.onClose()
        this.props.annotationStore!.selectedTraces = []
        this.props.annotationStore!.selectedAnnotations = []
    }

    render() {
        return <Modal visible={this.props.visible}
                      destroyOnClose={true}
                      width={'60vw'}
                      closable={true}
                      footer={false}
                      onCancel={this.onClose}
                      cancelButtonProps={{hidden: true}}
        >
            <div style={{paddingTop: 35}}>
                {this.props.annotations.map(sortedAnnotations => (
                    <div key={sortedAnnotations.traceId} style={{marginBottom: 30}}>
                        <TraceAnnotations traceAnnotations={sortedAnnotations}/>
                    </div>))
                }
            </div>
        </Modal>
    }
}

interface ITraceAnnotationsProps {
    annotationStore?: AnnotationStore
    traceAnnotations: ITraceAnnotationsDto
}

@inject(InjectNames.annotationStore)
@observer
export class TraceAnnotations extends React.Component<ITraceAnnotationsProps, any> {
    render() {
        const traceAnnotations = this.props.traceAnnotations;
        const info =
            <div>{`${this.props.traceAnnotations?.traceId} (${this.props.traceAnnotations?.subjectName} - ${dateTimeHelper.day_month_year_hour_min(this.props.traceAnnotations?.traceDate)})`}</div>
        const createForTrace = <ReplyForm buttonName={'Create'} addInputWithoutText={{
            traceId: this.props.traceAnnotations!.traceId,
        }} traceInfo={this.props.traceAnnotations}/>
        const annotations = traceAnnotations?.annotations?.map((parent, index, array) => (
            <div key={parent.annotationId} style={{marginTop: 30}}>
                <AnnotationParent
                    sequence={parent.sequence}
                    traceId={traceAnnotations!.traceId}
                    subjectName={traceAnnotations!.subjectName}
                    traceDate={traceAnnotations!.traceDate}
                    validateInput={() => undefined}
                    onDeleteAnnotation={async id => {
                        await this.props.annotationStore!.delete(id);
                    }}
                    onReply={async annotation => {
                        await this.props.annotationStore!.add(annotation, traceAnnotations);
                    }}
                    parentAnnotation={parent}
                >
                </AnnotationParent>
            </div>
        ))
        return (<CollapseTrace traceId={this.props.traceAnnotations.traceId}
                               collapseContent={<div>
                                   {createForTrace}
                                   {annotations}</div>}
                               collapseTitle={info}/>)
    }
}
