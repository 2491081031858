import React from "react";
import {action, observable} from "mobx";
import {Chart} from "../Charts/Chart";
import {chartHelper} from "../../services/utils/ChartHelper";
import {TracesStore} from "../../stores/traces/tracesStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";
import {CompareTracesStore} from "../../stores/traces/compareTracesStore";
import {ISamplingType} from "../Charts/IChartSeriesInfo";

interface IProps {
    tracesStore?: TracesStore
    compareTracesStore?: CompareTracesStore
    chartRef: () => Chart
}

@inject(InjectNames.tracesStore, InjectNames.compareTracesStore)
@observer
export default class EcgViewer extends React.Component <IProps> {
    componentDidMount(): void {
        // this.viewEcgInterval = setInterval(() => this.viewEcg(), 250)
        // this.viewEcgReaction = reaction(() => [this.props.chartRef().zoomPercentStart, this.props.chartRef().zoomPercentEnd, this.cmpStore._ignoreDayToggle], () => setTimeout(this.viewEcg, 10))
    }

    componentWillUnmount(): void {
        // clearInterval(this.viewEcgInterval)
        // this.viewEcgReaction()
    }

    // private viewEcgInterval!: NodeJS.Timeout
    // private viewEcgReaction!: IReactionDisposer

    get cmpStore() {
        return this.props.compareTracesStore!
    }

    @observable ecgSequenceStart = 0
    @observable ecgSequenceEnd = 0
    @observable sampling: ISamplingType = 'lttb'

    @action
    viewEcg = () => {
        const chartRef = this.props.chartRef()
        this.ecgSequenceStart = chartRef.zoomValueStart ?? 0
        this.ecgSequenceEnd = chartRef.zoomValueEnd ?? 0
    }

    render() {
        return <React.Fragment>
            {this.props.tracesStore!.fullTraceSeriesDtoList
                ?.filter(traceDto => traceDto?.ecgs?.length)
                ?.map(traceDto => <Chart
                key={traceDto.traceId}
                sampling={this.sampling}
                opts={{height: 350}}
                chartDpi={(monitorDPI: number) => monitorDPI}
                parallelYAxis={[
                    {
                        parallelYAxisNumber: undefined,
                        yAxisName: "ECG",
                        position: "left",
                        offset: 0,
                    }
                ]}
                xAxisType={this.cmpStore.xAxisType}
                chartSeries={traceDto.ecgs?.map(source => ({
                    seriesId: `ecg_${traceDto.traceId}_${source.startSequence}`,
                    color: 'red',
                    colorOpacity: 'red',
                    parallelYAxisNumber: undefined,
                    data: source.ecgValues?.flatMap((value) => value)
                            .map((ecg, ecgIndex) => ({
                                x: chartHelper.sequenceWhenElapsedOrDateWhenActual(traceDto.traceDate, ecg.sequence!, this.props.compareTracesStore!.compareTimeType, this.props.compareTracesStore!.ignoreDayToggle),
                                y: ecg!.value!,
                                index: ecgIndex,
                                json: {
                                    type: 'ecg',
                                    traceId: traceDto.traceId,
                                    traceDate: traceDto.traceDate,
                                    subjectName: traceDto.subjectName
                                },
                            }))
                            // .filter(x => x.x >= this.ecgSequenceStart && x.x <= this.ecgSequenceEnd)
                        ?? [],
                    name: 'ecg',
                    movingAverageId: undefined
                })) ?? []}
                xAxisHideLabel={true}
                xAxisHideTick={true}
                hideZoom={false}
                showSymbols={this.cmpStore.showSymbols}
                hideToolbox={true}
                tooltipFormatter={(values) => chartHelper.tooltipFormatterDefault(values, chartHelper.tooltipDateFormatterTimeMode(this.cmpStore.compareTimeType, this.cmpStore.ignoreDayToggle), 8)}
                legendFormatter={chartHelper.legendFormatterEmpty}
            />)}
        </React.Fragment>
    }
}
