import React from "react";
import {observer} from "mobx-react";
import {Collapse} from "antd";

interface IProps {
    collapsed:boolean;
    collapseContent: any
    collapseTitle: any
    handleCollapse:() => void;
}

@observer
export class CollapseView extends React.Component<IProps> {
    handleCollapse = () => {
        this.props.handleCollapse()
    }

    render() {
        return <Collapse activeKey={this.props.collapsed ? 0 : 1} onChange={key => this.handleCollapse()}>
            <Collapse.Panel key={1}
                            header={this.props.collapseTitle}>
                <div>
                    {this.props.collapseContent}
                </div>
            </Collapse.Panel>
        </Collapse>
    }
}
