import * as React from "react";
import {ReactNode} from "react";
import {Route} from "react-router";
import {Redirect} from 'react-router-dom';
import DashboardScene from "./scenes/authorized/Dashboard/DashboardScene";
import ResidentsTableScene from "./scenes/authorized/Residents/ResidentsTableScene";
import AddOrUpdateResidentScene from "./scenes/authorized/Residents/AddOrUpdateResidentScene";
import {TracesScene} from "./scenes/authorized/Traces/TracesScene";
import {CompareScene} from "./scenes/authorized/Traces/CompareScene";
import {UserType} from "./services/api/ProCvtClient";
import {StaffsTableScene} from "./scenes/authorized/Staffs/StaffsTableScene";
import {OAuthRedirect} from "./scenes/unauthorized/OAuthRedirect";
import {SignIn} from "./components/Forms/Sign/SignIn";
import {ProcessInvitation} from "./components/Forms/Sign/ProcessInvitation";
import {SignUp} from "./components/Forms/Sign/SignUp";
import {InviteStaffScene} from "./scenes/authorized/Staffs/InviteStaffScene";
import {AddOrUpdateRoomScene} from "./scenes/authorized/Rooms/AddOrUpdateRoomScene";
import {RoomsTableScene} from "./scenes/authorized/Rooms/RoomsTableScene";
import {Icon} from "antd";
import {antdIconNames} from "./constants/Icon";
import {UpdateStaffScene} from "./scenes/authorized/Staffs/UpdateStaffScene";
import {UsersTableScene} from "./scenes/authorized/Users/UsersTableScene";
import {UpdateUserScene} from "./scenes/authorized/Users/UpdateUserScene";
import {SurveyBot} from "./components/Bot/SurveyBot";
import RealtimeChartScene from "./scenes/authorized/RealTime/RealtimeChartScene";
import DevicesScene from "./scenes/authorized/Devices/DevicesScene";
import RegisterDeviceScene from "./scenes/authorized/Devices/RegisterDeviceScene";
import AssignDeviceScene from "./scenes/authorized/Devices/AssignDeviceScene";
import {ViewChartByTraceIdScene} from "./scenes/authorized/Traces/ViewChartByTraceIdScene";
import {PersonOverviewScene} from "./scenes/authorized/Traces/PersonOverviewScene";

export interface IRouteInfo extends IMenuItemInfo {
    pathForMenuItem?: string;
    exact?: boolean;
    render: (props: any) => React.ReactNode;
    showInMenu: boolean;
    allowForUsers: UserType[];
}

export interface IMenuItemInfo {
    path: string;
    title: string;
    icon?: any;
}

const notAuthorizedRoutePathsInfo: IRouteInfo[] = [
    {
        path: '/join/:invitation',
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <ProcessInvitation {...props}/>),
        showInMenu: false,
        allowForUsers: []
    },
    {
        path: '/sign-up',
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <SignUp {...props}/>),
        showInMenu: false,
        allowForUsers: []
    },
    {
        path: '/oauth_redirect',
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <OAuthRedirect {...props}/>),
        showInMenu: true,
        allowForUsers: [UserType.Primary, UserType.Super_User, UserType.Subject]
    },
    {
        path: '/oauth_sign_out',
        exact: false,
        title: '',
        icon: undefined,
        render: (props => <Redirect to={'/'}/>),
        showInMenu: false,
        allowForUsers: []
    },
    {
        path: '/',
        exact: false,
        title: '',
        icon: undefined,
        render: (props => <SignIn {...props}/>),
        showInMenu: false,
        allowForUsers: []
    },
];

const allUserTypes = [UserType.Subject, UserType.Secondary, UserType.Primary, UserType.Super_User, UserType.Third_party]

const routePathsInfo: IRouteInfo[] = [
    {
        path: '/survey-bot/:personId/:questionCategory',
        exact: true,
        title: '',
        icon: '',
        render: (props => <SurveyBot {...props}/>),
        showInMenu: false,
        allowForUsers: allUserTypes
    },
    {
        path: DashboardScene.routePaths.base,
        exact: true,
        title: 'Dashboard',
        icon: <Icon type={antdIconNames.Dashboard}/>,
        render: (props => <DashboardScene {...props}/>),
        showInMenu: true,
        allowForUsers: allUserTypes
    },
    {
        path: '/traces/table',
        exact: true,
        title: 'Traces',
        icon: <Icon type={antdIconNames.TraceIcon}/>,
        render: (props => <TracesScene {...props}/>),
        showInMenu: true,
        allowForUsers: allUserTypes
    },
    {
        path: '/traces/realtime/:deviceId',
        exact: true,
        title: 'Realtime',
        icon: <Icon type={antdIconNames.TraceIcon}/>,
        render: (props => <RealtimeChartScene {...props}/>),
        showInMenu: false,
        allowForUsers: allUserTypes
    },
    {
        path: '/traces/table/:subjectId',
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <TracesScene {...props}/>),
        showInMenu: false,
        allowForUsers: [UserType.Primary, UserType.Super_User, UserType.Secondary]
    },
    {
        path: '/traces/compare',
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <CompareScene {...props}/>),
        showInMenu: false,
        allowForUsers: allUserTypes
    },
    {
        path: ViewChartByTraceIdScene.routPath.byTraceId,
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <ViewChartByTraceIdScene {...props}/>),
        showInMenu: false,
        allowForUsers: allUserTypes
    },
    {
        path: PersonOverviewScene.routePaths.subject,
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <PersonOverviewScene {...props}/>),
        showInMenu: false,
        allowForUsers: [UserType.Primary, UserType.Super_User, UserType.Secondary]
    },
    {
        path: PersonOverviewScene.routePaths.session,
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <PersonOverviewScene {...props}/>),
        showInMenu: false,
        allowForUsers: [UserType.Primary, UserType.Super_User, UserType.Secondary]
    },
    {
        path: '/devices',
        exact: true,
        title: 'Devices',
        icon: <Icon type={antdIconNames.DeviceIcon}/>,
        render: (props => <DevicesScene {...props}/>),
        showInMenu: true,
        allowForUsers: allUserTypes
    },
    {
        path: '/devices/register',
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <RegisterDeviceScene {...props}/>),
        showInMenu: false,
        allowForUsers: [UserType.Super_User]
    },
    {
        path: '/devices/assign/:deviceName',
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <AssignDeviceScene {...props}/>),
        showInMenu: false,
        allowForUsers: [UserType.Super_User]
    },
    {
        path: '/residents',
        exact: true,
        title: 'Residents',
        icon: <Icon type={antdIconNames.Subject}/>,
        render: (props => <ResidentsTableScene {...props}/>),
        showInMenu: true,
        allowForUsers: [UserType.Primary, UserType.Super_User, UserType.Secondary]
    },
    {
        path: '/residents/invite',
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <AddOrUpdateResidentScene {...props}/>),
        showInMenu: false,
        allowForUsers: [UserType.Primary, UserType.Super_User, UserType.Secondary]
    },
    {
        path: '/residents/edit/:personId',
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <AddOrUpdateResidentScene {...props}/>),
        showInMenu: false,
        allowForUsers: [UserType.Primary, UserType.Super_User, UserType.Secondary]
    },
    {
        path: '/staff',
        exact: true,
        title: 'Staff',
        icon: <Icon type={antdIconNames.User}/>,
        render: (props => <StaffsTableScene {...props}/>),
        showInMenu: true,
        allowForUsers: [UserType.Primary, UserType.Super_User]
    },
    {
        path: '/staff/invite',
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <InviteStaffScene {...props}/>),
        showInMenu: false,
        allowForUsers: [UserType.Primary, UserType.Super_User]
    },
    {
        path: '/staff/edit/:personId',
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <UpdateStaffScene {...props}/>),
        showInMenu: false,
        allowForUsers: [UserType.Primary, UserType.Super_User]
    },
    {
        path: '/users',
        exact: true,
        title: 'Users',
        icon: <Icon type={antdIconNames.User}/>,
        render: (props => <UsersTableScene {...props}/>),
        showInMenu: true,
        allowForUsers: [UserType.Primary, UserType.Super_User]
    },
    {
        path: '/users/edit/:userId',
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <UpdateUserScene {...props}/>),
        showInMenu: false,
        allowForUsers: [UserType.Primary, UserType.Super_User]
    },
    {
        path: '/rooms',
        exact: true,
        title: 'Rooms',
        icon: <Icon type={antdIconNames.Room}/>,
        render: (props => <RoomsTableScene {...props}/>),
        showInMenu: true,
        allowForUsers: [UserType.Super_User, UserType.Primary, UserType.Secondary]
    },
    {
        path: '/rooms/add',
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <AddOrUpdateRoomScene {...props}/>),
        showInMenu: false,
        allowForUsers: [UserType.Super_User, UserType.Primary, UserType.Secondary]
    },
    {
        path: '/rooms/edit/:roomId',
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <AddOrUpdateRoomScene {...props}/>),
        showInMenu: false,
        allowForUsers: [UserType.Super_User, UserType.Primary, UserType.Secondary]
    },
    {
        path: '/',
        exact: false,
        title: '',
        icon: undefined,
        render: (props => <Redirect to={'/dashboard'}/>),
        showInMenu: false,
        allowForUsers: []
    },
];

export function getTitleByRoutePathName(routePath: string): string {
    const index = routePathsInfo.findIndex(value => value.path.toLocaleLowerCase().includes(routePath.toLocaleLowerCase()))
    return index !== -1 ? routePathsInfo[index].title : ''
}

export function getNotAuthorizedRoutes(): ReactNode[] {
    return notAuthorizedRoutePathsInfo
        .map(info => <Route key={`${info.path}_${info.title}`} path={info.path} exact={info.exact}
                            render={info.render}/>)
}

export function getRoutesForUserType(userType?: UserType): ReactNode[] {
    if (userType === undefined)
        return [];
    return filterByUserType(userType, routePathsInfo)
        .map(info => <Route key={`${info.path}_${info.title}`} path={info.path} exact={info.exact}
                            render={info.render}/>)
}

export function getMenuListForUserType(userType?: UserType): IMenuItemInfo[] {
    if (userType === undefined)
        return [];
    return filterByUserType(userType, routePathsInfo)
        .filter(info => info.showInMenu).map(info => {
            if (info.pathForMenuItem)
                return {...info, path: info.pathForMenuItem}
            else return info
        });
}

function filterByUserType(userType: UserType, routes: IRouteInfo[]) {
    return routes.filter(info => {
        if (info.allowForUsers.length === 0)
            return true;
        else {
            return info.allowForUsers.includes(userType);
        }
    });
}
