import {RouteComponentProps} from "react-router";
import {IDictionary, UserStore} from "../../stores/user/userStore";
import {AuthStore} from "../../stores/auth/authStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";
import * as React from "react";
import {computed, observable, runInAction} from "mobx";
import {Chart, IChartProps} from "../Charts/Chart";
import {Button, Checkbox, Col, message, Row, Slider, Statistic} from "antd";
import {IChartSeriesInfo, IChartValue, IGradientX, IToolTipParams} from "../Charts/IChartSeriesInfo";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";
import {
    IBaseCommand,
    IDeviceState,
    IGetDeviceStateCommand,
    IPutDataCommand,
    IPutDataCommandData,
    ISetDeviceStateCommand,
    IStartStreamingCommand,
    IStopStreamingCommand,
    ITestMsgCommand
} from "./Models/IBaseCommand";
import moment from "moment-timezone";
import {colorHelper} from "../../services/utils/ColorHelper";
import {chartHelper, IBaseRealTimePointDataJson, TraceSeriesType} from "../../services/utils/ChartHelper";
import {SpinOnCenter} from "../Loading/SpinOnCenter";
import {SliderValue} from "antd/lib/slider";
import {EChartOption, graphic} from "echarts"


interface IProps extends RouteComponentProps {
    deviceId: string
    userStore?: UserStore;
    authStore?: AuthStore;
}

@inject(InjectNames.userStore, InjectNames.authStore)
@observer
class RealtimeChart extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
        this.deviceId = props.deviceId
    }

    componentWillUnmount() {
        this.disconnect()
        clearInterval(this.disposeSmoothlyEcgLoop)
    }

    async componentDidMount() {
        this.initWssConnection()
        this.disposeSmoothlyEcgLoop = setInterval(this.smoothlyEcgLoop, this.smoothlyEcgLoopInterval)
    }

    readonly deviceId: string;

    emptyData = () => []
    @observable ws?: WebSocket
    @observable wssConnection = false
    @observable isWssOpened = false
    @observable isWssOpening = false
    @observable isSubscribed = false
    @observable deviceState?: IDeviceState = undefined

    @computed get loading() {
        return !this.deviceState && this.isWssOpened
    }

    isMaxPoints = false
    readonly maxMinutes: number = 5

    chartEcgRef!: Chart;
    chartRef!: any;

    @computed get xAxisMin() {
        return (value: { min: number, max: number }) => {
            return value.min + 1000 // + 1000 to hide tail
        }
    }

    @computed get xAxisMax() {
        return (value: { min: number, max: number }) => {
            const max = this.chartProps.chartSeries[0].data[this.chartProps.chartSeries[0].data.length - 1]?.x
            const constMaxMs = (value.min + (1000 * 60 * this.maxMinutes))
            if (!!max && max > constMaxMs) {
                this.isMaxPoints = true
                return (max as number)
            } else {
                this.isMaxPoints = false
                return constMaxMs
            }
        }
    }

    @computed get xAxisMinEcg() {
        return (value: { min: number, max: number }) => {
            return value.min // + 1000 // + 1000 to hide tail
        }
    }

    @observable chartSeriesCvt: IChartValue[] = []

    @observable chartSeriesHeart: IChartValue[] = []

    @observable chartSeriesResp: IChartValue[] = []

    @observable chartSeriesTemp: IChartValue[] = []

    // todo disabled mobx
    /*@observable */
    chartSeriesEcg: IChartValue[] = []
    _chartSeriesEcgLoop: IChartValue[] = []


    ecgReplaceGradientLength = 200
    ecgReplaceIndex: number | undefined = undefined
    xAxisMaxEcgCount = 1000
    smoothlyEcgLoopInterval = 10
    processing = false
    addEcgGradient = () => {
        if (!!this.deviceState?.services?.ecg && this.ecgReplaceIndex !== undefined) {
            const gradientStart = (((this.ecgReplaceIndex) * 100) / this.chartSeriesEcg.length) / 100
            const gradientStartNext = (((this.ecgReplaceIndex + 1) * 100) / this.chartSeriesEcg.length) / 100
            const gradientEnd = (((this.ecgReplaceIndex + this.ecgReplaceGradientLength) * 100) / this.chartSeriesEcg.length) / 100
            const gradientStartColor = 'rgba(255,255,255,0)'

            if (gradientEnd > 1) {
                this.chartSeriesEcgGradient = [
                    {offsetPercent: 0, color: this.chartPropsEcg.chartSeries[0].color},
                    {
                        offsetPercent: gradientStart,
                        color: this.chartPropsEcg.chartSeries[0].color
                    },
                    {offsetPercent: 1, color: gradientStartColor},
                ]
            } else
                this.chartSeriesEcgGradient = [
                    {offsetPercent: 0, color: this.chartPropsEcg.chartSeries[0].color},
                    {
                        offsetPercent: gradientStart,
                        color: this.chartPropsEcg.chartSeries[0].color
                    },
                    {
                        offsetPercent: gradientStartNext,
                        color: gradientStartColor
                    },
                    {
                        offsetPercent: gradientEnd,
                        color: this.chartPropsEcg.chartSeries[0].color
                    },
                    {offsetPercent: 1, color: this.chartPropsEcg.chartSeries[0].color},
                ]
        }
    }
    moveEcgTail: (dataParam: IChartValue[]) => IChartValue[] = (dataParam: IChartValue[]) => {
        let data = dataParam
        let tailLength = (data.length - this.xAxisMaxEcgCount)

        if (tailLength > 0) {
            if (this.ecgReplaceIndex === undefined) {
                this.ecgReplaceIndex = 0
            }

            let moveCount = 0
            let moveStartIndex = data.length - tailLength
            if (tailLength + this.ecgReplaceIndex > this.xAxisMaxEcgCount - 1) {
                moveCount = this.xAxisMaxEcgCount - this.ecgReplaceIndex
            } else {
                moveCount = tailLength
            }

            const move = data
                .splice(moveStartIndex, moveCount)
            const movedCount = data.splice(this.ecgReplaceIndex, move.length, ...move).length
            this.ecgReplaceIndex += movedCount
            if (this.ecgReplaceIndex >= this.xAxisMaxEcgCount - 1) {
                this.ecgReplaceIndex = 0
            }
        }

        if (data.length - this.xAxisMaxEcgCount > 0) {
            return this.moveEcgTail(data)
        } else {
            data.forEach((x, i) => {
                x.index = i
            })
            return data
        }
    }

    disposeSmoothlyEcgLoop!: any
    smoothlyEcgLoop = async () => {
        if (this.processing)
            return

        if (!this._chartSeriesEcgLoop?.length)
            return

        this.processing = true
        try {
            let itemLoopData = this._chartSeriesEcgLoop.splice(0, this._chartSeriesEcgLoop.length)
            const fps = 50
            const take = Math.ceil(itemLoopData.length / fps)
            const sleep = Math.ceil((1000 / fps))
            while (!!itemLoopData.length) {
                let spliced = itemLoopData.splice(0, take)

                const render = new Promise(resolve => {
                    runInAction(() => {
                        let st = moment()
                        this.chartSeriesEcg = this.moveEcgTail(this.chartSeriesEcg.concat(spliced))
                        const optionCurrent: EChartOption = this.chartEcgRef!.chartInstance!.getOption()
                        const option: EChartOption = {
                            ...optionCurrent,
                            series: [{
                                /*id: `ecg_${this.deviceId}`,*/
                                animation: false,
                                smooth: false,
                                large: true,
                                lineStyle: {
                                    width: 1,
                                },
                                itemStyle: {
                                    // @ts-ignore
                                    color: this.chartSeriesEcgGradient === undefined
                                        ? 'red'
                                        : new graphic.LinearGradient(
                                            0, 0, 1, 0,
                                            this.chartSeriesEcgGradient.map(item => ({offset: item.offsetPercent, color: item.color}))
                                        )
                                },
                                data: this.chartSeriesEcg.map(data => [data.x.toString(), data.y === null ? 0 : data.y, data.index, data.json, false /*parallelYAxis*/])
                            }]
                        }
                        this.chartEcgRef!.chartInstance!.setOption(option/*, true*/)
                        // this.chartSeriesEcg = this.moveEcgTail_2(this.chartSeriesEcg, spliced)
                        // this.moveEcgTail_3(this.chartSeriesEcg, spliced)
                        this.addEcgGradient()
                        const renderMs = moment().diff(st, 'ms')
                        setTimeout(resolve, sleep - renderMs)
                    })
                })
                // @ts-ignore
                spliced = null
                await render
            }
            // @ts-ignore
            itemLoopData = null
        } finally {
            this.processing = false
        }
    }
    // todo disabled mobx
    /*@observable */
    chartSeriesEcgGradient?: IGradientX | undefined = undefined

    private readonly CvtSeriesId = `cvt_${this.deviceId}`
    private readonly HrSeriesId = `hr_${this.deviceId}`
    private readonly RespSeriesId = `rr_${this.deviceId}`
    private readonly TempSeriesId = `temp_${this.deviceId}`

    @computed get chartSeries() {
        let series: IChartSeriesInfo[] = []
        if (!!this.deviceState?.services?.hr) {
            series.push({
                data: this.chartSeriesCvt,
                useCachedMvAvg: true,
                movingAverageId: 0,
                color: colorHelper.getColor(0, "cvt"),
                colorOpacity: colorHelper.getColorOpacity(0, "cvt"),
                seriesId: this.CvtSeriesId,
                name: `CVT`,
                showArea: true,
            })
            series.push({
                data: this.chartSeriesHeart,
                useCachedMvAvg: true,
                movingAverageId: 1,
                parallelYAxisNumber: 1,
                color: colorHelper.getColor(0, "hr"),
                colorOpacity: colorHelper.getColorOpacity(0, "hr"),
                showArea: false,
                seriesId: this.HrSeriesId,
                name: `HEART RATE`,
            })
        }

        if (!!this.deviceState?.services?.br) {
            series.push({
                data: this.chartSeriesResp,
                useCachedMvAvg: true,
                movingAverageId: 2,
                color: colorHelper.getColor(0, "rr"),
                colorOpacity: colorHelper.getColorOpacity(0, "rr"),
                showArea: false,
                parallelYAxisNumber: 2,
                seriesId: this.RespSeriesId,
                name: `RESPIRATION`,
            })
        }

        if (!!this.deviceState?.services?.temp) {
            series.push(
                {
                    data: this.chartSeriesTemp,
                    useCachedMvAvg: true,
                    movingAverageId: 3,
                    color: colorHelper.getColor(0, "temp"),
                    colorOpacity: colorHelper.getColorOpacity(0, "temp"),
                    showArea: false,
                    parallelYAxisNumber: 3,
                    seriesId: this.TempSeriesId,
                    name: `TEMPERATURE`,
                })
        }
        return series
    }

    @observable movingAverageOption = [
        {movingAverageId: 0, value: 0, max: 200, min: 0, title: 'CVT AVG', step: 10},
        {movingAverageId: 1, value: 0, max: 200, min: 0, title: 'HR AVG', step: 10},
        {movingAverageId: 2, value: 0, max: 200, min: 0, title: 'RESP AVG', step: 10},
        {movingAverageId: 3, value: 0, max: 200, min: 0, title: 'TEMP AVG', step: 10}
    ]

    @computed get chartProps() {
        let props: IChartProps = {
            legendFormatter: chartHelper.legendFormatterEmpty,
            opts: {height: 650},
            chartDpi: (monitorDPI: number) => monitorDPI,
            chartSeries: this.chartSeries,
            zoomFilterMode: "none",
            hideZoom: true,
            hideToolbox: true,
            xAxisType: "time",
            xAxisMin: this.xAxisMin,
            xAxisMax: this.xAxisMax,
            xAxisHideLabel: true,
            parallelYAxis: [
                {
                    max: undefined,
                    min: undefined,
                    parallelYAxisNumber: undefined,
                    yAxisName: 'CVT',
                    position: "left",
                    hideAll: !this.deviceState?.services?.hr
                },
                {
                    max: undefined,
                    min: undefined,
                    parallelYAxisNumber: 1,
                    yAxisName: 'HR',
                    position: "left",
                    offset: 45,
                    hideAll: !this.deviceState?.services?.hr
                },
                {
                    max: undefined,
                    min: undefined,
                    parallelYAxisNumber: 2,
                    position: "right",
                    yAxisName: 'RESP',
                    hideAll: !this.deviceState?.services?.br
                },
                {
                    max: 46,
                    min: 30,
                    parallelYAxisNumber: 3,
                    yAxisName: 'TEMP',
                    position: "right",
                    offset: !!this.deviceState?.services?.br ? 45 : 0,
                    hideAll: !this.deviceState?.services?.temp
                },
            ],
            movingAverageOption: this.movingAverageOption,
            tooltipFormatter: (values: IToolTipParams[]) => {
                return chartHelper.tooltipFormatterRealTimeDefault(values, (chartValue: IChartValue) => dateTimeHelper.day_month_year_hour_min_sec(chartValue.x as Date));
            }
        }
        return props
    }

    // todo disabled mobx
    /*@computed */
    get chartPropsEcg() {
        let props: IChartProps = {
            legendFormatter: chartHelper.legendFormatterEmpty,
            chartDpi: monitorDPI => monitorDPI,
            opts: {height: 350},
            chartSeries: [
                // @ts-ignore
                {
                    data: this.chartSeriesEcg,
                    useCachedMvAvg: true,
                    movingAverageId: undefined,
                    color: 'rgb(255,0,0)',
                    colorOpacity: 'rgba(255,0,0, 0.25)',
                    // seriesId: `ecg_${this.deviceId}`,
                    name: `ECG`,
                    // showArea: false,
                    // gradientX: this.chartSeriesEcgGradient
                },
            ],
            seriesType: "line",
            zoomFilterMode: "none",
            hideZoom: true,
            hideToolbox: true,
            xAxisType: "category",
            xAxisMin: this.xAxisMinEcg,
            xAxisMax: this.xAxisMaxEcgCount,
            parallelYAxis: [{yAxisName: 'ECG', hideName: true, yAxisHideValue: true, yAxisHideTick: true}],
            notMerge: true,
            tooltipFormatter: (value) => {
                return !!value ? `<div><div>${dateTimeHelper.hour_min_sec_a(dateTimeHelper.durationMsToDate(value[0].x as number, moment.utc(this.startTime).toDate()))}</div><div>${value[0].y}</div></div>` : ''
            },
            xAxisHideTick: true,
            xAxisHideLabel: true
        }
        return props
    }


    commandHandlers: IDictionary<() => (command: IBaseCommand) => any> = {
        "put_data": () => this.processPutDataCommand as (command: IBaseCommand) => any,
        "set_device_state": () => this.processSetDeviceStateCommand as (command: IBaseCommand) => any,
        // Only for development
        "test_msg": () => command => {
            let testMsgCommand = command as {
                "action": string,
                "command": string,
                "message": string
            };
            message.info({content: testMsgCommand?.message})
            setTimeout(() => {
                let command: ITestMsgCommand = {
                    command: "test_msg",
                    message: "From client",
                    deviceId: `${this.deviceId}`
                }
                this.sendCommand(command)
            }, 3000)
        },
    }

    getDeviceState = () => {
        let commandData: IGetDeviceStateCommand = {
            deviceId: this.deviceId,
            command: 'get_device_state',
        }
        this.sendCommand(commandData)
    }

    startStreaming = () => {
        let commandData: IStartStreamingCommand = {
            deviceId: this.deviceId,
            command: 'start_streaming',
            services: this.deviceState!.services
        }
        this.sendCommand(commandData)
        this.deviceState = {streaming: true, ...commandData}
        this.chartSeriesCvt = []
        this.chartSeriesHeart = []
        this.chartSeriesResp = []
        this.chartSeriesTemp = []
        this.chartSeriesEcg = []
        this.chartSeriesEcgGradient = undefined
    }

    stopStreaming = () => {
        let command: IStopStreamingCommand = {
            command: "stop_streaming",
            deviceId: `${this.deviceId}`
        }
        this.sendCommand(command)
        this.deviceState!.streaming = false
    }

    sendCommand = (commandData: IBaseCommand) => {
        this.ws?.send(JSON.stringify({
            "action": "command",
            "command": `${commandData.command}`,
            ...commandData
        }))
    }

    processCommand = (command: IBaseCommand) => {
        let handler = this.commandHandlers[command.command]()
        handler(command)
    }

    processSetDeviceStateCommand = (command: ISetDeviceStateCommand) => {
        this.deviceState = command.deviceState
    }

    startTime = ''
    processPutDataCommand = (command: IPutDataCommand) => {
        if (!this.isSubscribed)
            return

        let resultCvt: IChartValue[] = this.chartSeriesCvt.slice()
        let resultHeart: IChartValue[] = this.chartSeriesHeart.slice()
        let resultResp: IChartValue[] = this.chartSeriesResp.slice()
        let resultTemp: IChartValue[] = this.chartSeriesTemp.slice()
        let resultEcg: IChartValue[] = this._chartSeriesEcgLoop.slice()

        const convertX = (seq: number, startTime: string, xAxisType: 'time' | 'value' | 'category') => {
            return xAxisType === "time" ? dateTimeHelper.durationMsToDate(seq, moment.utc(startTime).toDate()).getTime() : seq
        }
        const updateOrPush = (resultData: IChartValue[], x: number, y: number | undefined | null, commandData: IPutDataCommandData, type: TraceSeriesType) => {
            const seq = convertX(x, commandData.startTime, this.chartProps.xAxisType)

            let yValue = y
            yValue = !!yValue ? yValue : 0

            const indexOfExists = resultData.findIndex(ex => ex.x === seq)
            if (indexOfExists !== -1) {
                resultData[indexOfExists].y = yValue
            } else {
                const latestIndex = resultData[resultData.length - 1]?.index
                const newIndex = !!latestIndex ? latestIndex + 1 : 0
                const json: IBaseRealTimePointDataJson = {type: type}
                resultData.push({
                    x: seq,
                    index: newIndex,
                    json: json,
                    y: yValue
                })
            }
        }

        command.data
            .forEach((commandData) => {
                if (commandData.startTime !== this.startTime) {
                    this.startTime = commandData.startTime
                    resultCvt = this.emptyData();
                    resultHeart = this.emptyData();
                    resultResp = this.emptyData();
                    resultTemp = this.emptyData();
                    resultEcg = this.emptyData();
                    this.chartSeriesEcg = this.emptyData();
                    this.ecgReplaceIndex = undefined
                    this.chartSeriesEcgGradient = undefined
                } else {
                    commandData.data?.forEach(point => {
                        updateOrPush(resultCvt, point.seq, point.cvt, commandData, 'cvt')
                        updateOrPush(resultHeart, point.seq, point.hr, commandData, "hr")
                        updateOrPush(resultResp, point.seq, point.resp, commandData, "rr")
                        updateOrPush(resultTemp, point.seq, point.temp, commandData, "temp")
                    })

                    let resultEcgAdded: IChartValue[] = []
                    commandData.ecg?.forEach(ecgData => {
                        ecgData?.ecg?.forEach((ecg, ecgIndex) => {
                            const json: IBaseRealTimePointDataJson = {type: 'ecg'}
                            resultEcgAdded.push({
                                x: convertX(ecgData.seq + ((ecgIndex) * (1000 / ecgData.samplesPerSecond)), commandData.startTime, this.chartPropsEcg.xAxisType),
                                y: ecg,
                                json: json,
                                index: -1
                            })
                        })
                    })

                    if (this.isMaxPoints) {
                        if (resultCvt.length > this.chartSeriesCvt.length) {
                            const addedPointCount = resultCvt.length - this.chartSeriesCvt.length
                            for (let countOfProcessed = 0; countOfProcessed < addedPointCount; countOfProcessed++) {
                                resultCvt.shift()
                                resultHeart.shift()
                                resultResp.shift()
                                resultTemp.shift()
                            }
                        }
                    }

                    resultEcg = resultEcg.concat(resultEcgAdded)
                }
            })

        runInAction(() => {
            this.chartSeriesCvt = resultCvt
            this.chartSeriesHeart = resultHeart
            this.chartSeriesResp = resultResp
            this.chartSeriesTemp = resultTemp
            this._chartSeriesEcgLoop = resultEcg
        })
    }

    initWssConnection = async () => {
        this.isWssOpening = true

        const onDisconnected = () => {
            this.ws = undefined
            this.isSubscribed = false
            this.isWssOpened = false
            this.isWssOpening = false
            this.deviceState = undefined
        }
        const onConnected = () => {
            this.isWssOpened = true
            this.isWssOpening = false
            this.getDeviceState()
            this.subscribe()
        }

        const url = await this.props.authStore!.getClientWebsocketUrl()
        this.ws = new WebSocket(url);

        this.ws!.onerror = evError => {
            message.error('Stream connection error')
            onDisconnected()
        }
        this.ws!.onclose = evClose => {
            onDisconnected()
        }
        this.ws!.onmessage = evMsg => {
            this.processCommand((JSON.parse(evMsg.data) as IBaseCommand))
        }
        this.ws.onopen = (ev: any) => {
            onConnected()
        }
    }

    subscribe = () => {
        this.ws!.send(JSON.stringify({
            'action': 'command',
            'command': 'subscribe_on_device',
            'deviceId': this.deviceId
        }))
        this.isSubscribed = true
    }
    unsubscribe = () => {
        this.ws!.send(JSON.stringify({
            'action': 'command',
            'command': 'unsubscribe_from_device',
            'deviceId': this.deviceId
        }))
        this.isSubscribed = false
    }
    disconnect = () => {
        if (this.isSubscribed)
            this.unsubscribe()

        this.ws?.close()
    }

    render() {
        const connectWssView = <React.Fragment>
            <Button disabled={this.isWssOpened || this.isWssOpening}
                    icon={this.isWssOpening ? 'loading' : undefined}
                    type={'primary'} loading={this.wssConnection}
                    onClick={this.initWssConnection}>Connect</Button>
            <Button disabled={!this.isWssOpened}
                    onClick={this.disconnect}>Disconnect</Button>
        </React.Fragment>

        const deviceSensorsView = !!this.deviceState && <React.Fragment>
            <Checkbox disabled={this.deviceState!.streaming} checked={this.deviceState!.services.hr}
                      onChange={e => this.deviceState!.services.hr = e.target.checked}>CVT/HEART RATE</Checkbox>

            <Checkbox disabled={this.deviceState!.streaming} checked={this.deviceState!.services.br}
                      onChange={e => this.deviceState!.services.br = e.target.checked}>RESPIRATION</Checkbox>

            <Checkbox disabled={this.deviceState!.streaming} checked={this.deviceState!.services.temp}
                      onChange={e => this.deviceState!.services.temp = e.target.checked}>TEMPERATURE</Checkbox>

            <Checkbox disabled={this.deviceState!.streaming} checked={this.deviceState!.services.ecg}
                      onChange={e => this.deviceState!.services.ecg = e.target.checked}>ECG</Checkbox>
        </React.Fragment>

        const deviceStreamingView = !!this.deviceState && <React.Fragment>
            <Button disabled={this.deviceState!.streaming || !this.isWssOpened}
                    onClick={this.startStreaming}>Start
                streaming</Button>
            <Button disabled={!this.deviceState!.streaming || !this.isWssOpened}
                    onClick={this.stopStreaming}>Stop
                streaming</Button>
        </React.Fragment>

        const latestValues = () => {
            const calc = (seriesId: string) => {
                const data = this.chartRef?.dataList?.find((x: IChartSeriesInfo) => x.seriesId === seriesId)?.data
                return !!data?.find((x: IChartValue) => !!x.y) // Show only if any point has value
                    ? data[data.length - 1].y
                    : undefined
            }
            const format = (seriesId: string, title: string, precision: number, suffix: string) => {
                const value = calc(seriesId)
                return value === undefined ? undefined :
                    <Col><Statistic title={title} value={value} precision={precision} suffix={suffix}/></Col>
            }
            return <Row type={'flex'} gutter={30} justify={'center'}>
                {format(this.CvtSeriesId, 'CVT', 2, '')}
                {format(this.HrSeriesId, 'HEART RATE', 0, 'bpm')}
                {format(this.RespSeriesId, 'RESPIRATION RATE', 1, 'bpm')}
                {format(this.TempSeriesId, 'TEMPERATURE', 1, 'C')}
            </Row>
        }
        const deviceControlView =
            <React.Fragment>
                <Row gutter={[15, 15]} style={{marginBottom: 30}}>
                    <Col>
                        <Row type={'flex'} justify={'start'} gutter={15}>
                            <Col>
                                {connectWssView}
                            </Col>
                            <Col>
                                {deviceStreamingView}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        {deviceSensorsView}
                    </Col>
                </Row>
            </React.Fragment>
        const extensionsFormatter = () => {
            return <div>
                <Row type={'flex'} gutter={[15, 15]} justify={'space-around'}>
                    {
                        this.deviceState?.services?.hr && <React.Fragment>
                            <Col span={6}>
                                <span>{this.movingAverageOption.find(x => x.movingAverageId === 0)!.title}</span>
                                <Slider {...this.movingAverageOption.find(x => x.movingAverageId === 0)}
                                        value={this.movingAverageOption.find(x => x.movingAverageId === 0)!.value}
                                        onChange={async (value: SliderValue) => {
                                            this.movingAverageOption.find(x => x.movingAverageId === 0)!.value = value as number
                                        }}/>
                            </Col>
                            <Col span={6}>
                                <span>{this.movingAverageOption.find(x => x.movingAverageId === 1)!.title}</span>
                                <Slider {...this.movingAverageOption.find(x => x.movingAverageId === 1)}
                                        value={this.movingAverageOption.find(x => x.movingAverageId === 1)!.value}
                                        onChange={async (value: SliderValue) => {
                                            this.movingAverageOption.find(x => x.movingAverageId === 1)!.value = value as number
                                        }}/>
                            </Col>
                        </React.Fragment>
                    }
                    {
                        this.deviceState?.services?.br && <Col span={6}>
                            <span>{this.movingAverageOption.find(x => x.movingAverageId === 2)!.title}</span>
                            <Slider {...this.movingAverageOption.find(x => x.movingAverageId === 2)}
                                    value={this.movingAverageOption.find(x => x.movingAverageId === 2)!.value}
                                    onChange={async (value: SliderValue) => {
                                        this.movingAverageOption.find(x => x.movingAverageId === 2)!.value = value as number
                                    }}/>
                        </Col>
                    }
                    {
                        this.deviceState?.services?.temp && <Col span={6}>
                            <span>{this.movingAverageOption.find(x => x.movingAverageId === 3)!.title}</span>
                            <Slider {...this.movingAverageOption.find(x => x.movingAverageId === 3)}
                                    value={this.movingAverageOption.find(x => x.movingAverageId === 3)!.value}
                                    onChange={async (value: SliderValue) => {
                                        this.movingAverageOption.find(x => x.movingAverageId === 3)!.value = value as number
                                    }}/>
                        </Col>
                    }
                </Row>
            </div>
        }

        const ecgChart = <Chart ref={(ref: Chart) => this.chartEcgRef = ref} {...this.chartPropsEcg}/>

        const chart = <Row>
            <Col>
                <Chart ref={ref => this.chartRef = ref} {...this.chartProps}/>
            </Col>
            <Col>{extensionsFormatter()}</Col>
        </Row>

        return (
            <React.Fragment>
                <Row gutter={[15, 15]}>
                    {deviceControlView}
                </Row>
                {this.loading ? <SpinOnCenter/> : <div>
                    <Row>
                        {latestValues()}
                    </Row>
                    <Row>
                        {(!!this.deviceState?.services?.hr || !!this.deviceState?.services?.br || !!this.deviceState?.services?.temp) && chart}
                    </Row>
                    <Row>
                        {!!this.deviceState?.services?.ecg && ecgChart}
                    </Row>
                </div>}
            </React.Fragment>
        );
    }
}

export default RealtimeChart;
