import {Button, Dropdown, Menu} from "antd";
import React from "react";

export interface IItemAction {
    title: string;
    action: (text: any, record: any) => void;
}
export interface IProps {
    actions:IItemAction[];
    text: any;
    record: any;
}

export class ActionsButton extends React.Component<IProps> {
    render() {
        return (<div>
            <Dropdown
                trigger={['click']}
                overlay={
                    <Menu>
                        {this.props.actions.map((actionInfo, index) =>
                            <Menu.Item key={actionInfo.title}
                                       onClick={() => actionInfo.action(this.props.text, this.props.record)}>{actionInfo.title}
                            </Menu.Item>)
                        }
                    </Menu>
                }
                placement="bottomLeft"
            >
                <Button type="link" icon="setting"/>
            </Dropdown>
        </div>);
    }
}