import React from "react";
import {Card, Col, DatePicker, Form, Input, Row, Select} from "antd";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {errorUtils, IHasValidationErrorsProperty} from "../../../services/utils/ErrorUtils";
import {FormComponentProps} from "antd/es/form";
import {RouteComponentProps} from "react-router-dom";
import rules from "./residents.rules";
import {action, computed, observable} from "mobx";
import {
    IAddResidentInput,
    IResidentDetailsDto,
    IUpdateResidentInput,
    ResidentsStore
} from "../../../stores/residents/residentsStore";
import moment from "moment-timezone";
import {EthniticySelect} from "../../Select/EthniticySelect";
import {RoomSelect} from "../../Select/RoomSelect";
import {OrgUnitSelect} from "../../Select/OrgUnitSelect";
import {UserStore} from "../../../stores/user/userStore";
import {Gender, GenderSameAsBirth} from "../../../services/api/ProCvtClient";
import {enumHelper} from "../../../services/utils/EnumHelper";
import {SubmitCancelFormRow} from "../../Buttons/SubmitCancelFormRow";

interface IProps extends FormComponentProps, RouteComponentProps {
    residentsStore?: ResidentsStore
    userStore?: UserStore
    personId?: number
}

@inject(InjectNames.residentsStore, InjectNames.userStore)
@observer
class AddOrUpdateResident extends React.Component<IProps, any> implements IHasValidationErrorsProperty {
    async componentDidMount() {
        await this.initForm()
    }

    validationErrors = {
        firstName: '',
        lastName: '',
        email: '',
        dateOfBirth: '',
        ethnicityId: '',
        orgUnitId: '',
        roomId: '',
        gender: '',
        genderSameAsBirth: '',
    }

    editableDetails?: IResidentDetailsDto
    @observable loading = false

    // Need get orgUnitId from resident instead. Task #18608
    @observable orgUnitForRoomSelect?: number = this.props.userStore!.isSuperUser
        ? this.isUpdateMode ? this.props.userStore!.userDetails?.orgUnitId : undefined
        : this.props.userStore!.userDetails?.orgUnitId

    get isUpdateMode() {
        return !!this.props.personId
    }

    get displayOrgUnit() {
        return this.props.userStore!.isSuperUser && !this.isUpdateMode
    }

    @computed get actionTitle() {
        return this.isUpdateMode ? 'Update' : 'Create'
    }

    @action initForm = async () => {
        if (this.isUpdateMode) {
            this.editableDetails = await this.props.residentsStore!.getResidentDetails(this.props.personId!)
            this.props.form.setFieldsValue({
                email:this.editableDetails.email,
                ethnicityId:this.editableDetails.ethnicityId,
                firstName:this.editableDetails.firstName,
                gender:this.editableDetails.gender,
                genderSameAsBirth:this.editableDetails.genderSameAsBirth,
                lastName:this.editableDetails.lastName,
                roomId:this.editableDetails.roomId,
                dateOfBirth: !!this.editableDetails.dateOfBirth ? moment(this.editableDetails.dateOfBirth) : undefined
            })
        }
    }

    @action resetFormAndRouteToMain = () => {
        this.resetFields()
        this.props.history.push('/residents')
    }

    @action handleSubmit = async (e: any) => {
        e.preventDefault();
        await this.props.form.validateFields(async (err: any, values: any) => {
            if (!err) {
                await errorUtils.execute({context: this, loadingFieldName: 'loading'}, async () => {
                    if (this.isUpdateMode)
                        await this.handleUpdate(values)
                    else
                        await this.handleAdd(values)
                    this.resetFormAndRouteToMain()
                })
            }
        });
    }

    handleUpdate = async (values: any) => {
        const input: IUpdateResidentInput = {
            ...values,
            personId: this.props.personId!,
            modifiedTime: this.editableDetails?.modifiedTime,
        }
        await this.props.residentsStore!.updateResident(input)
    }

    handleAdd = async (values: any) => {
        const input: IAddResidentInput = {
            ...values,
            orgUnitId: this.displayOrgUnit ? values.orgUnitId : this.props.userStore!.userDetails!.orgUnitId
        }
        await this.props.residentsStore!.addResident(input)
    }

    @action resetFields = () => {
        this.props.form.resetFields()
    }

    render() {
        const twoCol = (col1: any, col2: any) => {
            const span = 12;
            return <Row gutter={15}><Col span={span}>{col1}</Col><Col span={span}>{col2}</Col></Row>
        }
        const {getFieldDecorator} = this.props.form;
        return (
            <Card title={`${this.actionTitle} resident`}>
                <Form onSubmit={this.handleSubmit}>
                    <React.Fragment>
                        {twoCol(<Form.Item
                                label={'First name'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.firstName, 'firstName', this.props.form)}>
                                {getFieldDecorator('firstName', {rules: rules.firstName})(
                                    <Input placeholder={'First name'}
                                           onChange={event => errorUtils.resetValidationErrorState('firstName', this)}/>)}
                            </Form.Item>,
                            <Form.Item
                                label={'Last name'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.lastName, 'lastName', this.props.form)}>
                                {getFieldDecorator('lastName', {rules: rules.lastName})(
                                    <Input placeholder={'Last name'}
                                           onChange={event => errorUtils.resetValidationErrorState('lastName', this)}/>)}
                            </Form.Item>)}

                        {twoCol(<Form.Item
                                label={'Email'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.email, 'email', this.props.form)}>
                                {getFieldDecorator('email', {rules: rules.email})(
                                    <Input placeholder={'Email'}
                                           onChange={event => errorUtils.resetValidationErrorState('email', this)}/>)}
                            </Form.Item>,
                            <Form.Item
                                label={'Date of birth'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.dateOfBirth, 'dateOfBirth', this.props.form)}>
                                {getFieldDecorator('dateOfBirth', {rules: rules.dateOfBirth})(
                                    <DatePicker placeholder={'Date of birth'}/>)}
                            </Form.Item>)
                        }

                        {this.displayOrgUnit &&
                        twoCol(<Form.Item
                                label={'Org unit'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.orgUnitId, 'orgUnitId', this.props.form)}>
                                {getFieldDecorator('orgUnitId', {
                                    rules: rules.orgUnitId,
                                })(
                                    <OrgUnitSelect
                                        onChange={(value) => {
                                            errorUtils.resetValidationErrorState('orgUnitId', this)
                                            this.orgUnitForRoomSelect = value
                                        }}/>)}
                            </Form.Item>,
                            null)
                        }
                        {twoCol(<Form.Item
                                label={'Ethnicity'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.ethnicityId, 'ethnicityId', this.props.form)}>
                                {getFieldDecorator('ethnicityId', {rules: rules.ethnicityId})(
                                    <EthniticySelect/>)}
                            </Form.Item>,
                            <Form.Item
                                label={'Room'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.roomId, 'roomId', this.props.form)}>
                                {getFieldDecorator('roomId', {rules: rules.roomId})(
                                    <RoomSelect currentRoom={this.editableDetails?.roomId ? {roomId: this.editableDetails?.roomId, roomName: this.editableDetails?.roomName} : undefined}
                                                disabled={this.displayOrgUnit && !this.orgUnitForRoomSelect}
                                                occupied={false}
                                                orgUnitId={this.orgUnitForRoomSelect}/>)}
                            </Form.Item>)
                        }

                        {twoCol(<Form.Item
                                label={'Gender'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.gender, 'gender', this.props.form)}>
                                {getFieldDecorator('gender', {rules: rules.gender})(
                                    <Select>
                                        {Object.keys(Gender).map(gender => (
                                            <Select.Option key={gender}
                                                           value={gender}>{enumHelper.getFriendlyName(gender)}</Select.Option>
                                        ))}
                                    </Select>)}
                            </Form.Item>,
                            <Form.Item
                                label={'Gender same as birth'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.genderSameAsBirth, 'genderSameAsBirth', this.props.form)}>
                                {getFieldDecorator('genderSameAsBirth', {rules: rules.genderSameAsBirth})(
                                    <Select>
                                        {Object.keys(GenderSameAsBirth).map(gender => (
                                            <Select.Option key={gender}
                                                           value={gender}>{enumHelper.getFriendlyName(gender)}</Select.Option>
                                        ))}
                                    </Select>)}
                            </Form.Item>)
                        }

                    </React.Fragment>
                    <SubmitCancelFormRow loading={this.loading} okTitle={this.actionTitle}
                                         onCancel={this.resetFormAndRouteToMain}/>
                </Form>
            </Card>
        );
    }
}


export default Form.create<IProps>()(AddOrUpdateResident);
