import React from "react";
import {RouteComponentProps} from "react-router";
import {inject, observer} from "mobx-react";
import {CompareTraces} from '../../../components/Traces/CompareTraces';
import {Button, Card, Row} from "antd";
import {TracesStore} from "../../../stores/traces/tracesStore";
import {InjectNames} from "../../../stores/initializeStores";

interface IProps extends RouteComponentProps {
    tracesStore: TracesStore
}

@inject(InjectNames.tracesStore)
@observer
export class CompareScene extends React.Component<IProps> {

    async componentWillUnmount() {
        await this.props.tracesStore!.dispose()
    }

    routeToTraces = () => {
        this.props.history.push({pathname: '/traces/table'})
    };

    render() {
        return <div>
            <Row style={{marginBottom: '15px'}} type={'flex'} justify={'start'}>
                <Button type={'default'} onClick={this.routeToTraces}>To Traces</Button>
            </Row>
            <Card title={'Compare traces series'}>
                <CompareTraces {...this.props}/>
            </Card>
        </div>
    }
}
