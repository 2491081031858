import * as React from 'react';
import {observer} from "mobx-react";
import {Button, Card, Col, Row} from "antd";
import {RouteComponentProps} from "react-router";
import {antdIconNames} from "../../../constants/Icon";
import {ResidentsTable} from "../../../components/Tables/ResidentsTable";

interface IProps extends RouteComponentProps {
}

@observer
class ResidentsTableScene extends React.Component<IProps> {
    render() {
        return (
            <React.Fragment>
                <Row style={{paddingBottom: '15px'}}>
                    <Col>
                        <Button icon={antdIconNames.Plus} type={"default"}
                                onClick={() => this.props.history.push({pathname: '/residents/invite'})}>
                            Add resident
                        </Button>
                    </Col>
                </Row>
                <Card title="Residents table">
                    <ResidentsTable {...this.props}/>
                </Card>
            </React.Fragment>
        );
    }
}

export default ResidentsTableScene;
