import * as React from 'react';
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../../stores/initializeStores";
import {RouteComponentProps} from "react-router";
import {TracesStore} from "../../../../stores/traces/tracesStore";

interface IProps extends RouteComponentProps {
    tracesStore?: TracesStore;
}

@inject(InjectNames.tracesStore)
@observer
class SubjectDashboardScene extends React.Component<IProps> {

    render() {
        return (
            <React.Fragment>
                <div>SubjectDashboardScene is not implemented</div>
            </React.Fragment>
        );
    }
}

export default SubjectDashboardScene;
