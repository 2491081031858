import React from "react";
import { Modal} from "antd";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";
import {AnnotationStore, IAddAnnotationTraceInfo} from "../../stores/annotationStore/annotationStore";
import ReplyForm from "./AnnotationReplyForm";

interface IProps {
    annotationStore?: AnnotationStore
    model?: InitAnnotation;
    onClose: () => void
    onAdded: (annotationId: number) => void
}

export interface InitAnnotation {
    traceId: number;
    traceInfo: IAddAnnotationTraceInfo;
    sequence?: number | undefined;
}

@inject(InjectNames.annotationStore)
@observer
export class AnnotationCreateModal extends React.Component<IProps> {
    render(): React.ReactElement {
        return <Modal visible={!!this.props.model} onCancel={this.props.onClose} closable={true} footer={false}
                      width={'60%'}>
            <div style={{paddingTop: 30}}>
                {
                    !!this.props.model && <ReplyForm traceInfo={this.props.model?.traceInfo} buttonName={'Create'} onAdded={this.props.onAdded} addInputWithoutText={{
                        traceId: this.props.model!.traceId,
                        sequence: this.props.model!.sequence
                    }}/>
                }
            </div>
        </Modal>
    }
}
