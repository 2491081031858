import {RouteComponentProps} from "react-router";
import {TracesStore} from "../../../stores/traces/tracesStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import React from "react";
import {SpinOnCenter} from "../../../components/Loading/SpinOnCenter";
import {regexHelper} from "../../../services/utils/RegexHelper";
import {PersonOverviewScene} from "./PersonOverviewScene";

interface IRouteProps {
    traceId?: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
    tracesStore?: TracesStore
}

@inject(InjectNames.tracesStore)
@observer
export class ViewChartByTraceIdScene extends React.Component<IProps> {
    public  static routPath = {
        byTraceId: '/traces/compare/:traceId',
        byTraceIdFormat: (traceId: number) => `/traces/compare/${traceId}`
    }
    async componentDidMount() {
        if (regexHelper.isEmptyString(this.props.match.params.traceId)) {
            this.props.history.push('/traces/table')
        } else {
            try {
                let trace = await this.props.tracesStore?.getTraceById(Number.parseInt(this.props.match.params.traceId!))
                this.props.tracesStore!.selectedTraces.push(trace!)
                this.props.history.push(PersonOverviewScene.routePaths.sessionFormat(trace!.subjectId))
            } catch {
                this.props.history.push('/traces/table')
            }
        }
    }

    render() {
        return <SpinOnCenter/>;
    }
}
