import moment from "moment-timezone";
import {DurationInputArg1, DurationInputArg2} from "moment";

class DateTimeHelper {
    hour_min_a(date: Date | undefined) {
        if (!date)
            return "";
        return moment(date).format("hh:mm a")
    }
    hour_min_sec_a(date: Date | undefined) {
        if (!date)
            return "";
        return moment(date).format("hh:mm:ss a")
    }

    day_month_year(date: Date | undefined) {
        if (!date)
            return "";
        return moment(date).format("DD.MM.YYYY")
    }

    day_month_hour_min(date: Date | undefined) {
        if (!date)
            return "";
        return moment(date).format("DD.MM hh:mm a")
    }

    day_month_year_hour_min_sec(date: Date | undefined) {
        if (!date)
            return "";
        return moment(date).format("DD.MM.YY hh:mm:ss a");
    }
    day_month_year_hour_min(date: Date | undefined) {
        if (!date)
            return "";
        return moment(date).format("DD.MM.YY hh:mm a");
    }
    day_month_year_hour_24_min(date: Date | undefined) {
        if (!date)
            return "";
        return moment(date).format("DD.MM.YY kk:mm");
    }

    msToDurationFormat(ms: number) {
        const duration = moment.utc(ms).format("HH:mm:ss");
        return `${duration}`
    }
    msToDurationCharactersFormat(ms: number) {
        const duration = moment.utc(ms).format("HH [hrs] mm [min] ss [sec]");
        return `${duration}`
    }

    durationMsToDate(durationMs: number, date: Date) {
        return moment(date).add((durationMs), 'ms').toDate();
    }

    dateToDurationMs(date: Date, date2: Date) {
        return moment(date).add((date2.getTime() * -1), 'ms').toDate().getTime();
    }

    offsetByNow(offset: DurationInputArg1, offsetType: DurationInputArg2) {
        return moment().add(offset, offsetType).toDate();
    }

    //Date converter for api
    overrideToISOStringWithTimezone(date?: Date) {
        if (!date)
            return;
        date.toISOString = function () {
            return moment(this).format();
        }
    }

}

const dateTimeHelper = new DateTimeHelper();
export {dateTimeHelper}

/** FORMATS
 * https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/ */

/** moment().utcOffset
 * The utcOffset function has an optional second parameter which accepts a boolean value indicating whether to keep the existing time of day.
 * Passing false (the default) will keep the same instant in Universal Time, but the local time will change.
 * Passing true will keep the same local time, but at the expense of choosing a different point in Universal Time.
 * */
