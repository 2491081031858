import React from "react";
import {IRoomDetailsDto, RoomsStore} from "../../../stores/roomsStore/roomsStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {Form, Input} from "antd";
import {FormComponentProps} from "antd/es/form";
import {RouteComponentProps} from "react-router-dom";
import {errorUtils, IHasValidationErrorsProperty} from "../../../services/utils/ErrorUtils";
import {OrgUnitSelect} from "../../Select/OrgUnitSelect";
import {UserStore} from "../../../stores/user/userStore";
import {action, computed, observable} from "mobx";
import rules from "./roomRules";
import {SubmitCancelFormRow} from "../../Buttons/SubmitCancelFormRow";


interface IProps extends FormComponentProps, RouteComponentProps {
    roomsStore?: RoomsStore
    userStore?: UserStore
    roomId?: number;
}

@inject(InjectNames.roomsStore, InjectNames.userStore)
@observer
class RoomForm extends React.Component<IProps, any> implements IHasValidationErrorsProperty {
    validationErrors = {
        orgUnitId: '',
        roomId: '',
        roomName: '',
        roomLocation: '',
    }

    async componentDidMount() {
        if (this.updateMode) {
            this.editedModel = await this.props.roomsStore!.getRoomDetails(this.props.roomId!)
            this.props.form.setFieldsValue({
                roomName: this.editedModel.roomName,
                roomLocation: this.editedModel.roomLocation
            })
        }
    }

    @observable loading = false;
    @observable editedModel?: IRoomDetailsDto;

    @computed get actionTitle() {
        return this.updateMode ? 'Update' : 'Create'
    }

    @computed get displayOrgUnit() {
        return this.props.userStore!.isSuperUser && !this.updateMode
    }

    @computed get updateMode() {
        return this.props.roomId !== null && this.props.roomId !== undefined
    }

    @action resetFormAndRouteToMain = () => {
        this.props.form.resetFields()
        this.props.history.push('/rooms')
    }

    @action handleSubmit = async (e: any) => {
        e.preventDefault();
        await this.props.form.validateFields(async (err: any, values: any) => {
            if (!err) {
                let concreteHandler = this.updateMode ? this.handleUpdate : this.handleAdd
                await errorUtils.execute({
                    context: this,
                    loadingFieldName: 'loading',
                    showSuccessAlert: true,
                    showErrorAlert: true
                }, async () => {
                    await concreteHandler(values)
                    this.resetFormAndRouteToMain()
                })
            }
        });

    }

    @action handleAdd = async (values: any) => {
        await this.props.roomsStore!.addRoom({
            orgUnitId: this.displayOrgUnit ? values.orgUnitId : this.props.userStore!.userDetails?.orgUnitId,
            roomName: values.roomName,
            roomLocation: values.roomLocation
        })
    }

    @action handleUpdate = async (values: any) => {
        await this.props.roomsStore!.updateRoom({
            modifiedTime: this.editedModel?.modifiedTime,
            roomId: this.props.roomId!,
            roomName: values.roomName,
            roomLocation: values.roomLocation
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form;

        return (<Form onSubmit={this.handleSubmit}>
            {
                this.displayOrgUnit && <Form.Item
                    label={'Org unit'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.orgUnitId, 'orgUnitId', this.props.form)}>
                    {getFieldDecorator('orgUnitId', {
                        rules: rules.orgUnitId, initialValue: undefined
                    })(
                        <OrgUnitSelect onChange={() => errorUtils.resetValidationErrorState('orgUnitId', this)}/>)}
                </Form.Item>
            }

            <Form.Item
                label={'Room name'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.roomName, 'roomName', this.props.form)}>
                {getFieldDecorator('roomName', {rules: rules.roomName})(
                    <Input placeholder={'Room name'}
                           onChange={event => errorUtils.resetValidationErrorState('roomName', this)}/>)}
            </Form.Item>

            <Form.Item
                label={'Room location'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.roomLocation, 'roomLocation', this.props.form)}>
                {getFieldDecorator('roomLocation', {rules: rules.roomLocation})(
                    <Input placeholder={'Enter description'}
                           onChange={event => errorUtils.resetValidationErrorState('roomLocation', this)}/>)}
            </Form.Item>
            <SubmitCancelFormRow loading={this.loading} okTitle={this.actionTitle} onCancel={this.resetFormAndRouteToMain}/>
        </Form>)
    }
}

export default Form.create<IProps>()(RoomForm)
