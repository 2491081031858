import {action, observable} from 'mobx';
import {IClientWebsocketUrl, ProCvtClient} from "../../services/api/ProCvtClient";
import {RootStore} from "../initializeStores";
import {Auth} from "aws-amplify";
import {CognitoUserSession} from "amazon-cognito-identity-js";

export class AuthStore {
    constructor(client: ProCvtClient, rootStore: RootStore) {
        this.client = client!;
        this.rootStore = rootStore;
    }

    private readonly client: ProCvtClient;
    private readonly rootStore: RootStore;

    @observable token: string | null = null;
    accessToken: string | null = null;
    idToken: string | null = null;

    @observable isAuthorized: boolean = false;

    @action
    onLogIn = async (session: CognitoUserSession) => {
        let accessToken = session.getAccessToken()
        let idToken = session.getIdToken()

        this.idToken = idToken.getJwtToken()
        this.accessToken = accessToken.getJwtToken()
        this.token = this.idToken
        this.isAuthorized = true;

    }

    @action
    logOut = async () => {
        await Auth.signOut()
    };

    @action
    onLogOut = async () => {
        this.isAuthorized = false;
        this.wssUrlsCache = undefined
        await this.rootStore.onLogOut();
        // Should reset token after stores clear data to can call api.
        this.token = null;
        this.accessToken = null;
        this.idToken = null;
    };

    // May save to localStorage, but we are needed to know cache key for different users
    _wssUrlsCache?: IClientWebsocketUrl = undefined
    get wssUrlsCache() {
        return this._wssUrlsCache
    }

    set wssUrlsCache(value) {
        this._wssUrlsCache = value
    }

    @action
    getClientWebsocketUrl = async () => {
        const seconds30 = 30000
        // urlExpirationTime should be converted to local time by swagger client
        if (!this.wssUrlsCache || this.wssUrlsCache!.urlExpirationTime.getTime() < Date.now() + seconds30)
            this.wssUrlsCache = (await this.client.authorization_GetClientWebsocketUrl())!

        return this.wssUrlsCache!.url!
    };
}
