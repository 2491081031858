import * as React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {inject, observer} from "mobx-react";
import {UserStore} from "../../stores/user/userStore";
import {InjectNames} from "../../stores/initializeStores";
import AppLayoutScene from "./Layout/AppLayoutScene";
import {getMenuListForUserType, getRoutesForUserType} from "../../RouteSettings";
import {AuthStore} from "../../stores/auth/authStore";
import {observable} from "mobx";
import moment from 'moment';
import {enumHelper} from "../../services/utils/EnumHelper";

declare interface IProps extends RouteComponentProps {
    userStore?: UserStore;
    authStore?: AuthStore;
}

@inject(InjectNames.userStore, InjectNames.authStore)
@observer
export default class AuthorizedContainer extends React.Component<IProps> {
    @observable loading = true;

    async componentDidMount() {
        try {
            await this.props.userStore!.setTimeZone(moment.tz.guess());
            await this.props.userStore!.loadMyDetails();
        } finally {
            this.loading = false;
        }
    }

    render() {
        const allowedMenuItems = getMenuListForUserType(this.props.userStore!.userType)
        const allowedRoutes = getRoutesForUserType(this.props.userStore!.userType)
        return (
            <AppLayoutScene {...this.props}
                            loading={this.loading}
                            menuItems={allowedMenuItems}
                            routes={allowedRoutes}
                            userName={this.props.userStore!.userName}
                            userType={enumHelper.getFriendlyName(this.props.userStore!.userDetails?.userType)}
                            userLogo={this.props.userStore!.userLogo}
                            logOut={this.props.authStore!.logOut}/>
        );
    }
}
