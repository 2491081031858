import {IChartSeriesInfo} from "./IChartSeriesInfo";
import {
    IChartSeriesInfoCompareMode,
    ITraceSeriesPointDataJson,
    TraceSeriesType
} from "../../services/utils/ChartHelper";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";
import {GetTraceRrIntervalsRequest, IDeviceHeartbeatModel, TraceType} from "../../services/api/ProCvtClient";
import {IDictionary} from "../../stores/user/userStore";
import {Chart} from "./Chart";
import {proCvtClientInstance} from "../../services/api/ProCvtClientInstance";
import {Modal} from "antd";

export interface IChartExportToCvtProps {
    traceInfo: IChartExportToCvtPropsChartInfo
    originData: IChartSeriesInfoCompareMode[]
    rrIntervals: IDeviceHeartbeatModel[]
    mvAvgData: IChartSeriesInfo[]
    zoom: { indexStart: number, indexEnd: number }
}

export interface IChartExportToCvtPropsChartInfo {
    traceId: string;
    traceDate: Date;
    traceDuration?: string | undefined;
    traceType: TraceType;
    subjectName: string;
    traceSeriesType: TraceSeriesType;
}

export class ChartExport {

    /**
     * All series should be from one trace (hr, cvt, rr, etc...), multiple traces mode is not allowed (group series by trace and call this function).
     * */
    exportTraceSeriesToCsvFile = (props: IChartExportToCvtProps) => {
        // Validation
        if (!props.originData[0]?.data?.length || !props.mvAvgData[0]?.data?.length || !props.rrIntervals?.length) {
            Modal.info({
                title: 'Empty source data.',
                content: `Origin series: ${props.originData[0]?.data?.length ?? 0}. MvAvg series: ${props.mvAvgData[0]?.data?.length ?? 0}. Rr intervals: ${props.rrIntervals?.length ?? 0}.`
            })
            return;
        }

        if (props.mvAvgData?.length !== props.originData?.length) {
            Modal.error({
                title: 'Number of origin and mvAvg series(hr,cvt, rr, etc ...) should be equal.',
                content: `Origin series: ${props.originData?.length ?? 0}. MvAvg series: ${props.mvAvgData?.length ?? 0}.`
            })
            return;
        }

        let nl = "\n"
        if (navigator.appVersion.indexOf("Win") !== -1)
            nl = "\r\n"
        else if (navigator.appVersion.indexOf("Mac") !== -1)
            nl = "\r"

        const separator = ","
        const wrapText = (value: any) => `"${value}"`

        // Find zoom options
        const indexStart = props.zoom.indexStart
        const indexEnd = props.zoom.indexEnd

        let sharedColumns = [
            wrapText('Trace ID'),
            wrapText('Name'),
            wrapText('Time'),
            wrapText('Duration'),
            wrapText('Ms from start'),
            wrapText('RR interval'),
            wrapText('Valid RR count'),
        ]
        let columns: string [] = [...sharedColumns]
        let rows: string [] [] = []

        // Add shared data
        props.originData[0]!.data
            .filter((_, seriesDataItemIndex) => seriesDataItemIndex >= indexStart && seriesDataItemIndex <= indexEnd)
            .forEach((seriesDataItem, seriesDataItemIndex) => {
                rows.push([])
                const json = (seriesDataItem.json as ITraceSeriesPointDataJson)
                const rrInterval = props.rrIntervals.find(x => x.sequence === json.sequence)
                // Shared columns
                rows[seriesDataItemIndex] = [
                    wrapText(props.traceInfo.traceId), // 'Trace ID'
                    wrapText(props.traceInfo.subjectName), // 'Name'
                    wrapText(dateTimeHelper.day_month_hour_min(json.sequenceDate)), // 'Time'
                    wrapText(dateTimeHelper.msToDurationFormat(json.sequence)), // 'Duration'
                    wrapText(json.sequence), // 'Ms from start'
                    wrapText(rrInterval?.rrInt), // 'RR interval'
                    wrapText(json.validRrIntCount), // 'Valid RR count'
                ]
            })

        // Save rows values
        props.originData.forEach(series => {
            columns.push(wrapText(series.traceSeriesType?.toUpperCase() ?? series.name))
            columns.push(wrapText('Avg ' + series.traceSeriesType?.toUpperCase() ?? series.name))
        })

        props.originData
            .forEach((series, seriesIndex) => {
                const mvAvgSeriesData = props.mvAvgData.find(x => x.seriesId === series.seriesId)?.data?.slice()
                series.data
                    .filter((_, seriesDataItemIndex) => seriesDataItemIndex >= indexStart && seriesDataItemIndex <= indexEnd)
                    .forEach((seriesDataItem, seriesDataItemIndex) => {
                        if (rows[seriesDataItemIndex] === undefined)
                            rows.push([])

                        rows[seriesDataItemIndex].push(wrapText(seriesDataItem.y?.toFixed(2) ?? ''))
                        rows[seriesDataItemIndex].push(wrapText(mvAvgSeriesData?.find(x => x.index === seriesDataItem.index)?.y?.toFixed(2) ?? ""))
                    })
            })

        // Align values count
        const numberOfSeries = columns.length
        rows.forEach((rowValues, rowIndex) => {
            const addNumber = numberOfSeries - rowValues.length
            for (let i = 0; i < addNumber; i++) {
                rowValues.push(wrapText(""))
            }
        })

        // Format csv
        let additionalData = ''
        additionalData += wrapText('Subject name') + separator + wrapText(props.traceInfo.subjectName) + nl
        additionalData += wrapText('Trace ID') + separator + wrapText(props.traceInfo.traceId) + nl
        additionalData += wrapText('Date') + separator + wrapText(dateTimeHelper.day_month_year(props.traceInfo.traceDate)) + nl
        additionalData += wrapText('Time') + separator + wrapText(dateTimeHelper.hour_min_a(props.traceInfo.traceDate)) + nl
        additionalData += nl
        additionalData += nl

        let csvString = additionalData + columns.join(separator)
        csvString += nl
        rows.forEach(row => {
            csvString += row.join(separator)
            csvString += nl
        })

        // Download csv
        const fileName = `${props.traceInfo.subjectName}_${dateTimeHelper.day_month_year_hour_min(props.traceInfo.traceDate)}.csv`.replace(/ /g, '_').toUpperCase()
        let csvContent = "data:text/csv;charset=utf-8," + csvString
        const link = document.createElement("a")
        link.setAttribute("href", encodeURI(csvContent))
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    exportToCsv = async (chartSeries: IChartSeriesInfoCompareMode[], chartRef: Chart) => {
        let groupedByTraceId: IDictionary<{
            traceInfo: IChartExportToCvtPropsChartInfo,
            originData: IChartSeriesInfoCompareMode[]
            mvAvgData: IChartSeriesInfo[],
        }> = {}

        chartSeries.forEach(source => {
            if (groupedByTraceId[source.traceId.toString()] === undefined) {
                groupedByTraceId[source.traceId.toString()] = {
                    mvAvgData: [],
                    originData: [],
                    traceInfo: {
                        subjectName: source.subjectName,
                        traceDate: source.traceDate,
                        traceDuration: source.traceDuration,
                        traceId: `${source.traceId}`,
                        traceSeriesType: source.traceSeriesType,
                        traceType: source.traceType
                    }
                }
                groupedByTraceId[source.traceId.toString()].mvAvgData = chartRef?.dataList?.filter((x: IChartSeriesInfo) => (x as IChartSeriesInfoCompareMode).traceId === source.traceId) ?? []
            }

            groupedByTraceId[source.traceId.toString()].originData.push(source)
        })

        for (let traceId in groupedByTraceId) {
            if (!groupedByTraceId.hasOwnProperty(traceId))
                break

            let zoom = {
                indexStart: groupedByTraceId[traceId]!.originData[0].data.findIndex(data => {
                    let dataX = data.x instanceof Date ? data.x as Date : new Date(data.x)
                    let zoomDataStart = new Date(chartRef?.zoomValueStart ?? 0)
                    let zoomDataEnd = new Date(chartRef?.zoomValueEnd ?? 0)
                    return dataX.getTime() >= zoomDataStart.getTime() && dataX.getTime() <= zoomDataEnd.getTime()
                }),
                indexEnd: -1,
            }
            if (zoom.indexStart !== -1) {
                zoom.indexEnd = groupedByTraceId[traceId]!.originData[0].data.findIndex((data, dataIndex, dataSource) => {
                    let dataX = data.x instanceof Date ? data.x as Date : new Date(data.x)
                    let zoomDataEnd = new Date(chartRef?.zoomValueEnd ?? 0)
                    return dataX.getTime() >= zoomDataEnd.getTime() || dataIndex === dataSource.length - 1
                })
            }

            let jsonStart = groupedByTraceId[traceId]!.originData[0].data[zoom.indexStart].json as ITraceSeriesPointDataJson
            let jsonEnd = groupedByTraceId[traceId]!.originData[0].data[zoom.indexEnd].json as ITraceSeriesPointDataJson
            let rrIntervals = ((await proCvtClientInstance.diagnosis_GetTraceRrIntervals(new GetTraceRrIntervalsRequest({
                traceId: Number.parseInt(groupedByTraceId[traceId]!.traceInfo.traceId),
                startMs: jsonStart.sequence,
                endMs: jsonEnd.sequence
            })))!)

            const props: IChartExportToCvtProps = {
                traceInfo: groupedByTraceId[traceId]!.traceInfo,
                mvAvgData: groupedByTraceId[traceId]!.mvAvgData ?? [],
                originData: groupedByTraceId[traceId]!.originData ?? [],
                zoom: zoom,
                rrIntervals: rrIntervals
            }

            this.exportTraceSeriesToCsvFile(props)
        }
    }

}
