import * as React from 'react';
import {DevicesStore} from "../../stores/devicesStore/devicesStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";
import {observable} from "mobx";
import {DeviceOnlineStatus, IDeviceModel} from "../../services/api/ProCvtClient";
import {ColumnProps} from "antd/es/table";
import {Card, Tag, Table} from "antd";
import {ActionsButton} from "./ActionsButton";
import {RouteComponentProps} from 'react-router-dom';
import {errorUtils} from "../../services/utils/ErrorUtils";


interface IProps extends RouteComponentProps {
    devicesStore: DevicesStore;
}

@inject(InjectNames.devicesStore)
@observer
export class DevicesTable extends React.Component<IProps> {
    async componentDidMount() {
        await this.loadDevices()
    }

    async loadDevices() {
        await errorUtils.execute({context: this, loadingFieldName: 'loading'}, async () => {
            this.devices = await this.props.devicesStore!.getDevices({})
        })
    }

    @observable loading = true
    @observable devices: IDeviceModel[] = []

    deviceColumns: ColumnProps<IDeviceModel>[] = [
        {title: 'Device', dataIndex: 'deviceName'},
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => record.status === DeviceOnlineStatus.ONLINE ? <Tag color={'green'}>Online</Tag> :
                <Tag>{record.status}</Tag>
        },
        {
            title: "Actions",
            render: (text, record) => <ActionsButton text={'text'} record={record} actions={[
                {
                    title: 'Realtime',
                    action: (text, record: IDeviceModel) => this.props.history.push(`/traces/realtime/${record.deviceName}`)
                },
                {
                    title: 'Assign',
                    action: (text, record: IDeviceModel) => this.props.history.push(`/devices/assign/${record.deviceName}`)
                }
            ]}/>
        }
    ]

    render() {
        return (
            <React.Fragment>
                <Card title="Devices table">
                    <Table dataSource={this.devices} columns={this.deviceColumns} rowKey={'deviceName'}
                           loading={this.loading}>
                    </Table>
                </Card>
            </React.Fragment>
        );
    }
}

