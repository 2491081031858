import React from "react";
import {Select} from "antd";
import {action, observable, reaction} from "mobx";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";
import {errorUtils} from "../../services/utils/ErrorUtils";
import {IAssignableToDeviceDto, PersonsStore} from "../../stores/personsStore/personsStore";

interface IProps {
    personsStore?: PersonsStore;
    onChange?: (value?: number) => void;
    value?: number;
    disabled?: boolean;
    orgUnitId?: number;
    includeEmptyPersonId: boolean;
}

@inject(InjectNames.personsStore)
@observer
export class AssignableToDeviceSelect extends React.Component<IProps, any> {
    constructor(props: any) {
        super(props);
        this.loadList()
        this.disposeReactionForOrgUnitId = reaction(
            () => this.props.orgUnitId,
            this.reactionForOrgUnitId
        )
    }

    componentWillUnmount(): void {
        this.disposeReactionForOrgUnitId()
    }

    private readonly disposeReactionForOrgUnitId: () => any
    @observable search?: string
    @observable loading = false
    @observable list: IAssignableToDeviceDto[] = []

    reactionForOrgUnitId = () => {
        if (!!this.props.onChange)
            this.props.onChange(undefined)
        this.list = []
        this.loadList()
    }
    @action loadList = async () => {
        await errorUtils.execute({context: this, loadingFieldName: 'loading'}, async () => {
            this.list = (await this.props.personsStore!.getAssignableToDevice({
                offset: 0,
                rowCount: 1000,
                orgUnitId: this.props.orgUnitId,
                search: this.search
            }))
        })
    }

    render() {
        return <Select showSearch={true} allowClear={true} optionFilterProp={'children'}
                       style={{minWidth: 200, width: '100%'}}
                       disabled={this.props.disabled}
                       loading={this.loading}
                       placeholder={'Select person'}
                       value={this.loading ? undefined : this.props.value}
                       onSearch={value => {
                           this.search = value;
                           this.loadList()
                       }}
                       onChange={this.props.onChange}
        >
            {this.list.filter(x => this.props.includeEmptyPersonId || !!x.personId).map(item => <Select.Option key={item.personId}
                                                                                                                value={item.personId}>{item.userName}</Select.Option>)}
        </Select>
    }
}
