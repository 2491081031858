const rules = {
    email: [{required: true, message: 'This field is required!'}, {type: 'email', message: 'Email is not valid'}],
    userType: [{required: true, message: 'This field is required!'}],
    firstName: [{required: true, message: 'This field is required!'}],
    lastName: [{required: true, message: 'This field is required!'}],
    monitorUser: [{required: true, message: 'This field is required!'}],
    maxFamilyMembers: [],
    dateOfBirth: [],
    ethnicityId: [{required: true, message: 'This field is required!'}],
    roomId: [{required: true, message: 'This field is required!'}],
    orgUnitId: [{required: true, message: 'This field is required!'}],
    firstAddress: [],
    secondAddress: [],
    town: [],
    region: [],
    companyName: [],
    workPhone: [],
    postCode: [],
};
export default rules;
