import {IDisposable, RootStore} from "../initializeStores";
import {
    ProCvtClient,
} from "../../services/api/ProCvtClient";
import {action} from "mobx";
import {regexHelper} from "../../services/utils/RegexHelper";

export class OrgUnitsStore implements IDisposable {
    constructor(pbmSleepClient: ProCvtClient, rootStore: RootStore) {
        this.pbmSleepClient = pbmSleepClient!;
        this.rootStore = rootStore;
    }

    private readonly pbmSleepClient: ProCvtClient;
    private readonly rootStore: RootStore;

    @action
    getOrgUnitsList: (search: string) => Promise<IOrgUnitDto[]> = async (search: string) => {
        if (regexHelper.isEmptyString(search))
            search = ""
        return (await this.pbmSleepClient.orgUnits_GetOrgUnitsList(search))!
    }

    dispose = () => {
        return Promise.resolve(undefined);
    }
}

export interface IOrgUnitDto {
    id?: number | undefined;
    name?: string | undefined;
    description?: string | undefined;
}

