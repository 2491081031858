import React from "react";
import {Card, Col, Form, Input, Row, Select} from "antd";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {InvitationStore} from "../../../stores/invitationStore/invitationStore";
import {errorUtils, IHasValidationErrorsProperty} from "../../../services/utils/ErrorUtils";
import {FormComponentProps} from "antd/es/form";
import {AccountStatus, UserType} from "../../../services/api/ProCvtClient";
import {IUpdateUserInput, UserStore} from "../../../stores/user/userStore";
import {RouteComponentProps} from "react-router-dom";
import rules from "./user.rules";
import {action, computed, observable} from "mobx";
import {OrgUnitsStore} from "../../../stores/orgUnitsStore/orgUnitsStore";
import {OrgUnitSelect} from "../../Select/OrgUnitSelect";
import {enumHelper} from "../../../services/utils/EnumHelper";
import {SubmitCancelFormRow} from "../../Buttons/SubmitCancelFormRow";

interface IProps extends FormComponentProps, RouteComponentProps {
    invitationStore?: InvitationStore
    userStore?: UserStore
    orgUnitsStore?: OrgUnitsStore
    userId: number
}

@inject(InjectNames.invitationStore, InjectNames.userStore, InjectNames.orgUnitsStore)
@observer
class UpdateUser extends React.Component<IProps, any> implements IHasValidationErrorsProperty {
    validationErrors = {
        userType: '',
        email: '',
        firstName: '',
        lastName: '',
        orgUnitId: '',
        accountStatus: '',
        firstAddress: '',
        secondAddress: '',
        town: '',
        region: '',
        postCode: '',
        countryId: '',
    }
    @observable loading = false
    modifiedTime?: Date

    async componentDidMount() {
        await this.initForm()
    }

    @computed get buttonTitle() {
        return 'Update'
    }

    @action initForm = async () => {
        await errorUtils.execute({context: this, loadingFieldName: 'loading'}, async () => {
            const details = await this.props.userStore!.getUserDetails(this.props.userId)
            this.modifiedTime = details.modifiedTime
            this.props.form.setFieldsValue({...details})
        })
    }

    @computed get allowedUserTypes() {
        const userStore = this.props.userStore!
        if (userStore.isSuperUser)
            return Object.keys(UserType).filter(x => x !== UserType.Subject)
        if (userStore.isPrimary)
            return [UserType.Primary, UserType.Secondary]
        else
            return []
    }

    @computed get displayOrgUnit() {
        return this.props.userStore!.isSuperUser;
    }

    @action resetFormAndRouteToMain = () => {
        this.resetFields()
        this.props.history.push('/users')
    }

    @action handleSubmit = async (e: any) => {
        e.preventDefault();
        await this.props.form.validateFields(async (err: any, values: any) => {
            if (!err) {
                await errorUtils.execute({context: this, loadingFieldName: 'loading'}, async () => {
                    const input: IUpdateUserInput = {
                        ...values,
                        userId: this.props.userId!,
                        modifiedTime: this.modifiedTime,
                    }
                    await this.props.userStore!.updateUser(input)
                    this.resetFormAndRouteToMain()
                })
            }
        });
    }


    @action resetFields = () => {
        this.props.form.resetFields()
    }

    render() {
        const twoCol = (col1: any, col2: any) => {
            const span = 12;
            return <Row gutter={15}><Col span={span}>{col1}</Col><Col span={span}>{col2}</Col></Row>
        }
        const {getFieldDecorator} = this.props.form;
        return (
            <Card title={`Update User`}>
                <Form onSubmit={this.handleSubmit}>
                    {
                        this.displayOrgUnit && <React.Fragment>
                            {twoCol(
                                <Form.Item
                                    label={'Org unit'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.orgUnitId, 'orgUnitId', this.props.form)}>
                                    {getFieldDecorator('orgUnitId', {
                                        rules: rules.orgUnitId,
                                        initialValue: undefined
                                    })(
                                        <OrgUnitSelect
                                            onChange={() => errorUtils.resetValidationErrorState('orgUnitId', this)}/>)}
                                </Form.Item>,
                                null
                            )}
                        </React.Fragment>
                    }

                    <React.Fragment>
                        {twoCol(<Form.Item
                                label={'User type'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.userType, 'userType', this.props.form)}>
                                {getFieldDecorator('userType', {rules: rules.userType})(
                                    <Select onChange={event => errorUtils.resetValidationErrorState('userType', this)}
                                    >
                                        {this.allowedUserTypes.map(userType => (
                                            <Select.Option key={userType}
                                                           value={userType}>{enumHelper.getFriendlyName(userType)}</Select.Option>
                                        ))}
                                    </Select>)}
                            </Form.Item>,
                            <Form.Item
                                label={'Account'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.accountStatus, 'accountStatus', this.props.form)}>
                                {getFieldDecorator('accountStatus', {rules: rules.lastName})(
                                    <Select>
                                        <Select.Option value={AccountStatus.OPEN}>OPEN</Select.Option>
                                        <Select.Option value={AccountStatus.LOCKED}>LOCKED</Select.Option>
                                        <Select.Option value={AccountStatus.EXPIRED}>EXPIRED</Select.Option>
                                    </Select>)}
                            </Form.Item>)}
                    </React.Fragment>

                    {twoCol(<Form.Item
                            label={'Email'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.email, 'email', this.props.form)}>
                            {getFieldDecorator('email', {rules: rules.email})(
                                <Input placeholder={'Email'}
                                       onChange={event => errorUtils.resetValidationErrorState('email', this)}/>)}
                        </Form.Item>,
                        null)}

                    {twoCol(<Form.Item
                            label={'First name'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.firstName, 'firstName', this.props.form)}>
                            {getFieldDecorator('firstName', {rules: rules.firstName})(
                                <Input placeholder={'First name'}
                                       onChange={event => errorUtils.resetValidationErrorState('firstName', this)}/>)}
                        </Form.Item>,
                        <Form.Item
                            label={'Last name'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.lastName, 'lastName', this.props.form)}>
                            {getFieldDecorator('lastName', {rules: rules.lastName})(
                                <Input placeholder={'Last name'}
                                       onChange={event => errorUtils.resetValidationErrorState('lastName', this)}/>)}
                        </Form.Item>)}

                    {<React.Fragment>
                        {twoCol(<Form.Item
                                label={'First address'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.firstAddress, 'firstAddress', this.props.form)}>
                                {getFieldDecorator('firstAddress', {rules: rules.firstAddress})(
                                    <Input placeholder={'First address'}
                                           onChange={event => errorUtils.resetValidationErrorState('firstAddress', this)}/>)}
                            </Form.Item>,
                            <Form.Item
                                label={'Second Address'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.secondAddress, 'secondAddress', this.props.form)}>
                                {getFieldDecorator('secondAddress', {rules: rules.secondAddress})(
                                    <Input placeholder={'Second address'}
                                           onChange={event => errorUtils.resetValidationErrorState('secondAddress', this)}/>)}
                            </Form.Item>
                        )}

                        {twoCol(<Form.Item
                                label={'Town'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.town, 'town', this.props.form)}>
                                {getFieldDecorator('town', {rules: rules.town})(
                                    <Input placeholder={'Town'}
                                           onChange={event => errorUtils.resetValidationErrorState('town', this)}/>)}
                            </Form.Item>,
                            <Form.Item
                                label={'Region'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.region, 'region', this.props.form)}>
                                {getFieldDecorator('region', {rules: rules.region})(
                                    <Input placeholder={'Region'}
                                           onChange={event => errorUtils.resetValidationErrorState('region', this)}/>)}
                            </Form.Item>)}

                        {twoCol(<Form.Item
                                label={'Post code'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.postCode, 'postCode', this.props.form)}>
                                {getFieldDecorator('postCode', {rules: rules.region})(
                                    <Input placeholder={'Post code'}
                                           onChange={event => errorUtils.resetValidationErrorState('postCode', this)}/>)}
                            </Form.Item>,
                            null)}
                    </React.Fragment>}
                    <SubmitCancelFormRow loading={this.loading} okTitle={'Update'} onCancel={this.resetFormAndRouteToMain}/>
                </Form>
            </Card>
        );
    }
}


export default Form.create<IProps>()(UpdateUser);
