import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Col, Icon, Modal, Row, Tag} from "antd";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";
import {observable} from "mobx";
import {AnnotationStore, IAddAnnotationDto, IParentAnnotationDto} from "../../stores/annotationStore/annotationStore";
import {antdIconNames} from "../../constants/Icon";
import {InjectNames} from "../../stores/initializeStores";
import {UserStore} from "../../stores/user/userStore";
import ReplyForm from "./AnnotationReplyForm";
import {CollapseParentAnnotation} from "./CollapseParentAnnotation";

interface IProps {
    annotationStore?: AnnotationStore;
    userStore?: UserStore;
    traceId: number;
    sequence?: number;
    subjectName?: string;
    traceDate: Date;
    parentAnnotation: IParentAnnotationDto;
    onReply: (annotation: IAddAnnotationDto) => void;
    onDeleteAnnotation: (id: number) => void;
    validateInput: (input: string | undefined) => string | undefined;
}

@inject(InjectNames.annotationStore, InjectNames.userStore)
@observer
export class AnnotationParent extends React.Component<IProps> {
    @observable validationErrors = {
        text: ''
    };
    @observable loading = false;
    isCurrentUser = (name: string) => this.props.userStore!.userName === name

    confirmDelete = (deleteAction: () => any) => Modal.confirm({
        title: "Delete?",
        okText: 'Yes',
        cancelText: 'No',
        okType: "danger",
        onOk: () => deleteAction()
    })

    handleDeleteParent = async (event: any) => {
        event.stopPropagation()
        this.confirmDelete(() => this.props!.onDeleteAnnotation(this.props.parentAnnotation.annotationId))
    }
    handleDeleteChild = async (annotationId: number) => {
        this.confirmDelete(() => this.props.onDeleteAnnotation(annotationId))
    }

    render() {
        const getDateFormat = (date: Date | undefined) => {
            if (!date)
                return undefined
            else {
                return dateTimeHelper.day_month_year_hour_min(date)
            }
        }
        const getDateSequenceFormat = (sequence: number | undefined) => {
            if (!sequence)
                return undefined
            else {
                return dateTimeHelper.msToDurationCharactersFormat(sequence)
            }
        }
        const actionSpan = 2
        const contentSpan = 24 - actionSpan
        const annotationLayout = (name: any, date: any, duration: any, text: any, action: any, isParent = false) => {
            return <div style={{marginTop: isParent ? 0 : 40}}>
                <Row>
                    <Col span={contentSpan}>
                        <Row type={'flex'} justify={'space-between'}>
                            <Col style={{marginRight: 10}}>
                                <h4>{name}</h4>
                                {!!duration && <Tag>Sequence: {duration}</Tag>}
                            </Col>
                            <Col>
                                <h5>{date}</h5>
                            </Col>
                        </Row>
                    </Col>
                    <Col style={{textAlign: 'right'}} span={actionSpan}>{action}</Col>
                </Row>
                <Row>
                    <Col span={contentSpan}>
                        <h4>{text}</h4>
                    </Col>
                </Row>
            </div>
        };
        const replyForm = <ReplyForm addInputWithoutText={{
            traceId: this.props.traceId,
            sequence: this.props.parentAnnotation.sequence,
            parentAnnotationId: this.props.parentAnnotation.annotationId
        }} traceInfo={{subjectName: this.props.subjectName, traceDate: this.props.traceDate}}/>
        const parent = annotationLayout(this.props.parentAnnotation.nameOfCreator,
            getDateFormat(this.props.parentAnnotation.createdDate),
            getDateSequenceFormat(this.props.parentAnnotation.sequence),
            this.props.parentAnnotation.text,
            this.isCurrentUser(this.props.parentAnnotation.nameOfCreator!) &&
            <Button type={'default'} icon={antdIconNames.Delete} size={'default'}
                    onClick={this.handleDeleteParent}/>, true
        )
        const children = <div>
            {
                this.props.parentAnnotation.comments?.map(item => (
                    <div key={item.annotationId} style={{marginBottom: 15}}>
                        {annotationLayout(item.nameOfCreator,
                            getDateFormat(item.createdDate),
                            null,
                            item.text,
                            this.isCurrentUser(item.nameOfCreator!) &&
                            <Icon type={antdIconNames.Delete} style={{fontSize: 18}}
                                  onClick={() => this.handleDeleteChild(item.annotationId)}/>
                        )}
                    </div>
                ))
            }
            <Row>
                <Col span={contentSpan}>{replyForm}</Col>
            </Row>
        </div>

        const annotations =
            <CollapseParentAnnotation sequence={this.props.sequence}
                                      annotationId={this.props.parentAnnotation.annotationId}
                                      collapseTitle={parent} collapseContent={children}/>

        return (
            <div style={{marginBottom: 30}}>
                {annotations}
            </div>
        );
    }
}
