import {IDisposable, RootStore} from "../initializeStores";
import {action, computed, observable} from "mobx";
import {CompareTimeType} from "../../components/Charts/IChartSeriesInfo";
import EcgViewer from "../../components/Traces/EcgViewer";

export enum ValidRrCountThreshold {
    "Perfect" = 15,
    "Excellent" = 13,
    "Good" = 11,
    "Average" = 8,
    "All" = 0
}

export class CompareTracesStore implements IDisposable {
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    private readonly rootStore: RootStore;

    @action
    setDefaultValues = () => {
        this.showSymbols = this.defaultValues.showSymbols
        this.isHeartbeatMode = this.defaultValues.isHeartbeatMode
        this._ignoreDayToggle = this.defaultValues.ignoreDayToggle
        // @ts-ignore
        this.compareTimeType = this.defaultValues.compareTimeType
        this.heartRateToggle = this.defaultValues.heartRateToggle;
        this.cvtToggle = this.defaultValues.cvtToggle;
        this.respirationRateToggle = this.defaultValues.respirationRateToggle;
        this.temperatureToggle = this.defaultValues.temperatureToggle
        this._viewEcgEnabled = this.defaultValues.viewEcgEnabled
    }
    defaultValues = {
        showSymbols: false,
        isHeartbeatMode: false,
        ignoreDayToggle: true,
        compareTimeType: 'Actual',
        heartRateToggle: false,
        cvtToggle: true,
        respirationRateToggle: false,
        temperatureToggle: false,
        viewEcgEnabled: true,
        validRrCountValue: ValidRrCountThreshold.Excellent,
    }

    @observable showSymbols = this.defaultValues.showSymbols
    @observable isHeartbeatMode = this.defaultValues.isHeartbeatMode
    @observable _ignoreDayToggle = this.defaultValues.ignoreDayToggle
    // @ts-ignore
    @observable compareTimeType: CompareTimeType = this.defaultValues.compareTimeType
    @observable heartRateToggle = this.defaultValues.heartRateToggle
    @observable cvtToggle = this.defaultValues.cvtToggle
    @observable respirationRateToggle = this.defaultValues.respirationRateToggle
    @observable temperatureToggle = this.defaultValues.temperatureToggle
    @observable _viewEcgEnabled = this.defaultValues.viewEcgEnabled
    @observable validRrCountValue = this.defaultValues.validRrCountValue

    @computed get viewEcgEnabled() {
        return this._viewEcgEnabled
    }

    set viewEcgEnabled(value) {
        this._viewEcgEnabled = value
        if (this.viewEcgEnabled)
            this.viewEcg()
    }

    ecgViewerRef?: EcgViewer;
    viewEcg = () => {
        // wait ecg viewer render
        setTimeout(() => {
            if (this.viewEcgEnabled)
                this.ecgViewerRef?.viewEcg()
        }, 10)
    }

    @computed get heartRateToggleAllowed() {
        return !!this.rootStore.tracesStore!.fullTraceSeriesDtoList?.length
    }

    @computed get heartRateMovingAvgAllowed() {
        return this.heartRateToggleAllowed && this.heartRateToggle
    }

    @computed get cvtToggleAllowed() {
        return !!this.rootStore.tracesStore!.fullTraceSeriesDtoList?.length
    }

    @computed get cvtMovingAvgAllowed() {
        return this.cvtToggleAllowed && this.cvtToggle
    }

    @computed get temperatureToggleAllowed() {
        return !!this.rootStore.tracesStore!.fullTraceSeriesDtoList?.length
    }

    @computed get temperatureMovingAvgAllowed() {
        return this.temperatureToggleAllowed && this.temperatureToggle
    }

    @computed get respirationRateToggleAllowed() {
        return !!this.rootStore.tracesStore!.fullTraceSeriesDtoList?.length
    }

    @computed get respirationRateMovingAvgAllowed() {
        return this.respirationRateToggleAllowed && this.respirationRateToggle
    }

    @computed get ignoreDayToggle() {
        return this.ignoreDayToggleAllowed ? this._ignoreDayToggle : false;
    }

    set ignoreDayToggle(value: boolean) {
        this._ignoreDayToggle = value;
    }

    @computed get ignoreDayToggleAllowed() {
        return this.compareTimeType === "Actual"
    }

    @computed get heartbeatModeAllowed() {
        return this.rootStore.tracesStore.selectedTraces?.length <= 1
    }

    @computed get addNewSeriesAllowed() {
        return !(this.isHeartbeatMode && this.rootStore.tracesStore!.selectedTraces?.length > 0)
    }

    @action
    handleToggleHeartRate = async (checked: boolean) => {
        this.heartRateToggle = checked;
    };
    @action
    handleToggleCvt = async (checked: boolean) => {
        this.cvtToggle = checked;
    };
    @action
    handleToggleTemperature = async (checked: boolean) => {
        this.temperatureToggle = checked;
    };
    @action
    handleToggleRespirationRate = async (checked: boolean) => {
        this.respirationRateToggle = checked;
    };

    @computed get xAxisType() {
        switch (this.compareTimeType) {
            case "Actual":
                return "time";
            case "Elapsed":
                return "value";
            default:
                throw new Error('Unknown compareTimeType')
        }
    };

    dispose = async () => {
        this.setDefaultValues()
    };
}
