import * as React from 'react';
import {observer} from "mobx-react";
import {RouteComponentProps} from "react-router";
import AddOrUpdateResident from "../../../components/Forms/Residents/AddOrUpdateResident";

interface IRouteProps {
    personId?: string
}

interface IProps extends RouteComponentProps<IRouteProps> {
}

@observer
class AddOrUpdateResidentScene extends React.Component<IProps> {

    render() {
        return (
            <React.Fragment>
                <AddOrUpdateResident
                    personId={!!this.props.match.params.personId ? Number.parseInt(this.props.match.params.personId) : undefined} {...this.props}/>
            </React.Fragment>
        );
    }
}

export default AddOrUpdateResidentScene;
