import React from "react";
import {InvitationStore} from "../../../stores/invitationStore/invitationStore";
import {UserStore} from "../../../stores/user/userStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {RouteComponentProps} from "react-router-dom";
import {Card} from "antd";
import InviteUser from "../../../components/Forms/Users/InviteStaff";

interface IProps extends RouteComponentProps{
    invitationStore?: InvitationStore
    userStore?: UserStore
}

@inject(InjectNames.invitationStore, InjectNames.userStore)
@observer
export class InviteStaffScene extends React.Component<IProps, any> {
    render() {
        return  <Card title="Invite staff">
            <InviteUser {...this.props}/>
        </Card>
    }
}
