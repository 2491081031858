import React from "react";
import {Card, Col, Form, Input, Row} from "antd";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {InvitationStore} from "../../../stores/invitationStore/invitationStore";
import {errorUtils, IHasValidationErrorsProperty} from "../../../services/utils/ErrorUtils";
import {FormComponentProps} from "antd/es/form";
import {IUpdateStaffInput, UserStore} from "../../../stores/user/userStore";
import {RouteComponentProps} from "react-router-dom";
import rules from "./user.rules";
import {action, computed, observable} from "mobx";
import {OrgUnitsStore} from "../../../stores/orgUnitsStore/orgUnitsStore";
import {SubmitCancelFormRow} from "../../Buttons/SubmitCancelFormRow";
import {AccountStatusSelect} from "../../Select/AccountStatusSelect";

interface IProps extends FormComponentProps, RouteComponentProps {
    invitationStore?: InvitationStore
    userStore?: UserStore
    orgUnitsStore?: OrgUnitsStore
    personId: number
}

@inject(InjectNames.invitationStore, InjectNames.userStore, InjectNames.orgUnitsStore)
@observer
class UpdateStaff extends React.Component<IProps, any> implements IHasValidationErrorsProperty {
    validationErrors = {
        firstName: '',
        lastName: '',
        maxFamilyMembers: '',
        accountStatus: '',
    }
    @observable loading = false
    modifiedTime?: Date

    async componentDidMount() {
        await this.initForm()
    }

    @computed get actionTitle() {
        return 'Update'
    }

    @action initForm = async () => {
        const details = await this.props.userStore!.getStaffDetails(this.props.personId)
        this.modifiedTime = details.modifiedTime
        this.props.form.setFieldsValue({...details})
    }


    @action resetFormAndRouteToMain = () => {
        this.resetFields()
        this.props.history.push('/staff')
    }

    @action handleSubmit = async (e: any) => {
        e.preventDefault();
        await this.props.form.validateFields(async (err: any, values: any) => {
            if (!err) {
                await errorUtils.execute({context: this, loadingFieldName: 'loading'}, async () => {
                    const input: IUpdateStaffInput = {
                        ...values,
                        personId: this.props.personId!,
                        modifiedTime: this.modifiedTime,
                    }
                    await this.props.userStore!.updateStaff(input)
                    this.resetFormAndRouteToMain()
                })
            }
        });
    }

    @action resetFields = () => {
        this.props.form.resetFields()
    }

    render() {
        const twoCol = (col1: any, col2: any) => {
            const span = 12;
            return <Row gutter={15}><Col span={span}>{col1}</Col><Col span={span}>{col2}</Col></Row>
        }
        const {getFieldDecorator} = this.props.form;
        return (
            <Card title={`Update Staff`}>
                <Form onSubmit={this.handleSubmit}>
                    <React.Fragment>
                        {twoCol(<Form.Item
                                label={'First name'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.firstName, 'firstName', this.props.form)}>
                                {getFieldDecorator('firstName', {rules: rules.firstName})(
                                    <Input placeholder={'First name'}
                                           onChange={event => errorUtils.resetValidationErrorState('firstName', this)}/>)}
                            </Form.Item>,
                            <Form.Item
                                label={'Last name'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.lastName, 'lastName', this.props.form)}>
                                {getFieldDecorator('lastName', {rules: rules.lastName})(
                                    <Input placeholder={'Last name'}
                                           onChange={event => errorUtils.resetValidationErrorState('lastName', this)}/>)}
                            </Form.Item>)}

                        {twoCol(<Form.Item
                                label={'Max family members'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.maxFamilyMembers, 'maxFamilyMembers', this.props.form)}>
                                {getFieldDecorator('maxFamilyMembers', {rules: rules.maxFamilyMembers})(
                                    <Input placeholder={'Max family members'} type={'number'} min={1}
                                           onChange={event => errorUtils.resetValidationErrorState('maxFamilyMembers', this)}/>)}
                            </Form.Item>,
                            <Form.Item
                                label={'Account'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.accountStatus, 'accountStatus', this.props.form)}>
                                {getFieldDecorator('accountStatus', {rules: rules.lastName})(
                                    <AccountStatusSelect/>)}
                            </Form.Item>
                        )}
                    </React.Fragment>
                    <SubmitCancelFormRow loading={this.loading} okTitle={this.actionTitle}
                                         onCancel={this.resetFormAndRouteToMain}/>
                </Form>
            </Card>
        );
    }
}


export default Form.create<IProps>()(UpdateStaff);
