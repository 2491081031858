import React from "react";
import {RouteComponentProps} from "react-router";
import {Button, Card, Row} from "antd";
import {UserStore} from "../../../stores/user/userStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {antdIconNames} from "../../../constants/Icon";
import {StaffTable} from "../../../components/Tables/StaffTable";

interface IProps extends RouteComponentProps {
    userStore?: UserStore
}

@inject(InjectNames.userStore)
@observer
export class StaffsTableScene extends React.Component<IProps> {
    routeToInvite = () => {
        this.props.history.push({pathname: '/staff/invite'})
    };


    render() {
        return (
            <React.Fragment>
                <Row style={{paddingBottom: '30px'}}>
                    <Button onClick={this.routeToInvite} icon={antdIconNames.Plus} type={'default'}>Invite staff</Button>
                </Row>
                <Card title={'Staff'}>
                    <StaffTable {...this.props}/>
                </Card>
            </React.Fragment>
        );
    }
}
