import React from "react";
import {Col, Form, Input, Row, Select} from "antd";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {IInviteInput, InvitationStore} from "../../../stores/invitationStore/invitationStore";
import {errorUtils, IHasValidationErrorsProperty} from "../../../services/utils/ErrorUtils";
import {FormComponentProps} from "antd/es/form";
import {UserType} from "../../../services/api/ProCvtClient";
import {UserStore} from "../../../stores/user/userStore";
import {RouteComponentProps} from "react-router-dom";
import rules from "./user.rules";
import {action, computed, observable} from "mobx";
import {IOrgUnitDto, OrgUnitsStore} from "../../../stores/orgUnitsStore/orgUnitsStore";
import {OrgUnitSelect} from "../../Select/OrgUnitSelect";
import {enumHelper} from "../../../services/utils/EnumHelper";
import {SubmitCancelFormRow} from "../../Buttons/SubmitCancelFormRow";

interface IProps extends FormComponentProps, RouteComponentProps {
    invitationStore?: InvitationStore
    userStore?: UserStore
    orgUnitsStore?: OrgUnitsStore
}

@inject(InjectNames.invitationStore, InjectNames.userStore, InjectNames.orgUnitsStore)
@observer
class InviteStaff extends React.Component<IProps, any> implements IHasValidationErrorsProperty {
    validationErrors = {
        userType: '',
        email: '',
        monitorUser: '',
        firstName: '',
        lastName: '',
        orgUnitId: '',
        householdId: '',
        maxFamilyMembers: ''
    }
    @observable orgUnitsList: IOrgUnitDto[] = []
    @observable loading = false

    async componentDidMount() {
        await this.initForm()
    }

    @action initForm = async () => {
    }

    @computed get allowedUserTypes() {
        let userStore = this.props.userStore!
        if (userStore.isSuperUser)
            return Object.keys(UserType).filter(x => x !== UserType.Subject)
        if (userStore.isPrimary)
            return [UserType.Primary, UserType.Secondary]
        else
            return []
    }
    @action resetFormAndRouteToMain = () => {
        this.resetFields()
        this.props.history.push('/staff')
    }

    handleSubmit = async (e: any) => {
        e.preventDefault();
        await this.props.form.validateFields(async (err: any, values: any) => {
            if (!err) {
                await errorUtils.execute({context: this, loadingFieldName: 'loading'}, async () => {
                    let input: IInviteInput = {
                        email: values.email,
                        firstName: values.firstName,
                        householdId: this.props.userStore!.userDetails?.householdId,
                        lastName: values.lastName,
                        monitorUser: values.monitorUser === 'true',
                        orgUnitId: this.displayOrgUnit ? values.orgUnitId : this.props.userStore!.userDetails?.orgUnitId,
                        userType: values.userType,
                        maxFamilyMembers: values.maxFamilyMembers
                    }
                    await this.props.invitationStore!.invite(input)
                    this.resetFields()
                    this.props.history.push('/staff')
                })
            }
        });
    }

    @computed get displayOrgUnit() {
        return this.props.userStore!.isSuperUser;
    }


    resetFields = () => {
        this.props.form.resetFields()
    }

    render() {
        const twoCol = (col1: any, col2: any) => {
            const span = 12;
            return <Row gutter={15}><Col span={span}>{col1}</Col><Col span={span}>{col2}</Col></Row>
        }
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit}>
                {
                    this.displayOrgUnit && <React.Fragment>
                        {twoCol(
                            <Form.Item
                                label={'Org unit'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.orgUnitId, 'orgUnitId', this.props.form)}>
                                {getFieldDecorator('orgUnitId', {
                                    rules: rules.orgUnitId,
                                    initialValue: undefined
                                })(
                                    <OrgUnitSelect
                                        onChange={() => errorUtils.resetValidationErrorState('orgUnitId', this)}/>)}
                            </Form.Item>,
                            null
                        )}
                    </React.Fragment>
                }

                {twoCol(
                    <Form.Item
                        label={'Monitor user'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.monitorUser, 'monitorUser', this.props.form)}>
                        {getFieldDecorator('monitorUser', {rules: rules.monitorUser})(
                            <Select showSearch={false}
                                    onChange={event => errorUtils.resetValidationErrorState('monitorUser', this)}
                            >
                                <Select.Option value={'true'}>Yes</Select.Option>
                                <Select.Option value={'false'}>No</Select.Option>
                            </Select>)}
                    </Form.Item>,
                    <Form.Item
                        label={'Max family members'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.maxFamilyMembers, 'maxFamilyMembers', this.props.form)}>
                        {getFieldDecorator('maxFamilyMembers', {rules: rules.maxFamilyMembers})(
                            <Input placeholder={'Max family members'} type={'number'} min={1}
                                   onChange={event => errorUtils.resetValidationErrorState('maxFamilyMembers', this)}/>)}
                    </Form.Item>
                )}

                {twoCol(<Form.Item
                        label={'User type'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.userType, 'userType', this.props.form)}>
                        {getFieldDecorator('userType', {rules: rules.userType})(
                            <Select onChange={event => errorUtils.resetValidationErrorState('userType', this)}
                            >
                                {this.allowedUserTypes.map(userType => (
                                    <Select.Option key={userType}
                                                   value={userType}>{enumHelper.getFriendlyName(userType)}</Select.Option>
                                ))}
                            </Select>)}
                    </Form.Item>,
                    null)}


                {twoCol(<Form.Item
                        label={'Email'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.email, 'email', this.props.form)}>
                        {getFieldDecorator('email', {rules: rules.email})(
                            <Input placeholder={'Email'}
                                   onChange={event => errorUtils.resetValidationErrorState('email', this)}/>)}
                    </Form.Item>,
                    null)}

                {twoCol(<Form.Item
                        label={'First name'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.firstName, 'firstName', this.props.form)}>
                        {getFieldDecorator('firstName', {rules: rules.firstName})(
                            <Input placeholder={'First name'}
                                   onChange={event => errorUtils.resetValidationErrorState('firstName', this)}/>)}
                    </Form.Item>,
                    <Form.Item
                        label={'Last name'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.lastName, 'lastName', this.props.form)}>
                        {getFieldDecorator('lastName', {rules: rules.lastName})(
                            <Input placeholder={'Last name'}
                                   onChange={event => errorUtils.resetValidationErrorState('lastName', this)}/>)}
                    </Form.Item>)}

                <SubmitCancelFormRow loading={this.loading} okTitle={'Invite'} onCancel={this.resetFormAndRouteToMain}/>
            </Form>
        );
    }
}


export default Form.create<IProps>()(InviteStaff);
