import {action, observable} from "mobx";
import {
    CreateAnnotationModel,
    GetOverTimeAnnotationsRequest,
    ProCvtClient,
    TraceType
} from "../../services/api/ProCvtClient";
import {IDisposable, RootStore} from "../initializeStores";

export class AnnotationStore implements IDisposable {
    constructor(pbmSleepClient: ProCvtClient, rootStore: RootStore) {
        this.pbmSleepClient = pbmSleepClient!;
        this.rootStore = rootStore;
    }

    private readonly pbmSleepClient: ProCvtClient;
    private readonly rootStore: RootStore;

    @observable tracesList: ITraceAnnotationsDto[] = [];
    @observable tracesOverTimeList: ITraceAnnotationsDto[] = [];
    @observable selectedTraces: number[] = [];
    @observable selectedAnnotations: number[] = [];
    latestLoadInput: any;
    latestOverTimeInput: any;

    @action
    selectAnnotation = (params: { trace: number, annotation?: number | undefined }) => {
        this.selectedTraces = [params.trace]
        if (!!params.annotation)
            this.selectedAnnotations = [params.annotation]
    }

    @action
    add: (input: IAddAnnotationDto, traceInfo: IAddAnnotationTraceInfo) => Promise<number> = async (input: IAddAnnotationDto, traceInfo: IAddAnnotationTraceInfo) => {
        let addedId!: number
        if (input.parentAnnotationId === undefined) {
            const addedAnnInfo = (await this.pbmSleepClient.annotations_CreateParent(new CreateAnnotationModel(input)))!
            addedId = addedAnnInfo.annotationId
            const addedParent: IParentAnnotationDto = {
                comments: [],
                annotationId: addedAnnInfo.annotationId,
                nameOfCreator: this.rootStore.userStore.userName,
                sequence: input.sequence,
                createdDate: new Date(),
                text: input.text,
                traceId: input.traceId
            }
            this.insertParentAnnotation(addedParent, this.tracesList, traceInfo)
            this.insertParentAnnotation(addedParent, this.tracesOverTimeList, traceInfo)
        } else {
            const addedId = (await this.pbmSleepClient.annotations_CreateChild(new CreateAnnotationModel(input)))!
            const addedChild: IChildAnnotationDto = {
                text: input.text,
                sequence: input.sequence,
                parentAnnotationId: input.parentAnnotationId!,
                nameOfCreator: this.rootStore.userStore.userName,
                createdDate: new Date(),
                annotationId: addedId
            }
            this.insertChildAnnotation(addedChild, this.tracesList)
            this.insertChildAnnotation(addedChild, this.tracesOverTimeList)
        }
        return addedId;
    };
    private insertChildAnnotation = (child: IChildAnnotationDto, tracesList: ITraceAnnotationsDto[]) => {
        let traceIndex: number | undefined;
        let parentIndex: number | undefined;
        tracesList.forEach((traceAnnotationsItem, traceIndexItem) => {
            const parentIndexItem = traceAnnotationsItem.annotations?.findIndex(parent => parent.annotationId === child.parentAnnotationId)
            if (parentIndexItem !== -1 && parentIndexItem !== undefined) {
                parentIndex = parentIndexItem
                traceIndex = traceIndexItem
                return;
            }
        });

        if ((traceIndex !== -1 && traceIndex !== undefined) && (parentIndex !== -1 && parentIndex !== undefined)) {
            if (!!tracesList[traceIndex].annotations![parentIndex!].comments?.length)
                tracesList[traceIndex].annotations![parentIndex!].comments!.push(child)
            else
                tracesList[traceIndex].annotations![parentIndex!].comments = [child]
        }
    }
    private insertParentAnnotation = (parent: IParentAnnotationDto, tracesList: ITraceAnnotationsDto[], traceInfo: IAddAnnotationTraceInfo) => {
        const traceIndex: number = tracesList.findIndex(x => x.traceId === parent.traceId)
        if (traceIndex === -1) {
            tracesList.push({traceId: parent.traceId, annotations: [parent], ...traceInfo})
        } else {
            if (!!tracesList[traceIndex].annotations?.length) {
                tracesList[traceIndex].annotations!.push(parent)
            } else {
                tracesList[traceIndex].annotations = [parent]
            }
        }
    }

    @action
    delete: (annotationId: number) => Promise<void> = async (annotationId: number) => {
        await this.pbmSleepClient.annotations_Delete(annotationId)
        this.tracesOverTimeList = this.filterAnnotation(annotationId, this.tracesOverTimeList)
        this.tracesList = this.filterAnnotation(annotationId, this.tracesList)
    };
    @action
    private filterAnnotation = (annotationId: number, traceAnnotations: ITraceAnnotationsDto[]) => {
        return traceAnnotations.map(traceAnnotations => ({
            ...traceAnnotations,
            annotations: traceAnnotations.annotations?.filter(parent => parent.annotationId !== annotationId)
                .map(parent => ({
                    ...parent,
                    comments: parent.comments?.filter(child => child.annotationId !== annotationId)
                }))
        }))
    }

    @action
    load = async (traceIdList: number[]) => {
        const tracesList: ITraceAnnotationsDto[] = (await this.pbmSleepClient.annotations_GetForTraces(traceIdList))!
        this.latestLoadInput = traceIdList
        this.tracesList = tracesList.map(traceAnnotations => ({
            ...traceAnnotations,
            annotations: !!traceAnnotations.annotations ? traceAnnotations.annotations.map(parent => ({
                ...parent,
                comments: !!parent.comments ? parent.comments : []
            })) : []
        }));
    }

    @action
    reLoadLatest = async () => {
        if (!!this.latestLoadInput)
            await this.load(this.latestLoadInput)
    }

    @action
    loadOverTime = async (subjectId: number, traceType: TraceType) => {
        const tracesList = (await this.pbmSleepClient.annotations_GetOverTime(
            new GetOverTimeAnnotationsRequest({subjectId: subjectId, traceType: traceType})))!
        this.latestOverTimeInput = {subjectId: subjectId, traceType: traceType}
        this.tracesOverTimeList = tracesList.map(traceAnnotations => ({
            ...traceAnnotations,
            annotations: !!traceAnnotations.annotations ? traceAnnotations.annotations.map(parent => ({
                ...parent,
                comments: !!parent.comments ? parent.comments : []
            })) : []
        }));
    };
    @action
    reLoadLatestOverTime = async () => {
        if (!!this.latestOverTimeInput)
            await this.loadOverTime(this.latestOverTimeInput.subjectId, this.latestOverTimeInput.traceType)
    };
    reloadAll = async () => {
        await Promise.all([this.reLoadLatest(),
            this.reLoadLatestOverTime()])
    }

    @action
    dispose = async () => {
        this.latestLoadInput = null
        this.latestOverTimeInput = null
        this.tracesList = []
        this.tracesOverTimeList = []
    };
}

export interface ITraceAnnotationDto {
    annotationId: number;
    sequence?: number | undefined;
    parentAnnotationId?: number | undefined;
    text?: string | undefined;
    createdDate: Date;
    nameOfCreator?: string | undefined;
}

export interface IAddAnnotationDto {
    traceId: number;
    parentAnnotationId?: number | undefined;
    sequence?: number | undefined;
    text?: string | undefined;
}

export interface IAddAnnotationTraceInfo {
    traceDate: Date,
    subjectName?: string | undefined;
}

export interface ITraceAnnotationsDto {
    traceId: number;
    traceDate: Date;
    subjectName?: string | undefined;
    annotations?: IParentAnnotationDto[] | undefined;
}

export interface IParentAnnotationDto {
    traceId: number;
    sequence?: number | undefined;
    annotationId: number;
    text?: string | undefined;
    createdDate: Date;
    nameOfCreator?: string | undefined;
    comments?: IChildAnnotationDto[] | undefined;
}

export interface IChildAnnotationDto {
    sequence?: number | undefined;
    annotationId: number;
    parentAnnotationId: number;
    text?: string | undefined;
    createdDate: Date;
    nameOfCreator?: string | undefined;
}
