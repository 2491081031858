export const antdIconNames = {
    TraceIcon: "line-chart",
    DeviceIcon: "laptop",
    Close: "close",
    User: "team",
    Subject: "team",
    Dashboard: "home",
    Plus: "plus",
    PlusCircle: "plus-circle",
    MinusCircle: "minus-circle",
    Loading: "loading",
    List: "unordered-list",
    UploadCloud: "cloud-upload",
    Upload: "upload",
    Delete: "delete",
    Room: "home"
};

export const dataUriIcons = {
    Annotation: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzNiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNMSAxMUMxIDUuNDggMi4wMjkxOSAxIDMuMjk3MyAxSDMyLjcwMjdDMzMuOTcwOCAxIDM1IDUuNDggMzUgMTFWMjFDMzUgMjYuNTIgMzMuOTcwOCAzMSAzMi43MDI3IDMxSDMuMjk3M0MyLjAyOTE5IDMxIDEgMjYuNTIgMSAyMVYxMVoiIGZpbGw9IndoaXRlIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4NCiAgICA8bWFzayBpZD0iYW5ub3RhdGlvbiIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iOCIgeT0iNiIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj4NCiAgICAgICAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNiA2QzI3LjEgNiAyNy45OSA2LjkgMjcuOTkgOEwyOCAyNkwyNCAyMkgxMEM4LjkgMjIgOCAyMS4xIDggMjBWOEM4IDYuOSA4LjkgNiAxMCA2SDI2Wk0xMyAxOEgyM0MyMy41NSAxOCAyNCAxNy41NSAyNCAxN0MyNCAxNi40NSAyMy41NSAxNiAyMyAxNkgxM0MxMi40NSAxNiAxMiAxNi40NSAxMiAxN0MxMiAxNy41NSAxMi40NSAxOCAxMyAxOFpNMjMgMTVIMTNDMTIuNDUgMTUgMTIgMTQuNTUgMTIgMTRDMTIgMTMuNDUgMTIuNDUgMTMgMTMgMTNIMjNDMjMuNTUgMTMgMjQgMTMuNDUgMjQgMTRDMjQgMTQuNTUgMjMuNTUgMTUgMjMgMTVaTTEzIDEySDIzQzIzLjU1IDEyIDI0IDExLjU1IDI0IDExQzI0IDEwLjQ1IDIzLjU1IDEwIDIzIDEwSDEzQzEyLjQ1IDEwIDEyIDEwLjQ1IDEyIDExQzEyIDExLjU1IDEyLjQ1IDEyIDEzIDEyWiIgZmlsbD0id2hpdGUiLz4NCiAgICA8L21hc2s+DQogICAgPGcgbWFzaz0idXJsKCNhbm5vdGF0aW9uKSI+DQogICAgICAgIDxyZWN0IHg9IjYiIHk9IjQiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iYmxhY2siIGZpbGwtb3BhY2l0eT0iMC41NCIvPg0KICAgIDwvZz4NCjwvc3ZnPg==',
    Trace: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzNiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNMSAxMUMxIDUuNDggMi4wMjkxOSAxIDMuMjk3MyAxSDMyLjcwMjdDMzMuOTcwOCAxIDM1IDUuNDggMzUgMTFWMjFDMzUgMjYuNTIgMzMuOTcwOCAzMSAzMi43MDI3IDMxSDMuMjk3M0MyLjAyOTE5IDMxIDEgMjYuNTIgMSAyMVYxMVoiIGZpbGw9IndoaXRlIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4NCiAgICA8bWFzayBpZD0idHJhY2UiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjgiIHk9IjkiIHdpZHRoPSIyMCIgaGVpZ2h0PSIxNCI+DQogICAgICAgIDxwYXRoIGQ9Ik0xMC4xODYxIDIxLjc2NDRMMTUuNDg2MSAxNi40NjQ0TDE4LjczNjEgMTkuNzE0NEMxOS4xNDYxIDIwLjEyNDQgMTkuODA2MSAyMC4xMDQ0IDIwLjE4NjEgMTkuNjc0NEwyNy4zNTYxIDExLjYwNDRDMjcuNzA2MSAxMS4yMTQ0IDI3LjY4NjEgMTAuNjE0NCAyNy4zMTYxIDEwLjIzNDRDMjYuOTE2MSA5LjgzNDQgMjYuMjQ2MSA5Ljg0NDQgMjUuODY2MSAxMC4yNzQ0TDE5LjQ3NjEgMTcuNDU0NEwxNi4xODYxIDE0LjE2NDRDMTUuOTk5MiAxMy45NzcyIDE1Ljc0NTYgMTMuODcxOSAxNS40ODExIDEzLjg3MTlDMTUuMjE2NSAxMy44NzE5IDE0Ljk2MjkgMTMuOTc3MiAxNC43NzYxIDE0LjE2NDRMOC42ODYwNSAyMC4yNjQ0QzguMjk2MDUgMjAuNjU0NCA4LjI5NjA1IDIxLjI4NDQgOC42ODYwNSAyMS42NzQ0TDguNzc2MDUgMjEuNzY0NEM5LjE2NjA1IDIyLjE1NDQgOS44MDYwNSAyMi4xNTQ0IDEwLjE4NjEgMjEuNzY0NFoiIGZpbGw9IndoaXRlIi8+DQogICAgPC9tYXNrPg0KICAgIDxnIG1hc2s9InVybCgjdHJhY2UpIj4NCiAgICAgICAgPHJlY3QgeD0iNiIgeT0iNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjU0Ii8+DQogICAgPC9nPg0KPC9zdmc+',
    Plus: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzNiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNMSAxMUMxIDUuNDggMi4wMjkxOSAxIDMuMjk3MyAxSDMyLjcwMjdDMzMuOTcwOCAxIDM1IDUuNDggMzUgMTFWMjFDMzUgMjYuNTIgMzMuOTcwOCAzMSAzMi43MDI3IDMxSDMuMjk3M0MyLjAyOTE5IDMxIDEgMjYuNTIgMSAyMVYxMVoiIGZpbGw9IndoaXRlIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4NCiAgICA8bWFzayBpZD0icGx1cyIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iOCIgeT0iNiIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj4NCiAgICAgICAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOCA2QzEyLjQ5IDYgOCAxMC40OSA4IDE2QzggMjEuNTEgMTIuNDkgMjYgMTggMjZDMjMuNTEgMjYgMjggMjEuNTEgMjggMTZDMjggMTAuNDkgMjMuNTEgNiAxOCA2Wk0xOCAxMUMxNy40NSAxMSAxNyAxMS40NSAxNyAxMlYxNUgxNEMxMy40NSAxNSAxMyAxNS40NSAxMyAxNkMxMyAxNi41NSAxMy40NSAxNyAxNCAxN0gxN1YyMEMxNyAyMC41NSAxNy40NSAyMSAxOCAyMUMxOC41NSAyMSAxOSAyMC41NSAxOSAyMFYxN0gyMkMyMi41NSAxNyAyMyAxNi41NSAyMyAxNkMyMyAxNS40NSAyMi41NSAxNSAyMiAxNUgxOVYxMkMxOSAxMS40NSAxOC41NSAxMSAxOCAxMVpNMTAgMTZDMTAgMjAuNDEgMTMuNTkgMjQgMTggMjRDMjIuNDEgMjQgMjYgMjAuNDEgMjYgMTZDMjYgMTEuNTkgMjIuNDEgOCAxOCA4QzEzLjU5IDggMTAgMTEuNTkgMTAgMTZaIiBmaWxsPSJ3aGl0ZSIvPg0KICAgIDwvbWFzaz4NCiAgICA8ZyBtYXNrPSJ1cmwoI3BsdXMpIj4NCiAgICAgICAgPHJlY3QgeD0iNiIgeT0iNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjU0Ii8+DQogICAgPC9nPg0KPC9zdmc+',
}
