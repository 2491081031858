import React from "react";
import {RouteComponentProps} from 'react-router-dom';
import UpdateUser from "../../../components/Forms/Users/UpdateUser";

interface IRouteProps {
    userId: string
}

interface IProps extends RouteComponentProps<IRouteProps> {

}

export class UpdateUserScene extends React.Component<IProps, any> {
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return <UpdateUser {...this.props} userId={Number.parseInt(this.props.match.params.userId)}/>;
    }
}
