import * as React from 'react';
import {AuthStore} from "../../../stores/auth/authStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {Avatar, Button, Card, Col, Form, Input, Row} from "antd";
import {Auth, Hub} from 'aws-amplify';
import {action, observable} from "mobx";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth";
import {RouteComponentProps} from "react-router-dom";
import {ResetPassword} from "./ResetPassword";
import {VerifyEmail} from "./VerifyEmail";
import Logo from '../../../procvt_logo.png';
import Google from '../../../google.png';

declare interface IProps extends RouteComponentProps {
    authStore?: AuthStore;
}


@inject(InjectNames.authStore)
@observer
export class SignIn extends React.Component<IProps> {
    constructor(props: any) {
        super(props);
        Hub.listen('auth', async (data) => {
            if (data.payload.event === 'signIn_failure' && data.payload.data.code === 'UserNotConfirmedException') {
                this.needVerifyUser = true
            }
        });
    }

    @observable email = "";
    @observable password = "";
    @observable loading = false;
    @observable needVerifyUser = false;
    @observable error = "";

    @action clearError = () => {
        this.error = ""
    }


    handleUserVerified = () => {
        this.needVerifyUser = false
        this.handleSubmit()
    }

    handleSubmit = async (e?: any) => {
        e?.preventDefault();
        this.clearError()
        try {
            this.loading = true
            await Auth.signIn({password: this.password, username: this.email})
        } catch (e) {
            this.error = e.message
        } finally {
            this.loading = false
        }
    };

    public render() {
        const span = {xs: 22, lg: 12}
        const container = <Row style={{height: '100vh', justifyContent: 'center', flexDirection: 'column'}}
                               gutter={[15, 50]} type={'flex'}>
            <Col {...span} style={{alignSelf: 'center'}}>
                <div style={{textAlign: 'center'}}>
                    <img alt={'ProCvt'} style={{maxWidth: '60%'}} src={Logo}/>
                </div>
            </Col>
            <Col {...span} style={{alignSelf: 'center'}}>
                <Card title={'Sign In'}>
                    <div style={{textAlign: 'center'}}>
                        <div style={{color: "red"}}>{this.error}</div>
                    </div>

                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item>
                            <Input type="email" value={this.email} onChange={event => {
                                this.clearError();
                                this.email = event.target.value
                            }}
                                   placeholder={"Enter email"}/>
                        </Form.Item>
                        <Form.Item>
                            <Input type="password" value={this.password}
                                   onChange={event => {
                                       this.clearError();
                                       this.password = event.target.value
                                   }}
                                   placeholder={"Enter password"}/>
                        </Form.Item>
                        <Row type={'flex'} justify={'space-between'} style={{marginTop: 30}}>
                            <Col>
                                <Row type={'flex'} style={{flexDirection: 'column'}} gutter={[15, 15]}>
                                    <Col>
                                        <Row gutter={15} type={'flex'} style={{flexDirection: 'row'}}>
                                            <Col>
                                                <Button htmlType={"button"} onClick={async () => {
                                                    this.props.history.push('/sign-up')
                                                }}>Sign up</Button>
                                            </Col>
                                            <Col>
                                                <span className={'hover-pointer'} onClick={async () => {
                                                    await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google})
                                                    this.loading = true
                                                }}><Avatar src={Google}/></span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <ResetPassword/>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Button htmlType={'submit'} type={"primary"}
                                            loading={this.loading}>Sign In</Button>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Col>
            <VerifyEmail onConfirm={this.handleUserVerified} confirmUserName={this.email}
                         needVerifyUser={this.needVerifyUser} {...this.props}/>
        </Row>
        return (container)
    }
}
