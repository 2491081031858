import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Col, Modal, Radio, Row, Select, Switch} from "antd";
import {action, observable} from "mobx";
import {InjectNames} from "../../stores/initializeStores";
import {CompareTracesStore, ValidRrCountThreshold} from "../../stores/traces/compareTracesStore";
import './CompareOptions.less'
import {CompareTimeType} from "../Charts/IChartSeriesInfo";

interface IProps {
    compareTracesStore?: CompareTracesStore
}

@inject(InjectNames.compareTracesStore)
@observer
export class CompareOptions extends React.Component<IProps> {
    @observable show = false

    get cmpStore() {
        return this.props.compareTracesStore!
    }

    @observable compareTimeType: CompareTimeType = this.cmpStore.compareTimeType;
    @observable isHeartbeatMode = this.cmpStore.isHeartbeatMode;
    @observable ignoreDayToggle = this.cmpStore.ignoreDayToggle;
    @observable showSymbols = this.cmpStore.showSymbols;
    @observable viewEcgEnabled = this.cmpStore.viewEcgEnabled;
    @observable validRrCountValue = this.cmpStore.validRrCountValue;

    @action
    handleOptionsWindowVisibility = () => {
        this.show = !this.show
        if (!this.show)
            this.discard()
    }

    @action
    reset = () => {
        // @ts-ignore
        this.compareTimeType = this.cmpStore.defaultValues.compareTimeType;
        this.isHeartbeatMode = this.cmpStore.defaultValues.isHeartbeatMode;
        this.ignoreDayToggle = this.cmpStore.defaultValues.ignoreDayToggle;
        this.showSymbols = this.cmpStore.defaultValues.showSymbols;
        this.viewEcgEnabled = this.cmpStore.defaultValues.viewEcgEnabled;
        this.validRrCountValue = this.cmpStore.defaultValues.validRrCountValue;
    }

    @action
    discard = () => {
        this.compareTimeType = this.cmpStore.compareTimeType;
        this.isHeartbeatMode = this.cmpStore.isHeartbeatMode;
        this.ignoreDayToggle = this.cmpStore.ignoreDayToggle;
        this.showSymbols = this.cmpStore.showSymbols;
        this.viewEcgEnabled = this.cmpStore.viewEcgEnabled;
        this.validRrCountValue = this.cmpStore.validRrCountValue;
    }

    @action
    save = () => {
        this.cmpStore.compareTimeType = this.compareTimeType;
        this.cmpStore.isHeartbeatMode = this.isHeartbeatMode;
        this.cmpStore.ignoreDayToggle = this.ignoreDayToggle;
        this.cmpStore.showSymbols = this.showSymbols;
        this.cmpStore.viewEcgEnabled = this.viewEcgEnabled;
        this.cmpStore.validRrCountValue = this.validRrCountValue;

        this.handleOptionsWindowVisibility()
    }

    render() {
        const validRrCountThresholdToggle = () => {
            let options: {enumName: string, threshold: number}[] = []
            for (const enumName in ValidRrCountThreshold) {
                if(isNaN(Number.parseInt(enumName)))
                    options.push({enumName: enumName, threshold: Number.parseInt(ValidRrCountThreshold[enumName])})
            }
            return <Select value={this.validRrCountValue}
                           onChange={((value: number) => this.validRrCountValue = value)}
                           style={{minWidth: 200}}
                           placeholder={'Quality'}>
                {options.map(opt => <Select.Option key={opt.threshold} value={opt.threshold}>{opt.enumName}</Select.Option>)}
            </Select>
        }

        const compareTimeTypeToggle = () =>
            <Radio.Group value={this.compareTimeType} onChange={(e => this.compareTimeType = e.target.value)}>
                <Radio.Button value={'Actual'}>Actual</Radio.Button>
                <Radio.Button value={'Elapsed'}>Elapsed</Radio.Button>
            </Radio.Group>;

        const heartbeatToggle = () =>
            <Radio.Group value={this.isHeartbeatMode} onChange={() => {
                this.isHeartbeatMode = !this.isHeartbeatMode
            }}>
                <Radio.Button disabled={!this.cmpStore.heartbeatModeAllowed} value={true}>Heartbeat</Radio.Button>
                <Radio.Button value={false}>Time</Radio.Button>
            </Radio.Group>;

        const ignoreDayToggle = () =>
            <React.Fragment>
                <Switch
                    disabled={this.compareTimeType !== "Actual"} checked={this.ignoreDayToggle}
                    onChange={() => this.ignoreDayToggle = !this.ignoreDayToggle}/>
                <span style={{paddingLeft: 15}}>Ignore day</span>
            </React.Fragment>

        const showSymbolsToggle = () => (
            <React.Fragment><Switch
                checked={this.showSymbols}
                onChange={() => this.showSymbols = !this.showSymbols}/>
                <span style={{paddingLeft: 15}}>Show symbols</span>
            </React.Fragment>)

        const viewEcg = () => <React.Fragment>
            <Switch
                checked={this.viewEcgEnabled}
                onChange={() => this.viewEcgEnabled = !this.viewEcgEnabled}/>
            <span style={{paddingLeft: 15}}>View ecg</span>
        </React.Fragment>

        return <div>
            <Button icon={'more'} onClick={this.handleOptionsWindowVisibility}/>

            <Modal visible={this.show} onCancel={this.handleOptionsWindowVisibility}
                   width={745}
                   title={'Chart options'}
                   centered={true}
                   footer={<div className={'cmp-options-footer'}>
                       <div className={'left'}>
                           <Button onClick={this.reset}>Reset</Button>
                       </div>
                       <div className={'right'}>
                           <div>
                               <Button onClick={this.discard}>Discard</Button>
                           </div>
                           <div>
                               <Button onClick={this.save} type={'primary'}>Save</Button>
                           </div>
                       </div>
                   </div>}>
                <div className="cmp-options-body">
                    <Row type={'flex'} gutter={[15, 15]}>
                        <Col span={24}>View mode</Col>
                        <Col className={'cmp-option-item'}>{heartbeatToggle()}</Col>
                        <Col className={'cmp-option-item'}>{compareTimeTypeToggle()}</Col>
                    </Row>
                    <Row type={'flex'} style={{marginTop: 15}} gutter={[15, 15]}>
                        <Col className={'cmp-option-item'}>{showSymbolsToggle()}</Col>
                        <Col className={'cmp-option-item'}>{ignoreDayToggle()}</Col>
                        <Col className={'cmp-option-item'}>{viewEcg()}</Col>
                    </Row>
                    <Row type={'flex'} style={{marginTop: 15}} gutter={[15, 15]}>
                        <Col span={24}>Quality options</Col>
                        <Col className={'cmp-option-item'} style={{marginRight: 30}}>{validRrCountThresholdToggle()}</Col>
                    </Row>
                </div>
            </Modal>
        </div>
    }
}
