import React from 'react';
import './App.css';
import {inject, observer} from "mobx-react";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {AuthStore} from "./stores/auth/authStore";
import {InjectNames} from "./stores/initializeStores";
import AuthorizedContainer from "./scenes/authorized/AuthorizedContainer";
import UnauthorizedContainer from './scenes/unauthorized/Login/UnauthorizedContainer';
import {Auth, Hub} from "aws-amplify";
import {getNotAuthorizedRoutes} from "./RouteSettings";
import {observable} from "mobx";
import {SpinOnCenter} from "./components/Loading/SpinOnCenter";
import moment from "moment-timezone";
import {CognitoIdToken} from "amazon-cognito-identity-js";

interface IAppProps extends RouteComponentProps {
    authStore?: AuthStore;
}

interface IAppState {
}

@inject(InjectNames.authStore)
@observer
class App extends React.Component<IAppProps, IAppState> {
    constructor(props: any) {
        super(props);
        Hub.listen('auth', async (data) => {
            console.log(data.payload.event)
            console.log(data)
            console.log('')
            if (data.payload.event === 'signOut') {
                await this.props.authStore!.onLogOut()
            } else if (data.payload.event === 'signIn') {
                await this.tryGetCurrentSession()
            }else {
                console.log(data.payload.event)
            }
        });
        this.tryGetCurrentSession()
    }

    @observable loading = true

    tryGetCurrentSession = async () => {
        try {
            console.log('tryGetCurrentSession')
            this.loading = true
            let session = await Auth.currentSession()
            await this.props.authStore!.onLogIn(session)
            this.delayRefreshToken(session.getIdToken())
        } finally {
            this.loading = false
        }
    }

    delayRefreshToken = (idToken: CognitoIdToken) => {
        const timeoutMs = this.getRefreshTokenTimeoutMs(idToken)
        setTimeout(this.refreshToken, timeoutMs)
        console.log(`scheduled id token refresh at ${moment().add(timeoutMs, 'ms').format()} (after a ${(timeoutMs / 1000 / 60).toFixed(0)} minutes)`)
    }

    getRefreshTokenTimeoutMs = (idToken: CognitoIdToken) => {
        const expirationAt = moment.unix(idToken.getExpiration())
        const minute = 60_000
        const timeout = expirationAt.diff(moment()) - (minute * 5)
        return timeout > 0 ? timeout : 0
    }

    refreshToken = async () => {
        try {
            console.log('start id token refreshing')
            let refreshToken = (await Auth.currentSession()).getRefreshToken()
            let user = await Auth.currentAuthenticatedUser()
            user.refreshSession(refreshToken, async (err: any, session: any) => {
                if (err) {
                    console.log(`Error during token refreshing.`)
                    await this.props.authStore!.logOut()
                }else {
                    user.setSignInUserSession(session);
                    await this.props.authStore!.onLogIn(session)
                    this.delayRefreshToken(session.getIdToken())
                    console.log(`id token refreshed`)
                }
            })
        } catch (e) {
            console.error("Error occurred while refreshing token")
        }
    }

    public render() {
        if (this.loading) {
            return <SpinOnCenter/>
        } else if (!this.props.authStore!.isAuthorized) {
            return (<div>
                <UnauthorizedContainer routes={getNotAuthorizedRoutes()} {...this.props}/>
            </div>);
        } else {
            return <div>
                <AuthorizedContainer {...this.props}/>
            </div>;
        }
    }
}

export default withRouter(App);
