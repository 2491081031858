import React from "react";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";
import {IRoomDto, RoomsStore} from "../../stores/roomsStore/roomsStore";
import {ColumnProps} from "antd/lib/table/interface";
import {ActionsButton} from "./ActionsButton";
import {Col, Input, Row, Select, Table, Tag} from "antd";
import {errorUtils} from "../../services/utils/ErrorUtils";
import {action, computed, observable} from "mobx";
import {RouteComponentProps} from "react-router";
import {OrgUnitSelect} from "../Select/OrgUnitSelect";
import {UserStore} from "../../stores/user/userStore";

interface IProps extends RouteComponentProps {
    roomsStore?: RoomsStore
    userStore?: UserStore
}

enum OccupiedStatus {
    Occupied, NotOccupied, All
}

@inject(InjectNames.roomsStore, InjectNames.userStore)
@observer
export class RoomsTable extends React.Component<IProps, any> {
    constructor(props: any) {
        super(props);
        this.loadTable()
    }

    @observable roomsList: IRoomDto[] = []
    @observable search?: string;
    @observable occupiedStatus: OccupiedStatus = OccupiedStatus.All;
    @observable orgUnitId?: number = this.props.userStore!.userDetails?.orgUnitId;
    @observable loading = false;

    @computed get occupied() {
        switch (this.occupiedStatus) {
            case OccupiedStatus.Occupied:
                return true;
            case OccupiedStatus.NotOccupied:
                return false;
            case OccupiedStatus.All:
                return undefined;
            default:
                throw new Error('Unknown OccupiedStatus')
        }
    }

    @computed get displayOrgUnit() {
        return this.props.userStore!.isSuperUser
    }

    loadTable = async () => {
        await errorUtils.execute({context: this, loadingFieldName: 'loading'}, async () => {
            this.roomsList = await this.props.roomsStore!.getRoomsList({
                occupied: this.occupied,
                offset: 0,
                rowCount: 1000,
                orgUnitId: this.orgUnitId,
                search: this.search
            })
        })
    };

    @action
    handleSearch = async (e: any) => {
        this.search = e.target.value;
        await this.loadTable()
    };
    @action handleOrgUnitChange = async (value?: number) => {
        this.orgUnitId = value
        await this.loadTable()
    }
    @action handleOccupiedChange = async (value: OccupiedStatus) => {
        this.occupiedStatus = value
        await this.loadTable()
    }

    render() {
        const actions = [
            {
                title: 'Edit',
                action: (text: string, record: IRoomDto) => {
                    this.props.history.push({pathname: `/rooms/edit/${record.roomId}`})
                },
            },
        ];

        const columns: ColumnProps<IRoomDto>[] = [
            {title: 'Id', width: 70, dataIndex: 'roomId'},
            {title: 'Name', width: 200, dataIndex: 'roomName'},
            {title: 'Location', width: 200, dataIndex: 'roomLocation'},
            {
                title: 'Occupied', dataIndex: 'occupied', width: 200,
                render: (value: any) => <Tag>{(value as boolean) ? 'Yes' : 'No'}</Tag>
            },

            {title: 'Address', dataIndex: 'address', width: 200},
            {
                title: 'Actions',
                width: 150,
                render: (text: string, record: any) => (<ActionsButton text={text} record={record} actions={actions}/>),
            },
        ];

        const search = <Row type={"flex"} justify={"end"} gutter={15}>
            <Col>
                <Input placeholder="Search..." style={{marginBottom: '15px', width: '300px'}}
                       value={this.search}
                       onChange={this.handleSearch}/>
            </Col>
            {
                this.displayOrgUnit && <Col>
                    <OrgUnitSelect value={this.orgUnitId} onChange={this.handleOrgUnitChange}/>
                </Col>
            }
            <Col>
                <Select value={this.occupiedStatus} onChange={this.handleOccupiedChange} style={{width: 150}}>
                    <Select.Option key={1} value={OccupiedStatus.All}>All</Select.Option>
                    <Select.Option key={2} value={OccupiedStatus.Occupied}>Occupied</Select.Option>
                    <Select.Option key={3} value={OccupiedStatus.NotOccupied}>Not occupied</Select.Option>
                </Select>
            </Col>
        </Row>

        return (
            <React.Fragment>
                {search}
                <Table dataSource={this.roomsList} columns={columns}
                       rowKey={record => `${record.roomId}`} loading={this.loading}/>
            </React.Fragment>
        );
    }
}
