import {TraceSeriesType} from "./ChartHelper";

class ColorHelper {
    readonly colors = ['rgba(107,188,243,1)', 'rgba(47,69,84,1)', 'rgba(97,160,168,1)', 'rgba(212,130,101,1)',
        'rgba(145,199,174,1)', 'rgba(116,159,131,1)', 'rgba(202,134,34,1)', 'rgba(189,162,154,1)',
        'rgba(110,112,116,1)', 'rgba(84,101,112,1)', 'rgba(196,204,211,1)'];

    readonly cvtAlphaDefault = 0.8
    getColor: (index: number, type?: TraceSeriesType) => string = (index: number, type: TraceSeriesType = "cvt") => {
        switch (type) {
            case "cvt":
                const length = this.colors.length;
                if (index >= length) {
                    return this.getColor((length - index) * -1, type)
                } else {
                    return this.colors[index].replace('1)', `${this.cvtAlphaDefault})`);
                }
            case "hr":
                return "rgba(226,76,73,1)"
            case "rr":
                return "rgba(82,82,82,1)"
            case "temp":
                return "rgba(67,76,252,1)"
            default:
                return "rgba(232,183,85,1)"

        }
    };

    getColorOpacity: (index: number, type?: TraceSeriesType) => string = (index: number, type: TraceSeriesType = "cvt") => {
        switch (type) {
            case "cvt":
                return this.getColor(index, type).replace(`${this.cvtAlphaDefault})`, '0.25)')
            default:
                return this.getColor(index, type).replace('1)', '0.25)')
        }
    };
}

export const colorHelper = new ColorHelper()