import {IDisposable, RootStore} from "../initializeStores";
import {GetAlertsRequest, ProCvtClient} from "../../services/api/ProCvtClient";
import {action, computed, observable} from "mobx";

export class AlertsStore implements IDisposable {
    constructor(pbmSleepClient: ProCvtClient, rootStore: RootStore) {
        this.client = pbmSleepClient!;
        this.rootStore = rootStore;
    }

    private readonly client: ProCvtClient;
    private readonly rootStore: RootStore;

    @observable alerts: IAlertDto[] = []

    @computed get alertsSortedByRiskAndIsActive() {
        return this.alerts
            .sort((a, b) => b.riskLevel - a.riskLevel)
            .sort((a, b) => (b.active ? 1 : 0) - (a.active ? 1 : 0))
    }
    @computed get activeAlertsSortedByRisk() {
        return this.alertsSortedByRiskAndIsActive
            .filter(alert => alert.active)
    }

    @action
    loadAlerts = async (input: IGetAlertsInput) => {
        const alerts = await this.client.alerts_GetAlerts(new GetAlertsRequest(input))
        this.alerts = !!alerts ? alerts : []
    }

    dispose = async () => {
        this.alerts = []
    }

}

export interface IGetAlertsInput {
    personId?: number | undefined;
    isActive?: boolean | undefined;
    offset?: number | undefined;
    rowCount?: number | undefined;
}

export interface IAlertDto {
    alertId: number;
    alertTypeId: number;
    traceId: number;
    raisedTime: Date;
    active: boolean;
    closedTime?: Date | undefined;
    closedByUserId?: number | undefined;
    closedByUserName?: string | undefined;
    closeReasonId?: number | undefined;
    closeReason?: string | undefined;
    closeReasonDescription?: string | undefined;
    personId: number;
    personName?: string | undefined;
    roomName?: string | undefined;
    alertMsg?: string | undefined;
    alertName?: string | undefined;
    alertDesc?: string | undefined;
    riskType?: string | undefined;
    riskName?: string | undefined;
    riskLevel: number;
    riskDesc?: string | undefined;
    color?: string | undefined;
}
