import React from "react";
import {RouteComponentProps} from "react-router";
import {Avatar, Card, Col, Radio, Row} from "antd";
import {TracesStore} from "../../../stores/traces/tracesStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {UserStore} from "../../../stores/user/userStore";
import {action, observable} from "mobx";
import {ChartStore} from "../../../stores/chartStore/chartStore";
import {AnnotationStore} from "../../../stores/annotationStore/annotationStore";
import {PersonOverTimeTraces} from "../../../components/Traces/PersonOverTimeTraces";
import {PersonRealTimeTrace} from "../../../components/Traces/PersonRealTimeTrace";
import {Meta} from "antd/es/list/Item";
import {enumHelper} from "../../../services/utils/EnumHelper";
import moment from "moment-timezone";
import {regexHelper} from "../../../services/utils/RegexHelper";
import {IPersonDetailsDto, PersonsStore} from "../../../stores/personsStore/personsStore";
import {CompareTraces} from "../../../components/Traces/CompareTraces";
import './PersonOverviewScene.less'
import {AlertsStore} from "../../../stores/alertsStore/alertsStore";

interface IRouteProps {
    personId: string;
    viewMode?: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
    tracesStore?: TracesStore
    alertsStore?: AlertsStore
    annotationStore?: AnnotationStore
    userStore?: UserStore
    personsStore?: PersonsStore
    chartStore?: ChartStore
}

enum ViewModeType {
    'Traces' = 'Traces',
    'Realtime' = 'Realtime'
}

@inject(InjectNames.tracesStore, InjectNames.userStore,
    InjectNames.chartStore, InjectNames.annotationStore,
    InjectNames.personsStore, InjectNames.alertsStore
)
@observer
export class PersonOverviewScene extends React.Component<IProps> {
    public static readonly routePaths = {
        subject: '/person/overview/person/:personId',
        subjectFormat: (personId: number) => `/person/overview/person/${personId}`,
        session: '/person/overview/person/:personId/view-mode/:viewMode',
        sessionFormat: (personId: number) => `/person/overview/person/${personId}/view-mode/${ViewModeType.Traces}`,
    }

    @observable personId!: number;
    readonly viewModeDefault: ViewModeType = ViewModeType.Traces;
    @observable viewMode: ViewModeType = this.viewModeDefault;
    @observable subject?: IPersonDetailsDto;


    async componentDidMount() {
        this.personId = Number.parseInt(this.props.match.params.personId)
        if (!regexHelper.isEmptyString(this.props.match.params.viewMode))
            this.viewMode = this.props.match.params.viewMode as ViewModeType

        this.loadSubjectDetails();

        if (!!this.props.match.params.viewMode) {
            // need find other way to be easy manage viewMode
            window.history.replaceState(null, "", PersonOverviewScene.routePaths.subjectFormat(this.personId))
        }
    }

    async componentWillUnmount() {
        await this.props.tracesStore!.dispose()
    }

    @action
    handelChangeViewMode = async (viewMode: ViewModeType) => {
        await this.props.tracesStore!.dispose()
        this.viewMode = viewMode
    }


    loadSubjectDetails = async () => {
        this.subject = (await this.props.personsStore!.getPersonDetails(this.personId));
    }

    compareRef?: CompareTraces

    render() {
        const subjectDetails = () => {
            return <Card title={undefined} style={{backgroundColor: 'inherit'}} bordered={false}>
                <Row type={'flex'} gutter={[15, 15]}>
                    <Col>
                        <Avatar shape={'circle'} style={{width: 95, height: 95}}/>
                    </Col>
                    <Col>
                        <div style={{
                            display: "flex",
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '100%'
                        }}>
                            <Meta title={<span>{this.subject!.firstName + ' ' + this.subject!.lastName}</span>}
                                  description={
                                      <React.Fragment>
                                          <div>
                                              <span>
                                                  {enumHelper.getFriendlyName(this.subject!.gender)}
                                              </span>
                                              {!!this.subject!.dateOfBirth &&
                                              <span>, {moment().diff(moment(this.subject!.dateOfBirth), 'years')}</span>}
                                          </div>
                                      </React.Fragment>
                                  }
                            />
                        </div>
                    </Col>
                </Row>
            </Card>
        };

        const chart = () => {
            switch (this.viewMode) {
                case ViewModeType.Traces:
                    return <Row gutter={[15, 15]}>
                        <Col>
                            <CompareTraces ref={ref => this.compareRef = ref!} {...this.props}/>
                        </Col>
                        <Col>
                            <PersonOverTimeTraces {...this.props} subjectId={this.personId!}
                                                  toChart={() => this.compareRef?.load()}/>
                        </Col>
                    </Row>
                case ViewModeType.Realtime:
                    return <PersonRealTimeTrace {...this.props} subjectId={this.personId!}/>
            }
        }

        const latestAlert = () =>{
            const alert = this.props.alertsStore!.alertsSortedByRiskAndIsActive?.find(x => x.active)
            return alert &&
            <div className={'latest-active-alert'} style={{background: alert.color}}>{alert!.riskName}</div>
        }

        return (
            <React.Fragment>
                {!this.personId ? undefined : <Row style={{paddingBottom: '30px'}}>
                    <div className={'person-overview-page'}>
                        <div className={'person-overview'}>
                            <Row type={'flex'} justify={'space-between'} style={{paddingBottom: '30px'}}>
                                <Col>
                                    {!!this.subject && subjectDetails()}
                                </Col>
                                <Col>
                                    {latestAlert()}
                                </Col>
                            </Row>

                            <Card>
                                <Radio.Group style={{marginBottom: 15}}
                                             value={this.viewMode} disabled={!this.personId}
                                             onChange={e => this.handelChangeViewMode(e.target.value)}>
                                    <Radio.Button value={ViewModeType.Traces}>{'Traces'}</Radio.Button>
                                    <Radio.Button value={ViewModeType.Realtime}>{'Realtime'}</Radio.Button>
                                </Radio.Group>

                                {chart()}
                            </Card>
                        </div>
                        {/*<div className={'person-overview-alerts-sidebar'}>*/}
                        {/*    <AlertsSidebar {...this.props} options={{alertOptions: {subjectId: this.personId}}}/>*/}
                        {/*</div>*/}
                    </div>
                </Row>}
            </React.Fragment>
        );
    }
}
