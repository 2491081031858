import React from "react";
import {RouteComponentProps} from "react-router";
import {Button, Col, Modal, Radio, Row} from "antd";
import {inject, observer} from "mobx-react";
import moment from "moment-timezone";
import {Duration} from "moment";
import {TraceType} from "../../services/api/ProCvtClient";
import {IOverTimeTracesDto, TracesStore} from "../../stores/traces/tracesStore";
import {InjectNames} from "../../stores/initializeStores";
import {action, computed, observable} from "mobx";
import {IChartPointMark, IChartValue, IOnPointClickActions, IToolTipParams} from "../Charts/IChartSeriesInfo";
import {dataUriIcons} from "../../constants/Icon";
import {
    chartHelper,
    IBaseBatchPointDataJson,
    IChartPointMarkJson,
    ITraceOverTimePointDataJson
} from "../../services/utils/ChartHelper";
import {AnnotationStore} from "../../stores/annotationStore/annotationStore";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";
import {errorUtils} from "../../services/utils/ErrorUtils";
import {Chart} from "../Charts/Chart";
import {AnnotationTraceList} from "../Annotations/AnnotationTraceList";
import {AnnotationCreateModal, InitAnnotation} from "../Annotations/AnnotationCreateModal";
import {CompareTracesStore} from "../../stores/traces/compareTracesStore";


interface IProps extends RouteComponentProps {
    tracesStore?: TracesStore
    compareTracesStore?: CompareTracesStore
    annotationStore?: AnnotationStore
    subjectId: number
    toChart: () => void;
}


@inject(InjectNames.tracesStore, InjectNames.compareTracesStore)
@observer
export class PersonOverTimeTraces extends React.Component<IProps> {
    @observable showAnnotationDialog = false;
    @observable initAnnotation?: InitAnnotation;
    @observable chartLoading = true;
    @observable otTraceType: TraceType = TraceType.SLEEP;
    @observable overTime: Duration = moment.duration(1, 'month');
    overTimeMax: Duration = moment.duration(2, 'years');
    @observable tableTraceType?: TraceType = TraceType.SLEEP;


    @observable otSeries: IOverTimeTracesDto[] = [];


    async componentDidMount() {

        this.loadOtSeries();
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.subjectId !== this.props.subjectId) {
            this.loadOtSeries();
        }
    }

    componentWillUnmount() {
        this.otSeries = [];
    }

    get cmpStore() {
        return this.props.compareTracesStore!
    }

    @action
    handelChangeDetailsTraceType = async (e: any) => {
        this.otTraceType = e.target.value;
        await this.loadOtSeries();
    };
    @action
    handelChangeOverTime = async (duration: Duration) => {
        this.overTime = duration;
        await this.loadOtSeries()
    };


    loadOtSeries = async () => {

        this.otSeries = [];
        if (this.props.subjectId !== undefined) {
            await errorUtils.execute({context: this, loadingFieldName: 'chartLoading'}, async () => {
                this.otSeries = await this.props.tracesStore!.getOtSeries({
                    subjectId: this.props.subjectId!,
                    traceType: this.otTraceType,
                    dateFrom: moment().add(-this.overTime).toDate(),
                    dateTo: moment().toDate(),
                })
            })
        }
        await this.props.annotationStore!.loadOverTime(this.props.subjectId!, this.otTraceType)
    };

    @computed get visualMap() {
        const visualMapOutIfRangeOpacity = 0.4
        // Work with xAxisType "category"
        if (this.otTraceType !== TraceType.FATIGUE) {

            if (!this.props.tracesStore!.selectedTraces?.length)
                return undefined

            // Filter duplicated traces to correct find x-axis index.
            let filteredSource: IOverTimeTracesDto[] = []
            this.otSeries?.forEach(series => {
                if (!filteredSource.some(filtered => filtered.traceDateTime.getTime() === series.traceDateTime.getTime() &&
                    filtered.personName === series.personName)) {
                    filteredSource.push(series)
                }
            })

            let pieces = this.props.tracesStore!.selectedTraces.map(x => {
                let numbersRange = filteredSource.filter(chartData => (chartData.traceDateTime as Date).getTime() === x.traceDateTime.getTime());
                return numbersRange.map(range => {
                    let number = filteredSource.findIndex(chartData => chartData.traceId === range.traceId);
                    return {
                        min: number,
                        max: number,
                    }
                })
            }).flatMap(x => x)

            if (!pieces?.length) {
                pieces = [{min: -1, max: -1}]
            }

            return [{
                show: false,
                type: 'piecewise',
                pieces: pieces,
                dimension: 0,
                inRange: {opacity: 1},
                outOfRange: {opacity: visualMapOutIfRangeOpacity}
            }].filter(x => !!x.pieces?.length)

        } else {
            // TODO Fatigue traces, hasn't "category" axis, but should be marked as selected
            return undefined
        }
    }

    @computed get chartSeries() {
        return !!this.otSeries?.length ? chartHelper.mapOverTimeTraces(this.otSeries, this.otTraceType, this.props.annotationStore!.tracesOverTimeList) : []
    }

    @computed get onPointClickOptions() {
        const imageSize = 30;
        const offset = 35;

        let actions: IOnPointClickActions[] = [];
        actions.push({
            image: {
                x: 0,
                y: 0,
                style: {
                    width: imageSize, height: imageSize,
                    image: dataUriIcons.Trace
                }
            },
            action: async (dataValue: IChartValue, seriesId: string) => {
                this.props.tracesStore!.selectedTraces = [
                    await this.props.tracesStore!.getTraceById(this.otSeries!.find(x => (dataValue.json as ITraceOverTimePointDataJson).traceId === x.traceId)!.traceId)
                ];
                this.props.toChart();
            }
        })

        actions.push({
            image: {
                x: offset,
                y: 0,
                style: {
                    width: imageSize, height: imageSize,
                    image: dataUriIcons.Plus
                }
            }, action: async (dataValue: IChartValue) => {
                const traceId = (dataValue.json as IBaseBatchPointDataJson).traceId
                if (!this.props.tracesStore!.selectedTraces.some(x => x.traceId === traceId)) {
                    if (!this.cmpStore.addNewSeriesAllowed) {
                        Modal.error({content: 'Heartbeat mode does not support multiple traces.'})
                        return
                    }
                    this.props.tracesStore!.selectedTraces.push({...await this.props.tracesStore!.getTraceById(traceId)})
                } else
                    this.props.tracesStore!.selectedTraces = this.props.tracesStore!.selectedTraces.filter(x => x.traceId !== traceId)

                // Immediate loading, because single page
                this.props.toChart();
            }
        });

        actions.push({
            image: {
                x: offset * 2,
                y: 0,
                style: {
                    width: imageSize, height: imageSize,
                    image: dataUriIcons.Annotation
                }
            }, action: async (dataValue: IChartValue) => {
                this.initAnnotation = {
                    traceId: this.otSeries[dataValue.index].traceId,
                    sequence: undefined,
                    traceInfo: {
                        subjectName: this.otSeries[dataValue.index].personName,
                        traceDate: this.otSeries[dataValue.index].traceDateTime
                    }
                }
            }
        });

        return {
            actions: actions,
        };
    }

    onMarkClick = async (dataValue: IChartPointMark, seriesId: string) => {
        const json = dataValue.json as IChartPointMarkJson
        if (json.isAnnotation) {
            this.props.annotationStore!.selectAnnotation({trace: json.traceId})
            this.showAnnotationDialog = true
        }
    };

    render() {
        // const toChart = <Badge style={{background: '#aaaaaa'}} offset={[10, 0]}
        //                        count={this.props.tracesStore!.selectedTraces.length} showZero={true}>
        //     <span>
        //         {/*<Dropdown.Button icon={<Icon type={'down'}/>}*/}
        //         {/*                 onClick={this.props.toChart}*/}
        //         {/*                 trigger={['click']}*/}
        //         {/*                 overlay={*/}
        //         {/*                     <Menu>*/}
        //         {/*                         <Menu.Item key='clear'*/}
        //         {/*                                    onClick={() => {*/}
        //         {/*                                        this.props.tracesStore!.clearSelected()*/}
        //         {/*                                    }}>Clear all selected traces*/}
        //         {/*                         </Menu.Item>*/}
        //         {/*                         <Menu.Item key='view-selected'*/}
        //         {/*                                    onClick={this.props.tracesStore!.moveSelectedToTraces}>View selected*/}
        //         {/*                             traces*/}
        //         {/*                         </Menu.Item>*/}
        //         {/*                         <Menu.Item key='view-all'*/}
        //         {/*                                    onClick={this.loadTraces}>View all*/}
        //         {/*                             traces*/}
        //         {/*                         </Menu.Item>*/}
        //         {/*                     </Menu>*/}
        //         {/*                 }>To Chart*/}
        //         {/*</Dropdown.Button>         */}
        //         {/*<Button onClick={this.props.toChart}>Load traces</Button>*/}
        //     </span>
        // </Badge>;


        const msgButton =
            <Button icon={'message'}
                    onClick={async () => {
                        if (!this.props.subjectId)
                            return;
                        await errorUtils.execute({
                            context: this,
                            loadingFieldName: 'annotationDialogLoading'
                        }, async () => {
                            this.showAnnotationDialog = true
                            await this.props.annotationStore?.loadOverTime(this.props.subjectId, this.otTraceType)
                        })
                    }}>Annotations</Button>


        const subjectChart = () => {
            const tooltipFormatter = (values: IToolTipParams[]) => chartHelper.tooltipFormatterDefault(values, ((chartValue: IChartValue) => dateTimeHelper.day_month_year_hour_min_sec(chartValue.x as Date)))

            const xAxisFormatter = (value: Date | number) => dateTimeHelper.day_month_year(value as Date)
            const seriesType = this.otTraceType === TraceType.SLEEP ? 'bar' : 'line'
            return <Row style={{textAlign: 'center'}} gutter={[15, 15]}>

                <Row type={'flex'} justify={'space-between'} gutter={[15, 15]}
                     style={{marginLeft: '10%', marginRight: '10%'}}>
                    <Col>
                        <Radio.Group value={this.otTraceType}
                                     onChange={this.handelChangeDetailsTraceType}>
                            <Radio.Button value={TraceType.SLEEP}>{TraceType.SLEEP}</Radio.Button>
                            <Radio.Button value={TraceType.STRESS}>{TraceType.STRESS}</Radio.Button>
                            <Radio.Button value={TraceType.FATIGUE}>{TraceType.FATIGUE}</Radio.Button>
                        </Radio.Group>
                    </Col>

                    <Col>
                        <Radio.Group value={this.overTime.asMinutes()}
                                     onChange={e => this.handelChangeOverTime(moment.duration(e.target.value, 'minutes'))}>
                            <Radio.Button value={moment.duration(1, 'day').asMinutes()}>1D</Radio.Button>
                            <Radio.Button value={moment.duration(5, 'days').asMinutes()}>5D</Radio.Button>
                            <Radio.Button value={moment.duration(1, 'month').asMinutes()}>1M</Radio.Button>
                            <Radio.Button value={moment.duration(1, 'year').asMinutes()}>1Y</Radio.Button>
                            <Radio.Button value={this.overTimeMax.asMinutes()}>Max</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>

                <Col span={24}>
                    <Chart opts={{width: undefined, height: 300}}
                           loading={this.chartLoading}
                           sampling={'custom'}
                           showSymbols={true}
                           parallelYAxis={[
                               {
                                   yAxisName: "CVT",
                                   parallelYAxisNumber: undefined,
                                   hideName: true,
                                   yAxisHideValue: true,
                                   yAxisHideTick: true
                               },
                               {
                                   yAxisName: "HR",
                                   parallelYAxisNumber: 1,
                                   hideName: true,
                                   yAxisHideValue: true,
                                   yAxisHideTick: true
                               },
                               {
                                   yAxisName: "RR",
                                   parallelYAxisNumber: 2,
                                   hideName: true,
                                   yAxisHideValue: true,
                                   yAxisHideTick: true
                               },
                               {
                                   yAxisName: "TEMP",
                                   parallelYAxisNumber: 3,
                                   hideName: true,
                                   yAxisHideValue: true,
                                   yAxisHideTick: true
                               },
                               {
                                   yAxisName: "SLEEP SCORE",
                                   parallelYAxisNumber: 4,
                                   hideName: true,
                                   yAxisHideValue: true,
                                   yAxisHideTick: true
                               },
                           ]}
                           disableAutoScale={true} hideToolbox={true}
                           chartSeries={this.chartSeries}
                           xAxisType={"category"} seriesType={seriesType}
                           onPointClickOptions={this.onPointClickOptions}
                           onMarkClick={this.onMarkClick}
                           xAxisFormatter={xAxisFormatter}
                           tooltipFormatter={tooltipFormatter}
                           legendFormatter={chartHelper.legendFormatterEmpty}
                           visualMap={this.visualMap}
                    />
                </Col>
            </Row>
        };
        return (
            <React.Fragment>
                <Row gutter={[15, 15]}>
                    <Col span={24}>
                        <Row gutter={15} type={'flex'} justify={'end'}>
                            <Col>{msgButton}</Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        {subjectChart()}
                    </Col>
                </Row>

                <AnnotationTraceList annotations={this.props.annotationStore!.tracesOverTimeList}
                                     visible={this.showAnnotationDialog}
                                     onClose={() => this.showAnnotationDialog = false}/>
                <AnnotationCreateModal onClose={() => this.initAnnotation = undefined} onAdded={(annotationId) => {
                    this.props.annotationStore!.selectAnnotation({
                        annotation: annotationId,
                        trace: this.initAnnotation!.traceId
                    });
                    this.initAnnotation = undefined;
                    this.showAnnotationDialog = true
                }} model={this.initAnnotation}/>
            </React.Fragment>
        );
    }
}
