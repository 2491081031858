import React from "react";
import {Button, Row} from "antd";

interface IProps {
    onCancel: () => void;
    loading: boolean;
    okTitle: string
}

export class SubmitCancelFormRow extends React.Component<IProps> {
    render() {
        return <Row type={'flex'} justify={'space-between'}>
            <Button type={"default"} onClick={this.props.onCancel}>Cancel</Button>
            <Button type={"primary"} loading={this.props.loading} htmlType={"submit"}>{this.props.okTitle}</Button>
        </Row>
    }
}
