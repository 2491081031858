import fetchIntercept from 'fetch-intercept';
import {Modal} from 'antd';
import {InitializedStores} from "../../stores/initializeStores";
import {ErrorBase} from "../utils/ErrorUtils";

fetchIntercept.register({
    request(url, config) {
        let token = InitializedStores.authStore.token
        if (!!token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }

        return [url, config];
    },

    async requestError(error) {
        return error;
    },

    response(response) {
        if (response.status !== 200) {
            switch (response.status) {
                case 401:
                    Modal.error({
                        title: 'Authorize error',
                        content: 'Authorize error',
                    });
                    InitializedStores.authStore.logOut();
                    break;
                case 500:
                    const clone = response.clone()
                    clone.json()
                        .then((errorBase: ErrorBase | undefined | null) => {
                            if (!!errorBase?.failReason) {
                                Modal.error({
                                    title: "Error",
                                    content: errorBase.failReason,
                                });
                            } else {
                                Modal.error({
                                    title: "Error",
                                    content: 'Unknown error',
                                });
                            }
                        });
                    break;
            }
            setTimeout(() => {
            }, 1000);
        }
        return response;
    },

    responseError(error) {
        return Promise.reject(error);
    },
});
