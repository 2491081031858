import {IDisposable, RootStore} from "../initializeStores";
import {
    AssignDeviceRequest, DeviceAssignedTo, DeviceOnlineStatus,
    DeviceStatus,
    GetDevicesRequest,
    ProCvtClient,
    RegisterDeviceRequest
} from "../../services/api/ProCvtClient";
import {action} from "mobx";
import {proCvtClientInstance} from "../../services/api/ProCvtClientInstance";

export class DevicesStore implements IDisposable {
    constructor(client: ProCvtClient, rootStore: RootStore) {
        this.client = client!;
        this.rootStore = rootStore;
    }

    private readonly client: ProCvtClient;
    private readonly rootStore: RootStore;


    @action
    getDevices: (request: IGetDevicesDto) => Promise<IDeviceDto[]> = async (request: IGetDevicesDto) => {
        let result = await proCvtClientInstance.devices_GetDevices(new GetDevicesRequest(request))

        if (!result)
            result = []

        return !!result ? result : []
    };

    @action
    registerDevice: (input: IRegisterDeviceDto) => Promise<void> = async (input: IRegisterDeviceDto) => {
        await this.client.devices_Register(new RegisterDeviceRequest({
            deviceName: input.deviceName?.trim(),
            fwVersion: input.fwVersion?.trim(),
            hwVersion: input.hwVersion?.trim()
        }))
    };

    @action
    assignDevice: (input: IAssignDeviceDto) => Promise<void> = async (input: IAssignDeviceDto) => {
        await this.client.devices_Assign(new AssignDeviceRequest({
            deviceName: input.deviceName?.trim(),
            personId: input.personId,
            roomId: input.roomId
        }))
    };

    @action
    getDeviceOwnership: (input: { deviceName: string }) => Promise<IDeviceDetailsDto | null> = async (input: { deviceName: string }) => {
        return await this.client.devices_GetDeviceOwnership(input.deviceName?.trim())
    };

    @action
    getStatus: (input: { deviceName: string }) => Promise<DeviceStatus> = async (input: IAssignDeviceDto) => {
        return (await this.client.devices_GetStatus(input.deviceName?.trim()))!
    };

    dispose = () => {
        return Promise.resolve();
    }
}

export interface IGetDevicesDto {
    personId?: number | undefined;
}

export interface IRegisterDeviceDto {
    deviceName?: string | undefined;
    fwVersion?: string | undefined;
    hwVersion?: string | undefined;
}

export interface IAssignDeviceDto {
    deviceName?: string | undefined;
    roomId?: number | undefined;
    personId?: number | undefined;
}

export interface IDeviceDetailsDto {
    deviceName?: string | undefined;
    orgUnitId: number;
    roomId?: number | undefined;
    personId?: number | undefined;
    assignedTo?: DeviceAssignedTo | undefined;
}

export interface IDeviceDto {
    deviceName?: string | undefined;
    status: DeviceOnlineStatus;
}