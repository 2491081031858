import React from "react";
import {RouteComponentProps} from "react-router";
import {IUserBySearchDto, UserStore} from "../../stores/user/userStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";
import {Col, Input, Radio, Row, Table} from "antd";
import {ColumnProps} from "antd/lib/table/interface";
import {action, computed, observable} from "mobx";
import {UserType} from "../../services/api/ProCvtClient";
import {errorUtils} from "../../services/utils/ErrorUtils";
import {RadioChangeEvent} from "antd/lib/radio/interface";
import {ActionsButton} from "./ActionsButton";
import {OrgUnitSelect} from "../Select/OrgUnitSelect";
import {enumHelper} from "../../services/utils/EnumHelper";

interface IProps extends RouteComponentProps {
    userStore?: UserStore
}

@inject(InjectNames.userStore)
@observer
export class UsersTable extends React.Component<IProps> {
    async componentDidMount() {
        await this.loadTable();
    }

    @observable searchUserType: UserType = UserType.Subject;
    @observable orgUnitId?: number = undefined;
    @observable search?: string;
    @observable loading: boolean = false;

    @computed get displayOrgUnit() {
        return this.props.userStore!.isSuperUser
    }

    @computed get allowedUserTypes() {
        const userStore = this.props.userStore!
        if (userStore.isSuperUser)
            return Object.keys(UserType)
        else if (userStore.isPrimary)
            return Object.keys(UserType).filter(x => x !== UserType.Super_User)
        else if (userStore.isSecondaryOrThirdParty)
            return [UserType.Subject]
        else return []
    }

    @action
    handleChangeOrgUnit = (value: number) => {
        this.orgUnitId = value
        this.loadTable()
    }

    @action loadTable = async () => {
        await errorUtils.execute({context: this, loadingFieldName: 'loading'}, async () => {
            await this.props.userStore!.loadUsers({
                offset: 0,
                /**Fix.Hard Need use pagination*/
                rowCount: 1000,
                // rowCount: this.props.practitionerStore!.stats.subjectCount,
                search: this.search,
                orgUnitId: this.orgUnitId,
                userType: this.searchUserType
            })
        })
    };
    @action
    handleChangeUserType = (e: RadioChangeEvent) => {
        this.searchUserType = e.target.value;
        this.loadTable()
    };
    @action
    handleSearch = (e: any) => {
        this.search = e.target.value;
        this.loadTable()
    };

    render() {
        const actions = [
            {
                title: 'Edit',
                action: (text: string, record: IUserBySearchDto) => {
                    this.props.history.push({pathname: `/users/edit/${record.userId}`})
                },
            },
        ];

        const columns: ColumnProps<IUserBySearchDto>[] = [
            {
                title: 'Role', width: 100,
                dataIndex: 'userType',
                render: (value: any) => enumHelper.getFriendlyName(value)
            },
            {title: 'Name', width: 200, dataIndex: 'userName'},
            {title: 'Email', width: 200, dataIndex: 'email'},
            {title: 'Urg. unit', width: 200, dataIndex: 'orgUnitName'},
            {title: 'Address', dataIndex: 'address', width: 200},
            {
                title: 'Actions',
                width: 150,
                render: (text: string, record: any) => (<ActionsButton text={text} record={record} actions={actions}/>),
            },
        ];

        const search = <Row type={"flex"} justify={"space-between"} gutter={[15, 15]}>
            <Col>
                {!!this.allowedUserTypes?.length &&
                <Radio.Group value={this.searchUserType} disabled={this.loading}
                             onChange={this.handleChangeUserType}>
                    {this.allowedUserTypes.map((userType) =>
                        (<Radio.Button key={userType}
                                       value={userType}>{enumHelper.getFriendlyName(userType)}</Radio.Button>))
                    }
                </Radio.Group>
                }
            </Col>
            <Col>
                <Row type={"flex"} justify={"end"} gutter={15}>
                    <Col>
                        <Input placeholder="Search..." style={{marginBottom: '15px', width: '300px'}}
                               value={this.search}
                               onChange={this.handleSearch}/>
                    </Col>
                    {this.displayOrgUnit &&
                    <Col>
                        <OrgUnitSelect value={this.orgUnitId} onChange={this.handleChangeOrgUnit}/>
                    </Col>
                    }
                </Row>
            </Col>
        </Row>
        return (
            <React.Fragment>
                {search}
                <Table dataSource={this.props.userStore!.userList} columns={columns}
                       rowKey={record => `${record.userId}`} loading={this.loading}/>
            </React.Fragment>
        );
    }
}
