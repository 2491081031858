import "./WebChat.less"
import React from "react";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";
import {AuthStore} from "../../stores/auth/authStore";
import ReactWebChat, {createDirectLine, createStore} from 'botframework-webchat';
import {proCvtClientInstance} from "../../services/api/ProCvtClientInstance";
import {SpinOnCenter} from "../Loading/SpinOnCenter";
import {UserStore} from "../../stores/user/userStore";
import jwtHelper from "../../services/utils/JwtHelper";
import {Modal} from "antd";
import {RouteComponentProps} from "react-router-dom";
import {
    QuestionCategory
} from "../../services/api/ProCvtClient";
import {WebChatCompleted, webChatComponents} from "./WebChatComponents/WebChatComponents";
import {SurveyBotStore} from "../../stores/surveyBotStore/surveyBotStore";


interface IRouteProps {
    personId: string;
    questionCategory: QuestionCategory;
}

interface IProps extends RouteComponentProps<IRouteProps> {
    authStore?: AuthStore
    userStore?: UserStore
    surveyBotStore?: SurveyBotStore
}
const attachmentMiddleware = (props:RouteComponentProps) => {
    return () => (next: (arg0: any) => any) => (card: { acivity: any, attachment: { contentType: any; content?: any; }; }) => {
        switch (card.attachment.contentType) {
            case 'custom':
                const customComponent = webChatComponents[card.attachment.content.answers_bundle.input_type]
                return !!customComponent ? customComponent(card.attachment.content) : <div>Unsupported</div>
            case 'completed':
                return WebChatCompleted(props)
            default:
                return next(card);
        }
    };
}

const store = (surveyBotStore:SurveyBotStore) => {
    // @ts-ignore
    return createStore({}, ({ dispatch }) => next => action => {
        const value = action.payload?.activity?.value
        switch (action.type) {
            case 'DIRECT_LINE/POST_ACTIVITY_PENDING':
                surveyBotStore.loading.push(value?.questionId)
                break;
            case 'DIRECT_LINE/POST_ACTIVITY_FULFILLED':
                window.scrollTo({top: document.body.scrollHeight, behavior: "smooth"})
                surveyBotStore.loading = surveyBotStore.loading.filter(questionId => questionId !== value?.questionId)
                break;
        }
        return next(action)
    })
}
@inject(InjectNames.authStore, InjectNames.userStore, InjectNames.surveyBotStore)
@observer
export class SurveyBot extends React.Component<IProps, any> {
    constructor(props: any) {
        super(props);
        this.init()
    }

    componentWillUnmount(): void {
        this.props.surveyBotStore!.personId = undefined
    }

    userID = this.props.userStore!.userDetails?.email;
    username = this.props.userStore!.userDetails?.userName;
    directLine: any;

    surveyOptions = {
        sessionId: jwtHelper.decode(this.props.authStore!.idToken!).session_id,
        personId: Number.parseInt(this.props.match.params.personId),
        questionCategory: this.props.match.params.questionCategory
    }

    init = async () => {
        try {
            this.props.surveyBotStore!.initialization = true
            this.props.surveyBotStore!.personId = this.surveyOptions.personId
            const secret = await this.getSecret()
            if (!secret?.length) {
                Modal.error({title: 'Can not get DirectLine config'})
                return
            }
            await this.createDirectLine(secret)
            await this.sendSurveyOptions()
            this.props.surveyBotStore!.initialization = false;
        } catch {
            Modal.error({title: 'Something went wrong'})
        }
    }

    getSecret = async () => {
        return await proCvtClientInstance.bot_GetDirectLineSecret()
    }
    createDirectLine = async (secret: string) => {
        this.directLine = createDirectLine({token: secret});
    }
    sendSurveyOptions = async () => {
        return new Promise(resolve => {
            this.directLine.postActivity({
                type: "event",
                value: 'init_survey',
                from: {
                    id: this.userID,
                    name: this.username,
                    properties: this.surveyOptions
                },
                name: "Init survey"
            })
                .subscribe((id: any) => {
                    resolve()
                });
        })
    }

    render() {
        return (
            <div>
                {this.props.surveyBotStore!.initialization ? <SpinOnCenter/> :
                    <ReactWebChat directLine={this.directLine}
                                  attachmentMiddleware={attachmentMiddleware(this.props)}
                                  store={store(this.props.surveyBotStore!)}
                                  userID={this.userID} username={this.username}/>
                }
            </div>
        )
    }
}


