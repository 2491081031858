import React from "react";
import {Button, Card, Col, Form, Input, message, Row} from "antd";
import {Auth} from "aws-amplify";
import {action, observable} from "mobx";
import {inject, observer} from "mobx-react";
import {RouteComponentProps} from 'react-router-dom';
import {InjectNames} from "../../../stores/initializeStores";
import {UserStore} from "../../../stores/user/userStore";
import {InvitationStore} from "../../../stores/invitationStore/invitationStore";

interface IRouteProps {
    invitation: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
    userStore?: UserStore
    invitationStore?: InvitationStore
}

@inject(InjectNames.userStore, InjectNames.invitationStore)
@observer
export class ProcessInvitation extends React.Component<IProps> {
    @observable loading = false;
    @observable password = "";
    @observable email = "";
    @observable error = "";

    private readonly invitation:string;

    constructor(props:IProps) {
        super(props);
        this.invitation = this.props.match.params.invitation;
        this.validateInvitationData()
    }

    @action clearError = () => {
        this.error = ""
    }
    @action validateInvitationData = async () => {
        try {
            this.email = await this.props.invitationStore!.getEmail(this.invitation)
        }catch (e) {
            this.error = `Unknown invitation: ${e.message}`
        }
    }

    handleSubmit = async (e: any) => {
        e.preventDefault();
        this.clearError()
        let invitation = this.props.match.params.invitation;
        let options: any = {
            password: this.password,
            username: this.email,
            attributes: {
                'custom:invitation_id': invitation,
                email: this.email,
            },
            validationData: [],
            clientMetadata: {},
        };
        try {
            this.loading = true
            await Auth.signUp(options)
            message.success({type: "success", content: "Success"})
            this.props.history.push('/')
        } catch (e) {
            this.loading = false
            this.error = e.message
        }
    };

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const span = {xs: 22, sm: 20, md: 12, lg: 8}
        const container = <Row style={{height: '100vh', justifyContent: 'center'}} type={'flex'}>
            <Col {...span} style={{alignSelf: 'center'}}>
                <Card title={'Confirm invitation'}>
                    <div style={{textAlign: 'center'}}>
                        <div style={{color: "red"}}>{this.error}</div>
                    </div>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item>
                            <Input type="text" value={this.email} readOnly={true}/>
                        </Form.Item>
                        <Form.Item>
                            <Input type="password" value={this.password}
                                   onChange={event => {
                                       this.password = event.target.value;
                                       this.clearError()
                                   }}
                                   placeholder={"Enter password"}/>
                        </Form.Item>
                        <Row type={'flex'} justify={'space-between'}>
                            <Button htmlType={'button'} type={"default"} onClick={() => this.props.history.push('/')}
                                    loading={this.loading}>Back</Button>
                            <Button htmlType={'submit'} type={"primary"}
                                    loading={this.loading}>Sign Up</Button>
                        </Row>
                    </Form>
                </Card>
            </Col>
        </Row>
        return (
            container
        );
    }
}
