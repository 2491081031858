import * as React from 'react';
import {UserStore} from "../../../stores/user/userStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import PractitionerDashboardScene from "./Practitioner/PractitionerDashboardScene";
import SubjectDashboardScene from "./Subject/SubjectDashboardScene";
import {RouteComponentProps} from "react-router";

interface IProps extends RouteComponentProps {
    userStore: UserStore;
}

@inject(InjectNames.userStore)
@observer
class DashboardScene extends React.Component<IProps> {
    public static readonly routePaths = {
        base: '/dashboard'
    }

    render() {
        let dashboard: React.ReactNode;
        if (this.props.userStore!.isPractitioner)
            dashboard = <PractitionerDashboardScene {...this.props}/>;
        else if (this.props.userStore!.isSubject)
            dashboard = <SubjectDashboardScene  {...this.props}/>;

        return (
            <React.Fragment>
                {dashboard}
            </React.Fragment>
        );
    }
}

export default DashboardScene;
