import React from "react";
import {Select} from "antd";
import {action, observable, reaction} from "mobx";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";
import {RoomsStore} from "../../stores/roomsStore/roomsStore";
import {errorUtils} from "../../services/utils/ErrorUtils";

interface IListItem {
    roomId: number;
    roomName?: string | undefined;
}


interface IProps {
    roomsStore?: RoomsStore;
    onChange?: (value?: number) => void;
    value?: number;
    disabled?: boolean;
    orgUnitId?: number;
    currentRoom?: IListItem;
    occupied?: boolean | undefined;
}

@inject(InjectNames.roomsStore)
@observer
export class RoomSelect extends React.Component<IProps, any> {
    constructor(props: any) {
        super(props);
        this.loadRoomsList()
        this.disposeReactionForOrgUnitId = reaction(
            () => this.props.orgUnitId,
            this.reactionForOrgUnitId
        )
        this.disposeReactionForCurrentRoom = reaction(
            () => this.props.currentRoom,
            this.reactionForCurrentRoom
        )
    }

    componentWillUnmount(): void {
        this.disposeReactionForOrgUnitId()
    }

    private readonly disposeReactionForOrgUnitId: () => any
    private readonly disposeReactionForCurrentRoom: () => any
    @observable search?: string
    @observable loading = false
    @observable roomsList: IListItem[] = []

    reactionForOrgUnitId = () => {
        if (!!this.props.onChange)
            this.props.onChange(undefined)
        this.roomsList = []
        this.loadRoomsList()
    }

    reactionForCurrentRoom = () => {
        if (!!this.props.currentRoom && !this.roomsList.find(x => x.roomId === this.props.currentRoom!.roomId))
            this.roomsList = [{...this.props.currentRoom!}].concat(this.roomsList.slice())
        else
            this.loadRoomsList()
    }
    @action loadRoomsList = async () => {
        await errorUtils.execute({context: this, loadingFieldName: 'loading'}, async () => {
            let roomsList: IListItem[] = (await this.props.roomsStore!.getRoomsList({
                occupied: this.props.occupied,
                offset: 0,
                rowCount: 1000,
                orgUnitId: this.props.orgUnitId,
                search: this.search
            }))
            if (!!this.props.currentRoom && !roomsList.find(x => x.roomId === this.props.currentRoom!.roomId))
                roomsList = [{...this.props.currentRoom!}].concat(roomsList)
            this.roomsList = roomsList
        })
    }

    render() {
        return <Select showSearch={true} allowClear={true} optionFilterProp={'children'}
                       style={{minWidth: 200, width: '100%'}}
                       disabled={this.props.disabled}
                       loading={this.loading}
                       placeholder={'Select room'}
                       value={this.loading ? undefined : this.props.value}
                       onSearch={value => {
                           this.search = value;
                           this.loadRoomsList()
                       }}
                       onChange={this.props.onChange}
        >
            {this.roomsList.map(room => <Select.Option key={room.roomId}
                                                       value={room.roomId}>{room.roomName}</Select.Option>)}
        </Select>
    }
}
