import React from "react";
import {Button, Modal, Table} from "antd";
import {ColumnProps} from "antd/lib/table";
import {inject, observer} from "mobx-react";
import {ITraceDto, TracesStore} from "../../stores/traces/tracesStore";
import {InjectNames} from "../../stores/initializeStores";
import {RouteComponentProps} from "react-router";
import {errorUtils} from "../../services/utils/ErrorUtils";
import {action, observable} from "mobx";
import {UserStore} from "../../stores/user/userStore";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";
import {ActionsButton} from "../Tables/ActionsButton";
import {Link} from "react-router-dom";
import {antdIconNames} from "../../constants/Icon";
import {AnnotationStore} from "../../stores/annotationStore/annotationStore";
import {AnnotationTraceList} from "../Annotations/AnnotationTraceList";
import {PersonOverviewScene} from "../../scenes/authorized/Traces/PersonOverviewScene";

interface IProps extends RouteComponentProps {
    tracesStore?: TracesStore
    annotationStore?: AnnotationStore
    userStore?: UserStore
    loading: boolean;
    comparable: boolean;
    isShowSubjectName: boolean;
    routeToTrace: (subjectId: number) => void;
}

@inject(InjectNames.tracesStore, InjectNames.userStore, InjectNames.annotationStore)
@observer
export class TracesTable extends React.Component<IProps> {
    @observable showAnnotationDialog = false;
    @observable deleteTraceLoading = false
    confirmDeleteTrace = (text: any, record: any) => {
        Modal.confirm({
            title: `Delete this trace?`,
            onOk: () => {
                this.handleDeleteTrace(record.traceId)
            },
            okType: "danger",
            okText: 'Yes',
            cancelText: 'No'
        })
    }

    handleDeleteTrace = async (traceId: number) => {
        await errorUtils.execute({context: this, loadingFieldName: 'deleteTraceLoading'}, async () => {
            await this.props.tracesStore!.deleteTrace(traceId)
        })
    }

    @action
    handleCompare = (record: ITraceDto) => {
        const isSelected = this.props.tracesStore!.selectedTraces.findIndex(value => value.traceId === record.traceId) !== -1;
        if (isSelected) {
            this.props.tracesStore!.selectedTraces = this.props.tracesStore!.selectedTraces.filter(x => x.traceId !== record.traceId);
        } else {
            this.props.tracesStore!.selectedTraces.push(record);
        }
    }

    handleRowClick = (record: ITraceDto) => {
        this.props.tracesStore!.selectedTraces = [record];
        this.props.routeToTrace(record.subjectId)
    }

    render() {
        const dataSource = this.props.tracesStore!.traces.slice().sort((a, b) => b.traceDateTime.getTime() - a.traceDateTime.getTime());

        const compare = (text: string, record: ITraceDto) => {
            const isSelected = this.props.tracesStore!.selectedTraces.findIndex(value => value.traceId === record.traceId) !== -1;
            const style: any = {type: 'link', size: 'large'}
            if (isSelected)
                return <Button icon={antdIconNames.MinusCircle} {...style} style={{color: 'black'}}
                               onClick={() => this.handleCompare(record)}/>
            else
                return <Button icon={antdIconNames.PlusCircle} {...style} onClick={() => this.handleCompare(record)}/>
        }

        const actions = [
            {
                title: 'Delete',
                action: this.confirmDeleteTrace,
            },
            {
                title: 'Annotations',
                action: async (_: any, record: ITraceDto) => {
                    await this.props.annotationStore!.load([record.traceId])
                    this.props.annotationStore!.selectedTraces = [record.traceId];
                    this.props.annotationStore!.selectAnnotation({trace: record.traceId});
                    this.showAnnotationDialog = true
                },
            },
        ];


        let columns: ColumnProps<ITraceDto>[] = [
            {
                title: 'Duration',
                dataIndex: 'traceDuration',
            },
            {
                title: 'Date',
                dataIndex: 'traceDateTime',
                render: (date: Date, record: ITraceDto) => dateTimeHelper.day_month_year_hour_min(date)
            },
            {
                title: 'Sleep Score',
                dataIndex: 'sleepScore',
            },
            {
                title: 'Compare',
                width: this.props.comparable ? '125px' : '0px',
                render: (text, record) => <span
                    onClick={event => event.stopPropagation()}>{compare(text, record)}</span>
            },
            {
                title: 'Actions',
                width: 150,
                render: (text: string, record: any) => (
                    <span onClick={event => event.stopPropagation()}><ActionsButton text={text} record={record}
                                                                                    actions={actions}/></span>),
            },
        ]
        if (this.props.isShowSubjectName)
            columns = [{
                title: 'Subject',
                dataIndex: 'subjectName',
                render: (text: string, record: ITraceDto) => <span onClick={event => event.stopPropagation()}> <Link
                    to={PersonOverviewScene.routePaths.subjectFormat(record.subjectId)}>{text}</Link></span>
            }, ...columns]

        return (<React.Fragment>
            <AnnotationTraceList annotations={this.props.annotationStore!.tracesList}
                                 visible={this.showAnnotationDialog} onClose={() => {
                this.showAnnotationDialog = false
            }}/>
            <span hidden={true}>Selected to compare: {this.props.tracesStore!.selectedTraces.length}</span>
            <Table dataSource={dataSource}
                   columns={columns}
                   onRowClick={this.handleRowClick}
                   loading={this.props.loading || this.deleteTraceLoading}
                   rowKey={(record, index) => `${record.traceId}`}
            />
        </React.Fragment>);
    }
}
