import {IDisposable, RootStore} from "../initializeStores";
import {action} from "mobx";
import {Gender, GenderSameAsBirth, GetAssignableToDeviceRequest, ProCvtClient} from "../../services/api/ProCvtClient";

export class PersonsStore implements IDisposable {
    constructor(pbmSleepClient: ProCvtClient, rootStore: RootStore) {
        this.client = pbmSleepClient!;
        this.rootStore = rootStore;
    }

    private readonly client: ProCvtClient;
    private readonly rootStore: RootStore;

    @action getAssignableToDevice: (input: IGetAssignableToDeviceDto) => Promise<IAssignableToDeviceDto[]> = async (input: IGetAssignableToDeviceDto) => {
        const response = await this.client.persons_GetAssignableToDevice(new GetAssignableToDeviceRequest(input));
        return !!response ? response : []
    }

    @action getPersonDetails: (personId: number) => Promise<IPersonDetailsDto> = async (personId: number) => {
        return (await this.client.persons_GetPersonDetails(personId))!;
    }

    dispose = async () => {
    };
}

export interface IGetAssignableToDeviceDto {
    search?: string | undefined;
    orgUnitId?: number | undefined;
    offset: number;
    rowCount: number;
}

export interface IAssignableToDeviceDto {
    personId: number;
    userName?: string | undefined;
}

export interface IPersonDetailsDto {
    modifiedTime?: Date | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    dateOfBirth?: Date | undefined;
    ethnicityId?: number | undefined;
    gender?: Gender | undefined;
    genderSameAsBirth?: GenderSameAsBirth | undefined;
    firstAddress?: string | undefined;
    secondAddress?: string | undefined;
    town?: string | undefined;
    region?: string | undefined;
    postcode?: string | undefined;
    countryId?: number | undefined;
}