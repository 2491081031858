import * as React from 'react';
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {RouteComponentProps} from "react-router";
import {DevicesStore} from "../../../stores/devicesStore/devicesStore";
import AssignDevice from "../../../components/Forms/Devices/AssignDevice";

interface IRoutePros {
    deviceName: string
    target?: 'room' | 'person'
    targetId?: string
}

interface IProps extends RouteComponentProps<IRoutePros> {
    devicesStore: DevicesStore;
}

@inject(InjectNames.devicesStore)
@observer
class AssignDeviceScene extends React.Component<IProps> {
    render() {
        return (
            <React.Fragment>
                <AssignDevice {...this.props} deviceName={this.props.match.params.deviceName}/>
            </React.Fragment>
        );
    }
}

export default AssignDeviceScene;
