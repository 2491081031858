import * as React from 'react';
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {RouteComponentProps} from "react-router";
import {DevicesStore} from "../../../stores/devicesStore/devicesStore";
import {DevicesTable} from "../../../components/Tables/DevicesTable";
import {Button, Row} from "antd";

interface IProps extends RouteComponentProps {
    devicesStore: DevicesStore;
}

@inject(InjectNames.devicesStore)
@observer
class DevicesScene extends React.Component<IProps> {
    render() {
        return (
            <React.Fragment>
                <Row style={{paddingBottom:15}}>
                    <Button onClick={() => this.props.history.push('/devices/register')}>Register Device</Button>
                </Row>
                <DevicesTable {...this.props}/>
            </React.Fragment>
        );
    }
}

export default DevicesScene;
