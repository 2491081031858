import './index.less';
import Logo from '../../../procvt_logo_white.png';

import * as React from 'react';

import {Avatar, Col, Layout, Menu} from 'antd';
import {IMenuItemInfo} from "../../../RouteSettings";
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import {ManualUploadStore} from "../../../stores/manualUpload/manualUploadStore";
import {InjectNames} from "../../../stores/initializeStores";


const {Sider} = Layout;

export interface ISiderMenuProps {
    manualUploadStore?: ManualUploadStore;
    collapsed: boolean;
    history: any;
    layouts: IMenuItemInfo[];
}
@inject(InjectNames.manualUploadStore)
@observer
export default class SiderMenu extends React.Component<ISiderMenuProps> {
    render() {
        const {collapsed} = this.props;
        const progress = null
        // const progress = <Dropdown
        //     trigger={['click']} overlayStyle={{position: 'fixed'}}
        //     overlay={<div style={{width: 300}}><ProcessingProgress history={this.props.history}/></div>}
        //     placement="topCenter"
        // >
        //     <div style={{width: '100%'}}>
        //         <Button type="link"
        //                 icon={!!this.props.manualUploadStore!.processingList?.length ? antdIconNames.Loading : antdIconNames.List} style={{color: 'white'}}
        //                 size={'large'}>{collapsed ? undefined : 'Processing'}</Button>
        //     </div>
        // </Dropdown>
        return (
            <Sider trigger={progress} className={'sidebar'} width={256} collapsible collapsed={collapsed}>
                {collapsed ? (
                    <Col style={{textAlign: 'center', marginTop: 15, marginBottom: 10}}
                         onClick={() => this.props.history.push('/')}>
                        <Avatar shape="square" style={{height: 20, width: 64}} src={Logo}/>
                    </Col>
                ) : (
                    <Col style={{textAlign: 'center', marginTop: 15, marginBottom: 10}}
                         onClick={() => this.props.history.push('/')}>
                        {/*1280/385 = 3.1*/}
                        <Avatar shape="square" style={{height: 41, width: 128}} src={Logo}/>
                    </Col>
                )}

                <Menu theme="dark" mode="inline" selectable={false}>
                    {this.props.layouts
                        .map((info: IMenuItemInfo, index: number) => {
                            return (
                                <Menu.Item key={info.path}>
                                    <Link to={info.path}>
                                        {info.icon}
                                        <span>{(info.title)}</span>
                                    </Link>
                                </Menu.Item>
                            );
                        })}
                </Menu>
            </Sider>
        );
    }
};
