import React from "react";
import RoomForm from "../../../components/Forms/Room/RoomForm";
import {observer} from "mobx-react";
import {RouteComponentProps} from "react-router-dom";
import {Card} from "antd";

interface IRouteProps {
    roomId?: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {

}

@observer
export class AddOrUpdateRoomScene extends React.Component<IProps, any> {
    get roomId() {
        if (this.props.match.params.roomId !== null && this.props.match.params.roomId !== undefined)
            return Number.parseInt(this.props.match.params.roomId)
        else return undefined;
    }

    render() {
        return <Card title={`${this.roomId === undefined ? 'Add' : 'Update'} room`}>
            <RoomForm roomId={this.roomId}  {...this.props}/>
        </Card>
    }
}
