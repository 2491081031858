import './index.less';
import * as React from 'react';
import {Avatar, Col, Dropdown, Icon, Menu, Row, Tag} from 'antd';
import {observer} from "mobx-react";

export interface IHeaderProps {
    collapsed?: any;
    toggle?: any;
    logOut: () => void;
    userLogo?: string | null;
    userName?: string;
    userType?: string;
}

@observer
export class Header extends React.Component<IHeaderProps> {
    private userDropdownMenu = (
        <Menu>
            <Menu.Item key="2" onClick={this.props.logOut}>
                <Icon type="logout"/>
                <span> {('Logout')}</span>
            </Menu.Item>
        </Menu>
    );

    render() {
        return (
            <Row className={'header-container'}>
                <Col style={{textAlign: 'left'}} span={4}>
                    <Icon className="trigger" type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'}
                          onClick={this.props.toggle}/>
                </Col>
                <Col style={{padding: '0px 15px 0px 15px', textAlign: 'right'}} span={20}>
                    <Tag>{this.props.userName}</Tag>
                    <Tag>{this.props.userType}</Tag>
                    <Dropdown overlay={this.userDropdownMenu} trigger={['click']}>
                        <Avatar style={{height: 50, width: 50}} shape="circle" alt={'profile'}
                                src={!this.props.userLogo ? undefined : this.props.userLogo!}/>
                    </Dropdown>
                </Col>
            </Row>
        );
    }
}

export default Header;
