import React from "react";
import {Card, Col, Form, Input, Row} from "antd";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {errorUtils, IHasValidationErrorsProperty} from "../../../services/utils/ErrorUtils";
import {FormComponentProps} from "antd/es/form";
import {RouteComponentProps} from "react-router-dom";
import {action, observable} from "mobx";
import {SubmitCancelFormRow} from "../../Buttons/SubmitCancelFormRow";
import rules from "./devices.rules";
import {DevicesStore, IAssignDeviceDto} from "../../../stores/devicesStore/devicesStore";
import {RoomSelect} from "../../Select/RoomSelect";
import {OrgUnitSelect} from "../../Select/OrgUnitSelect";
import {AssignableToDeviceSelect} from "../../Select/AssignableToDeviceSelect";
import {DeviceAssignedTo, DeviceStatus} from "../../../services/api/ProCvtClient";
import {RoomsStore} from "../../../stores/roomsStore/roomsStore";

interface IProps extends FormComponentProps, RouteComponentProps {
    devicesStore?: DevicesStore
    roomsStore?: RoomsStore
    deviceName: string,
}

@inject(InjectNames.devicesStore, InjectNames.roomsStore)
@observer
class AssignDevice extends React.Component<IProps, any> implements IHasValidationErrorsProperty {
    async componentDidMount() {
        try {
            const deviceStatus = await this.props.devicesStore!.getStatus({
                deviceName: this.props.deviceName
            })
            if (deviceStatus === DeviceStatus.ASSIGNED) {
                const deviceDetails = (await this.props.devicesStore!.getDeviceOwnership({
                    deviceName: this.props.deviceName,
                }))!

                if (deviceDetails.assignedTo === DeviceAssignedTo.ROOM)
                    this.roomDetails = {
                        roomId: deviceDetails.roomId!,
                        roomName: (await this.props.roomsStore!.getRoomDetails(deviceDetails.roomId!)).roomName
                    }

                this.orgUnitForRoomSelect = deviceDetails?.orgUnitId
                this.props.form.setFieldsValue({
                    deviceName: this.props.deviceName,
                    personId: deviceDetails?.assignedTo === DeviceAssignedTo.PERSON ? deviceDetails?.personId : undefined,
                    roomId: deviceDetails?.assignedTo === DeviceAssignedTo.ROOM ? deviceDetails?.roomId : undefined,
                })
            } else {
                this.props.form.setFieldsValue({
                    deviceName: this.props.deviceName,
                })
            }

        } finally {
            this.loading = false
        }
    }

    validationErrors = {
        deviceName: '',
        roomId: '',
        personId: '',
    }


    @observable loading = true
    @observable assigning = false
    @observable orgUnitForRoomSelect?: number = undefined
    @observable roomDetails?: { roomId: number, roomName?: string | undefined } = undefined


    @action resetFormAndRouteToMain = () => {
        this.resetFields()
        this.props.history.push('/devices')
    }

    @action handleSubmit = async (e: any) => {
        e.preventDefault();
        await this.props.form.validateFields(async (err: any, values: any) => {
            if (!err) {
                await errorUtils.execute({context: this, loadingFieldName: 'assigning'}, async () => {
                    const input: IAssignDeviceDto = {
                        deviceName: values.deviceName,
                        roomId: values.roomId,
                        personId: values.personId,
                    }
                    await this.props.devicesStore!.assignDevice(input)
                    this.resetFormAndRouteToMain()
                })
            }
        });
    }


    @action resetFields = () => {
        this.props.form.resetFields()
    }

    render() {
        const twoCol = (col1: any, col2: any) => {
            const span = 12;
            return <Row gutter={15}><Col span={span}>{col1}</Col><Col span={span}>{col2}</Col></Row>
        }
        const {getFieldDecorator} = this.props.form;
        return (
            <Card title={'Assign device'} loading={this.loading}>
                <Form onSubmit={this.handleSubmit}>
                    <React.Fragment>
                        {twoCol(<Form.Item
                                label={'Device id'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.deviceName, 'deviceName', this.props.form)}>
                                {getFieldDecorator('deviceName', {rules: rules.deviceName})(
                                    <Input placeholder={'000000000000000000000001'}
                                           onChange={event => errorUtils.resetValidationErrorState('deviceName', this)}/>)}
                            </Form.Item>,
                            undefined)}

                        {twoCol(<Form.Item
                                label={'Room'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.roomId, 'roomId', this.props.form)}>
                                {getFieldDecorator('roomId', {rules: []})(
                                    <RoomSelect
                                        currentRoom={!!this.roomDetails ? {
                                            roomId: this.roomDetails.roomId,
                                            roomName: this.roomDetails!.roomName
                                        } : undefined}
                                        disabled={false}
                                        occupied={undefined}
                                        orgUnitId={this.orgUnitForRoomSelect} onChange={id => {
                                        errorUtils.resetValidationErrorState('roomId', this)
                                        if (!!id)
                                            this.props.form.setFieldsValue({personId: undefined})

                                    }}/>)}
                            </Form.Item>,
                            <Form.Item
                                label={'Org unit'}>
                                <OrgUnitSelect value={this.orgUnitForRoomSelect}
                                               onChange={(value) => {
                                                   this.orgUnitForRoomSelect = value
                                               }}/>
                            </Form.Item>)}
                        {twoCol(
                            <Form.Item
                                label={'Family member'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.personId, 'personId', this.props.form)}>
                                {getFieldDecorator('personId', {})(
                                    <AssignableToDeviceSelect
                                        orgUnitId={this.orgUnitForRoomSelect}
                                        includeEmptyPersonId={false}
                                        onChange={id => {
                                            errorUtils.resetValidationErrorState('personId', this)
                                            if (!!id)
                                                this.props.form.setFieldsValue({roomId: undefined})
                                        }}/>)}
                            </Form.Item>,
                            undefined
                        )}
                    </React.Fragment>
                    <SubmitCancelFormRow loading={this.assigning} okTitle={'Assign'}
                                         onCancel={this.resetFormAndRouteToMain}/>
                </Form>
            </Card>
        );
    }
}


export default Form.create<IProps>()(AssignDevice);
