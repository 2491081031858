import * as React from 'react';
import {UserStore} from "../../../stores/user/userStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {RouteComponentProps} from "react-router";
import {AuthStore} from "../../../stores/auth/authStore";
import RealtimeChart from "../../../components/Traces/RealTimeChart";


interface IRouteProps {
    deviceId: string
}

interface IProps extends RouteComponentProps<IRouteProps> {
    userStore: UserStore;
    authStore: AuthStore;
}

@inject(InjectNames.userStore, InjectNames.authStore)
@observer
class RealtimeChartScene extends React.Component<IProps> {

    render() {
        return <RealtimeChart {...this.props} deviceId={this.props.match.params.deviceId}/>
    }
}

export default RealtimeChartScene;
