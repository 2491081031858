import React from "react";
import {Card, Col, Form, Input, Row} from "antd";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {errorUtils, IHasValidationErrorsProperty} from "../../../services/utils/ErrorUtils";
import {FormComponentProps} from "antd/es/form";
import {RouteComponentProps} from "react-router-dom";
import {action, observable} from "mobx";
import {ResidentsStore} from "../../../stores/residents/residentsStore";
import {UserStore} from "../../../stores/user/userStore";
import {SubmitCancelFormRow} from "../../Buttons/SubmitCancelFormRow";
import rules from "./devices.rules";
import {DevicesStore, IRegisterDeviceDto} from "../../../stores/devicesStore/devicesStore";

interface IProps extends FormComponentProps, RouteComponentProps {
    residentsStore?: ResidentsStore
    userStore?: UserStore
    devicesStore?: DevicesStore
    onRegistered: (deviceName: string) => void;
    onCanceled: () => void;
}

@inject(InjectNames.residentsStore, InjectNames.userStore, InjectNames.devicesStore)
@observer
class RegisterDevice extends React.Component<IProps, any> implements IHasValidationErrorsProperty {

    validationErrors = {
        deviceName: '',
        fwVersion: '',
        hwVersion: '',
    }

    @observable loading = false


    @action onCanceled = () => {
        this.resetFields()
        this.props.onCanceled()
    }

    @action onRegistered = (deviceName: string) => {
        this.resetFields()
        this.props.onRegistered(deviceName)
    }

    @action handleSubmit = async (e: any) => {
        e.preventDefault();
        await this.props.form.validateFields(async (err: any, values: any) => {
            if (!err) {
                await errorUtils.execute({context: this, loadingFieldName: 'loading'}, async () => {
                    const input: IRegisterDeviceDto = {
                        deviceName: values.deviceName,
                        fwVersion: values.fwVersion,
                        hwVersion: values.hwVersion,
                    }

                    await this.props.devicesStore!.registerDevice(input)
                    this.onRegistered(input.deviceName!)
                })
            }
        });
    }


    @action resetFields = () => {
        this.props.form.resetFields()
    }

    render() {
        const twoCol = (col1: any, col2: any) => {
            const span = 12;
            return <Row gutter={15}><Col span={span}>{col1}</Col><Col span={span}>{col2}</Col></Row>
        }
        const {getFieldDecorator} = this.props.form;
        return (
            <Card title={'Register device'}>
                <Form onSubmit={this.handleSubmit}>
                    <React.Fragment>
                        {twoCol(<Form.Item
                                label={'Device id'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.deviceName, 'deviceName', this.props.form)}>
                                {getFieldDecorator('deviceName', {rules: rules.deviceName})(
                                    <Input placeholder={'000000000000000000000001'}
                                           onChange={event => errorUtils.resetValidationErrorState('deviceName', this)}/>)}
                            </Form.Item>,
                            undefined)}

                        {twoCol(<Form.Item
                                label={'Firmware version'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.fwVersion, 'fwVersion', this.props.form)}>
                                {getFieldDecorator('fwVersion', {rules: rules.fwVersion})(
                                    <Input placeholder={'1.0.0'}
                                           onChange={event => errorUtils.resetValidationErrorState('fwVersion', this)}/>)}
                            </Form.Item>,
                            <Form.Item
                                label={'Hardware version'} {...errorUtils.bindCustomErrorToAntdFormItem(this.validationErrors.hwVersion, 'hwVersion', this.props.form)}>
                                {getFieldDecorator('hwVersion', {rules: rules.hwVersion})(
                                    <Input placeholder={'1.0.0'}
                                           onChange={event => errorUtils.resetValidationErrorState('hwVersion', this)}/>)}
                            </Form.Item>)}


                    </React.Fragment>
                    <SubmitCancelFormRow loading={this.loading} okTitle={'Register'}
                                         onCancel={this.onCanceled}/>
                </Form>
            </Card>
        );
    }
}


export default Form.create<IProps>()(RegisterDevice);
