import {IDisposable, RootStore} from "../initializeStores";
import {
    AddRoomRequest, GetRoomsListRequest,
    ProCvtClient, UpdateRoomRequest,
} from "../../services/api/ProCvtClient";
import {action} from "mobx";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";

export class RoomsStore implements IDisposable {
    constructor(pbmSleepClient: ProCvtClient, rootStore: RootStore) {
        this.pbmSleepClient = pbmSleepClient!;
        this.rootStore = rootStore;
    }

    private readonly pbmSleepClient: ProCvtClient;
    private readonly rootStore: RootStore;

    @action
    addRoom: (input: IAddRoomInput) => Promise<number> = async (input: IAddRoomInput) => {
        return (await this.pbmSleepClient.rooms_AddRoom(new AddRoomRequest(input)))!
    }

    @action
    updateRoom: (input: IUpdateRoomInput) => Promise<void> = async (input: IUpdateRoomInput) => {
        const request = new UpdateRoomRequest(input);
        dateTimeHelper.overrideToISOStringWithTimezone(request.modifiedTime)
        return (await this.pbmSleepClient.rooms_UpdateRoom(request))!
    }

    @action
    getRoomDetails: (roomId: number) => Promise<IRoomDetailsDto> = async (roomId: number) => {
        return (await this.pbmSleepClient.rooms_GetRoomDetails(roomId))!
    }

    @action
    getRoomsList: (input: IGetRoomsListInput) => Promise<IRoomDto[]> = async (input: IGetRoomsListInput) => {
        return (await this.pbmSleepClient.rooms_GetRoomsList(new GetRoomsListRequest(input)))!
    }

    dispose = () => {
        return Promise.resolve(undefined);
    }
}

export interface IAddRoomInput {
    orgUnitId?: number | undefined;
    roomName?: string | undefined;
    roomLocation?: string | undefined;
}

export interface IUpdateRoomInput {
    roomId: number;
    roomName?: string | undefined;
    roomLocation?: string | undefined;
    modifiedTime?: Date | undefined;
}

export interface IRoomDetailsDto {
    roomName?: string | undefined;
    roomLocation?: string | undefined;
    modifiedTime?: Date | undefined;
}

export interface IGetRoomsListInput {
    orgUnitId?: number | undefined;
    offset: number;
    rowCount: number;
    occupied?: boolean | undefined;
    search?: string | undefined;
}

export interface IRoomDto {
    roomId: number;
    roomName?: string | undefined;
    roomLocation?: string | undefined;
    occupied: boolean;
}
