import React from "react";
import {Select} from "antd";
import {observer} from "mobx-react";
import {AccountStatus} from "../../services/api/ProCvtClient";

interface IProps {
    onChange?: (value: AccountStatus, option: React.ReactElement<any> | React.ReactElement<any>[]) => void;
    value?: AccountStatus;
}

@observer
export class AccountStatusSelect extends React.Component<IProps, any> {
    render() {
        return <Select showSearch={true} allowClear={true} optionFilterProp={'children'}
                       style={{minWidth: 200, width: '100%'}}
                       placeholder={'Account status'} value={this.props.value === AccountStatus.NA ? undefined : this.props.value}
                       onChange={this.props.onChange}
        >
            <Select.Option value={AccountStatus.OPEN}>OPEN</Select.Option>
            <Select.Option value={AccountStatus.LOCKED}>LOCKED</Select.Option>
            <Select.Option value={AccountStatus.EXPIRED}>EXPIRED</Select.Option>
        </Select>
    }
}
