import {IDisposable, RootStore} from "../initializeStores";
import {
    InviteRequest,
    ProCvtClient,
    UserType
} from "../../services/api/ProCvtClient";
import {action} from "mobx";

export class InvitationStore implements IDisposable {
    constructor(pbmSleepClient: ProCvtClient, rootStore: RootStore) {
        this.pbmSleepClient = pbmSleepClient!;
        this.rootStore = rootStore;
    }

    private readonly pbmSleepClient: ProCvtClient;
    private readonly rootStore: RootStore;

    @action
    getEmail: (invitationId: string) => Promise<string> = async (invitationId: string) => {
        return (await this.pbmSleepClient.invitations_GetEmail(invitationId))!
    }

    @action
    invite: (input:IInviteInput) => Promise<string> = async (input:IInviteInput) => {
        return (await this.pbmSleepClient.invitations_Invite(new InviteRequest(input)))!
    }

    dispose = () => {
        return Promise.resolve(undefined);
    }
}

export interface IInviteInput {
    email?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    userType: UserType;
    orgUnitId?: number | undefined;
    householdId?: number | undefined;
    monitorUser: boolean;
    maxFamilyMembers?: number | undefined;
}
