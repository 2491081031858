import {Modal} from "antd";
import {getDynamic, setDynamic} from "./DynamicField";

export interface IHasValidationErrorsProperty {
    validationErrors: {}
}

export interface ITypescriptClientException {
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any; },
    result?: any
}

export interface ErrorBase {
    errorCode: number;
    failReason: string;
    exceptionType: string;
    success: boolean;
}

export interface ValidationError extends ErrorBase {
    validationErrors: string[];
}

export class IExecuteOptions {
    context: IHasValidationErrorsProperty | any;
    loadingFieldName?: string;
    showSuccessAlert?: boolean;
    showErrorAlert?: boolean;
}

class ErrorUtils {
    async execute(options: IExecuteOptions, action: () => Promise<any>) {
        options.context.validationErrors = {};
        this.executeLoading(options, "start");
        try {
            const result = await action();
            if (options.showSuccessAlert)
                Modal.success({title: 'Success'})
            return result;
        } catch (ex) {
            if (options.showErrorAlert)
                Modal.error({title: 'Error', content: ex.message})
            errorUtils.handleTypescriptClientException(options.context, ex);
        } finally {
            this.executeLoading(options, "end");
        }
    }

    private executeLoading(options: IExecuteOptions, step: 'start' | 'end') {
        if (!options.loadingFieldName)
            return;
        const fieldValue = getDynamic(options.context, options.loadingFieldName);
        let newValue = undefined;
        if (step === "start") {
            if (typeof fieldValue === "boolean") {
                newValue = true;
            } else if (typeof fieldValue === "number") {

                newValue = (fieldValue as number) + 1;
            }
        } else if (step === "end") {
            if (typeof fieldValue === "boolean") {
                newValue = false;
            } else if (typeof fieldValue === "number") {
                newValue = (fieldValue as number) - 1;
            }
        }
        if (newValue !== undefined)
            setDynamic(options.context, options.loadingFieldName, newValue)

    }

    private handleTypescriptClientException(stateContext: any, typescriptClientException: ITypescriptClientException) {
        if (!!typescriptClientException.response) {
            let errorBase = this.parseErrorResponse(typescriptClientException);
            switch (errorBase.exceptionType) {
                case 'ValidationError':
                    const validationError = errorBase as ValidationError;
                    this.setValidationErrorMessageToState(stateContext, validationError);
                    break;
                default:
                    break;
            }
        } else {
        }
    }

    private parseErrorResponse(typescriptClientException: ITypescriptClientException): ErrorBase {
        let response: any = typescriptClientException.response;
        if (response.data !== undefined) {
            return response.data;
        } else {
            return JSON.parse(response);
        }
    }

    private setValidationErrorMessageToState(stateContext: IHasValidationErrorsProperty, validationError: ValidationError) {
        let parseResult = this.parseValidationError(validationError);
        console.warn('setValidationErrorMessageToState');
        console.warn(parseResult);
        stateContext.validationErrors = parseResult.validationErrors

    }

    private parseValidationError(validationError: ValidationError): IHasValidationErrorsProperty {
        console.warn('validationError.validationErrors');
        console.log(validationError.validationErrors);
        let object = {validationErrors: {}};
        validationError.validationErrors.forEach((item, index) => {
            let errorKeyValue = item.split(/: /);
            let name = errorKeyValue[0].replace(/^\w/, c => c.toLowerCase());
            if (name.includes('.'))
                name = name.split('').map((value1, index1, array1) => {
                    if (index1 - 1 < 0)
                        return value1;
                    if (array1[index1 - 1] === '.')
                        return value1.toLowerCase();
                    return value1;
                }).join('');
            let value = errorKeyValue[1];
            setDynamic(object.validationErrors, name, value);
        });
        return object;
    }

    resetValidationErrorState(field: string, stateContext: IHasValidationErrorsProperty) {
        let validationErrors = stateContext.validationErrors;
        setDynamic(validationErrors, field, '');
        stateContext.validationErrors = validationErrors;
    }

    bindCustomErrorToAntdFormItem(customErrorMsg: any, formField: string, formContext: any): any {

        return {
            help: !!formContext.getFieldError(formField) ? formContext.getFieldError(formField) : customErrorMsg,
            validateStatus: !!customErrorMsg || formContext.getFieldError(formField) ? 'error' : ''
        }
    }
}

const errorUtils = new ErrorUtils();

export {errorUtils};
