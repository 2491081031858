import React from "react";
import {Select} from "antd";
import {action, observable} from "mobx";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";
import {IEthnicityDto, ResidentsStore} from "../../stores/residents/residentsStore";
import {errorUtils} from "../../services/utils/ErrorUtils";

interface IProps {
    residentsStore?: ResidentsStore;
    onChange?: (value: number, option: React.ReactElement<any> | React.ReactElement<any>[]) => void;
    value?: number;
}

@inject(InjectNames.residentsStore)
@observer
export class EthniticySelect extends React.Component<IProps, any> {
    constructor(props: any) {
        super(props);
        this.loadEthnicityList()
    }

    @observable loading = false
    @observable ethnicityList: IEthnicityDto[] = []

    @action loadEthnicityList = async () => await errorUtils.execute({context: this, loadingFieldName: 'loading'},
        async () => {
            this.ethnicityList = await this.props.residentsStore!.getEthnicityList()
        })

    render() {
        return <Select showSearch={true} allowClear={true} optionFilterProp={'children'}
                       loading={this.loading}
                       style={{minWidth: 200, width: '100%'}}
                       placeholder={'Ethnicity'}
                       value={this.loading ? undefined : this.props.value}
                       onChange={this.props.onChange}
        >
            {this.ethnicityList.map(ethnicity => (
                <Select.Option key={ethnicity.ethnicityId}
                               value={ethnicity.ethnicityId}>{ethnicity.ethnicity}</Select.Option>
            ))}
        </Select>
    }
}
