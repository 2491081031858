import {
    GetFullTraceSeriesOption,
    GetOverTimeSeriesRequest, GetTraceEcgOption,
    GetTracesRequest,
    ProCvtClient,
    TraceType
} from "../../services/api/ProCvtClient";
import {action, observable} from "mobx";
import {IDisposable, RootStore} from "../initializeStores";
import {ITraceAnnotationDto} from "../annotationStore/annotationStore";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";

export class TracesStore implements IDisposable {
    constructor(pbmSleepClient: ProCvtClient, rootStore: RootStore) {
        this.client = pbmSleepClient!;
        this.rootStore = rootStore;
    }

    private readonly client: ProCvtClient;
    private readonly rootStore: RootStore;

    @observable traces: ITraceDto[] = [];
    @observable lastLoadTracesInput?: IGetTracesInput;
    @observable selectedTraces: ITraceDto[] = [];
    @observable fullTraceSeriesDtoList: IFullTraceSeriesDto[] = [];

    @action
    loadTraces: (input: IGetTracesInput) => Promise<void> = async (input: IGetTracesInput) => {
        this.lastLoadTracesInput = input;
        this.traces = [];
        this.traces = (await this.client.diagnosis_GetTraces(new GetTracesRequest(input)))!
            .sort((a, b) => a.traceDateTime.getTime() - b.traceDateTime.getTime())
    };

    @action
    getTraceById: (traceId: number) => Promise<ITraceDto> = async (traceId: number) => {
        return ((await this.client.diagnosis_GetTraceById(traceId))!)
    };

    @action
    getOtSeries: (input: IGetOverTimeSeriesInput) => Promise<IOverTimeTracesDto[]> = async (input: IGetOverTimeSeriesInput) => {
        let request = new GetOverTimeSeriesRequest({
            personId: input.subjectId,
            traceType: input.traceType,
            dateFrom: input.dateFrom,
            dateTo: input.dateTo
        })
        dateTimeHelper.overrideToISOStringWithTimezone(request.dateFrom)
        dateTimeHelper.overrideToISOStringWithTimezone(request.dateTo)
        return (await this.client.diagnosis_GetOverTimeSeries(request))!
            .sort((a, b) => a.traceDateTime.getTime() - b.traceDateTime.getTime())
            .map(x => ({...x, personName: x.personName!}))
    };

    @action
    deleteTrace = async (traceId: number) => {
        await this.client.diagnosis_DeleteTrace(traceId)
        this.traces = this.traces.filter(x => x.traceId !== traceId)
        this.selectedTraces = this.selectedTraces.filter(x => x.traceId !== traceId)
    };
    @action
    moveSelectedToTraces = () => {
        this.traces = this.selectedTraces
    };
    @action
    clearSelected = () => {
        this.selectedTraces = []
    };
    @action
    clearFullTraceSeries = () => {
        this.fullTraceSeriesDtoList = []
    };

    @action
    loadFullTraceSeriesModel: (options: IGetFullTraceSeriesInput[]) => Promise<void> = async (options: IGetFullTraceSeriesInput[]) => {
        const duplicates = this.fullTraceSeriesDtoList.slice().filter(x => options.some(option => x.traceId === option.traceId))!
        this.fullTraceSeriesDtoList = []
        let loaded = (await Promise.all(
                options
                    .filter(option => !duplicates.some(x => x.option.traceId === option.traceId))
                    .map(async option => {
                        const responses = await Promise.all([this.client.diagnosis_GetFullTraceSeriesModel(new GetFullTraceSeriesOption(option)), this.client.diagnosis_GetTraceEcg(new GetTraceEcgOption(option))])
                        const trace = responses[0]!
                        const ecg = responses[1]!
                        return {
                            ...trace,
                            ...ecg,
                            option: option
                        }
                    }))
        )
        if(!loaded.length)
            loaded = []
        this.fullTraceSeriesDtoList = [...duplicates, ...(loaded!)]
    };

    @action
    dispose = async () => {
        this.traces = [];
        this.lastLoadTracesInput = undefined;
        this.selectedTraces = [];
        this.fullTraceSeriesDtoList = [];
    };
}

export interface IGetFullTraceSeriesInput {
    traceId: number;
}

export interface IFullTraceSeriesDto {
    data?: ISeriesPointDto[] | undefined;
    ecgs?: IEcgDto[] | undefined;
    traceId: number;
    subjectId: number;
    subjectName?: string | undefined;
    traceDate: Date;
    traceDuration?: string | undefined;
    traceType: TraceType;
    fatigueDetails?: IFatigueDetailsDto | undefined;
    annotations?: ITraceAnnotationDto[] | undefined;
    option: IGetFullTraceSeriesInput;
    hourBreakdown?: IHourBreakdownDto[] | undefined;
}

export interface IEcgDto {
    startSequence: number;
    endSequence: number;
    samplesPerSecond: number;
    ecgValues?: IEcgValueDto[] | undefined;
}
export interface IEcgValueDto  {
    sequence: number;
    value: number;
}

export interface ISeriesPointDto {
    sequence: number;
    cvt?: number | undefined;
    heartRate?: number | undefined;
    respirationRate?: number | undefined;
    temperature?: number | undefined;
    markNumber?: number | undefined;
    validRrIntCnt?: number | undefined;
}


export interface IGetTracesInput {
    subjectId?: number | undefined;
    traceType?: TraceType | undefined;
    dateFrom?: Date | undefined;
    dateTo?: Date | undefined;
    search?: string | undefined;
    offset: number;
    rowCount: number;
}

export interface ITraceDto {
    traceId: number;
    subjectId: number;
    subjectName?: string | undefined;
    traceType: TraceType;
    traceDateTime: Date;
    traceDuration?: string | undefined;
    sleepScore: number;
    avgCvt: number;
}

export interface IGetBestAndWorstSleepTracesInput {
    subjectId: number;
    traceType: TraceType;
}

export interface IFatigueDetailsDto {
    sleepTraceId: number;
    fatigueTraceId: number;
    startTimeInMsOut: number;
    endTimeInMs: number;
    cvt: number;
    confidence: number;
}

export interface IGetOverTimeSeriesInput {
    traceType: TraceType;
    subjectId: number;
    dateFrom: Date;
    dateTo: Date;
}

export interface IOverTimeTracesDto {
    traceId: number;
    personName: string;
    traceDateTime: Date;
    sleepScore: number;
    avgCvt: number;
    avgHeartRate: number;
    avgRespirationRate: number;
    avgTemperature: number;
    sleepTraceId?: number | undefined;
}

export interface ITraceMarkInfo {
    marks: ITraceMarkDto[];
    traceId: number;
}

export interface ITraceMarkDto {
    markNumber: number;
    sequence: number;
}

export interface IHourBreakdownDto {
    hour?: string | undefined;
    sumCvt: number;
    heartbeatsCount: number;
    avgCvt: number;
}

export interface IBestAndWorstDuplicateDto {
    duplicateForTraceId: number;
    traceType: TraceType;
}

export interface IRespirationRateInfo {
    respirationRate: IRespirationRateDto[];
    traceId: number;
    traceDateTime: Date;
}

export interface IRespirationRateDto {
    sequence: number;
    ratePerMinute: number;
}

export interface IGetTraceRrIntervalsInput {
    traceId: number;
    startMs?: number | undefined;
    endMs?: number | undefined;
}

export interface IDeviceHeartbeatDto {
    sequence: number;
    rrInt: number;
}
