import React from "react";
import {RouteComponentProps} from "react-router";
import {UserStore} from "../../stores/user/userStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";
import {Button, Col, Input, Modal, Row, Table, Tag} from "antd";
import {ColumnProps} from "antd/lib/table/interface";
import {action, observable} from "mobx";
import {AccountStatus, QuestionCategory, StaffSearchType} from "../../services/api/ProCvtClient";
import {errorUtils} from "../../services/utils/ErrorUtils";
import {ActionsButton} from "./ActionsButton";
import {OrgUnitSelect} from "../Select/OrgUnitSelect";
import {IResidentDto, ResidentsStore} from "../../stores/residents/residentsStore";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";
import {Link} from "react-router-dom";
import {enumHelper} from "../../services/utils/EnumHelper";

interface IProps extends RouteComponentProps {
    userStore?: UserStore
    residentsStore?: ResidentsStore
}

@inject(InjectNames.userStore, InjectNames.residentsStore)
@observer
export class ResidentsTable extends React.Component<IProps> {
    @observable staffSearchType: StaffSearchType = StaffSearchType.Both;
    @observable orgUnitId?: number = undefined;
    @observable search?: string;
    @observable loading: boolean = false;

    async componentDidMount() {
        await this.loadTable();
    }
    componentWillUnmount(): void {
        this.props.residentsStore!.residentsList = []
    }

    loadTable = async () => {
        await errorUtils.execute({context: this, loadingFieldName: 'loading'}, async () => {
            await this.props.residentsStore?.loadResidents({
                offset: 0,
                /**Fix.Hard Need use pagination*/
                rowCount: 1000,
                search: this.search,
                orgUnitId: this.orgUnitId
            })
        })
    };

    @action
    handleChangeOrgUnit = (value: number) => {
        this.orgUnitId = value
        this.loadTable()
    }

    @action
    handleSearch = (e: any) => {
        this.search = e.target.value;
        this.loadTable()
    };

    render() {
        const actions = [
            {
                title: 'Edit',
                action: (text: string, record: IResidentDto) => {
                    if(record.accountStatus === AccountStatus.PENDING)
                        Modal.info({content: "Unable edit account if status is Pending"})
                    else this.props.history.push({pathname: `/residents/edit/${record.personId}`})
                },
            },
        ];

        const columns: ColumnProps<IResidentDto>[] = [
            {title: 'Room', width: 200, dataIndex: 'roomName'},
            {title: 'Name', width: 200, dataIndex: 'userName'},
            {title: 'Email', width: 200, dataIndex: 'email'},
            {
                title: 'Last trace', width: 200, dataIndex: 'lastTraceTime',
                render: (value: Date, record: IResidentDto) => (dateTimeHelper.day_month_year(value)),
            },
            {
                title: 'Lifestyle', width: 200, dataIndex: 'lifestyleAnswered',
                render: (value: boolean, record: IResidentDto) => (value ? <Tag>Answered</Tag> :<Link to={`/survey-bot/${record.personId}/${QuestionCategory.LIFESTYLE}`}><Button type={"link"} size={"small"}>To survey</Button></Link>)
            },
            {
                title: 'Health', width: 200, dataIndex: 'healthAnswered',
                render: (value: boolean, record: IResidentDto) => (value ? <Tag>Answered</Tag> :<Link to={`/survey-bot/${record.personId}/${QuestionCategory.HEALTH}`}><Button type={"link"} size={"small"}>To survey</Button></Link>)
            },
            {title: 'Account', width: 200, dataIndex: 'accountStatus', render: (text, record) => enumHelper.getFriendlyName(text)},
            {
                title: 'Actions',
                width: 150,
                render: (text: string, record: IResidentDto) => (<ActionsButton text={text} record={record} actions={actions}/>),
            },
        ];

        const search = <Row type={"flex"} justify={"end"} gutter={15}>
            <Col>
                <Input placeholder="Search..." style={{marginBottom: '15px', width: '300px'}}
                       value={this.search}
                       onChange={this.handleSearch}/>
            </Col>
            {this.props.userStore!.isSuperUser &&
            <Col>
                <OrgUnitSelect value={this.orgUnitId} onChange={this.handleChangeOrgUnit}/>
            </Col>
            }
        </Row>
        return (
            <React.Fragment>
                {search}
                <Table dataSource={this.props.residentsStore!.residentsList} columns={columns}
                       rowKey={record => `${record.personId}_${record.userName}`} loading={this.loading}/>
            </React.Fragment>
        );
    }
}
