import React from "react";
import {RouteComponentProps} from 'react-router-dom';
import UpdateStaff from "../../../components/Forms/Users/UpdateStaff";

interface IRouteProps {
    personId: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {

}

export class UpdateStaffScene extends React.Component<IProps, any> {
    render() {
        return <UpdateStaff {...this.props} personId={Number.parseInt(this.props.match.params.personId!)}/>
    }
}
