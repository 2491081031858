import {IFullTraceSeriesDto, IOverTimeTracesDto} from "../../stores/traces/tracesStore";
import {
    CompareTimeType, IChartLineMark,
    IChartPointMark,
    IChartSeriesInfo,
    IChartValue,
    ILegendOptions,
    IToolTipParams
} from "../../components/Charts/IChartSeriesInfo";
import {dateTimeHelper} from "./DateTimeHelper";
import {TraceType} from "../api/ProCvtClient";
import {colorHelper} from "./ColorHelper";
import {ITraceAnnotationsDto} from "../../stores/annotationStore/annotationStore";
import moment from "moment-timezone";

class ChartHelper {

    tooltipDateFormatterTimeMode = (compareTimeType: CompareTimeType, ignoreDayToggle: boolean) => {
        switch (compareTimeType) {
            case "Actual":
                return ignoreDayToggle
                    ? (chartValue: IChartValue) => dateTimeHelper.hour_min_sec_a(chartValue.x as Date)
                    : (chartValue: IChartValue) => dateTimeHelper.day_month_year_hour_min_sec(chartValue.x as Date)
            case "Elapsed":
                return (chartValue: IChartValue) => dateTimeHelper.msToDurationFormat(chartValue.x as number);
            default:
                throw new Error('Unknown compareTimeType')
        }
    }

    legendFormatterEmpty: (chartInfoList: IChartSeriesInfo[]) => ILegendOptions[] = (chartInfoList: IChartSeriesInfo[]) => {
        return []
    }
    legendFormatterCompareMode: (chartInfoList: IChartSeriesInfoCompareMode[], selectedSeriesId: string | undefined) => ILegendOptions[] = (chartInfoList: IChartSeriesInfoCompareMode[], selectedSeriesId: string | undefined) => {
        const findItemLegendGroup = (traceId: number, groupedByLegendId: ILegendOptions[]) => groupedByLegendId.find(x => x.legendId === traceId.toString())

        let groupedByLegendId: ILegendOptions[] = []
        chartInfoList.forEach((dataItem) => {
            const traceId = dataItem.traceId ?? 'default'

            let itemLegendGroup = findItemLegendGroup(traceId, groupedByLegendId)
            if (!itemLegendGroup) {
                groupedByLegendId.push({
                    legendId: traceId.toString(),
                    legends: [],
                    container: {
                        title: moment(dataItem.traceDate).format('D MMM'),
                        subTitle: moment(dataItem.traceDate).format('hh.mm A')
                    }
                })
                itemLegendGroup = findItemLegendGroup(traceId, groupedByLegendId)
            }
            itemLegendGroup!.legends.push({
                seriesId: dataItem.seriesId,
                legendInfo: {
                    color: selectedSeriesId === undefined
                        ? dataItem.color
                        : selectedSeriesId === dataItem.seriesId ? dataItem.color : dataItem.colorOpacity,
                    name: dataItem.traceSeriesType?.toUpperCase()
                }
            })
        })

        return groupedByLegendId
    }

    tooltipFormatterDefault = (values: IToolTipParams[], tooltipDateFormatter: (chartValue: IChartValue) => string, valueRounding?: number) => {
        if (!valueRounding)
            valueRounding = 2

        const rounding = (valueY?: number) => valueY?.toFixed(valueRounding) ?? 0.0

        let groupedSubject: { subjectName: string, byTraceDate: { traceDate: Date, sensors: { chartValue: IChartValue, formatted: any }[] }[] }[] = []

        values
            .sort((a, b) => (a.json as IBaseBatchPointDataJson).traceId - (b.json as IBaseBatchPointDataJson).traceId)
            .forEach(value => {
                const json: IBaseBatchPointDataJson = value.json;
                if (!groupedSubject.some(x => x.subjectName === json.subjectName))
                    groupedSubject.push({subjectName: json.subjectName, byTraceDate: []})

                let bySubject = groupedSubject.find(x => x.subjectName === json.subjectName)!

                if (!bySubject.byTraceDate.some(x => x.traceDate.getTime() === json.traceDate.getTime()))
                    bySubject.byTraceDate.push({traceDate: json.traceDate, sensors: []})

                let bySubjectAndTraceDate = bySubject.byTraceDate.find(x => x.traceDate.getTime() === json.traceDate.getTime())!

                bySubjectAndTraceDate.sensors.push({
                    chartValue: value,
                    formatted: `<p>${value.params.marker} ${json.type.toUpperCase()} ${rounding(value.y)}</p>`
                })
            })
        let tooltip = ''

        groupedSubject.forEach(bySubjectItem => {
            tooltip += `<p>${bySubjectItem.subjectName}</p>`

            bySubjectItem.byTraceDate.forEach(byTraceDateItem => {
                let dateWasSet = false

                byTraceDateItem.sensors.forEach(sensor => {
                    if (!dateWasSet) {
                        dateWasSet = true
                        tooltip += `<p>Date time ${tooltipDateFormatter(sensor.chartValue)}</p>`
                    }
                    tooltip += sensor.formatted
                })
            })
        })

        return `<div style="text-align: left">${tooltip}</div>`;
    }

    tooltipFormatterRealTimeDefault = (values: IToolTipParams[], tooltipDateFormatter: (chartValue: IChartValue) => string) => {
        let tooltip = ''
        let dateWasSet = false;
        values
            .forEach(value => {
                if (!dateWasSet) {
                    dateWasSet = true
                    tooltip += `<p>Date time ${tooltipDateFormatter(value)}</p>`
                }
                tooltip += `<p>${value.params.marker} ${(value.json as IBaseRealTimePointDataJson).type.toUpperCase()} ${value.y?.toFixed(2) ?? 0.0}</p>`
            })

        return `<div style="text-align: left">${tooltip}</div>`;
    }

    mapOverTimeTraces: (otSeries: IOverTimeTracesDto[] | undefined, traceType: TraceType, annotations: ITraceAnnotationsDto[]) => IChartSeriesInfo[]
        = (otSeries: IOverTimeTracesDto[] | undefined, traceType: TraceType, annotations: ITraceAnnotationsDto[]) => {
        let pointMarks: IChartPointMark[] = []

        annotations.forEach(traceAnnotations => {
            traceAnnotations.annotations?.forEach(parent => {
                const json: IChartPointMarkJson = {
                    sequence: parent.sequence,
                    traceId: traceAnnotations.traceId,
                    isAnnotation: true
                }
                pointMarks.push({
                    displayValue: `${traceAnnotations.annotations?.length}`,
                    markPointId: parent.annotationId,
                    dataIndex: otSeries?.findIndex(traceItem => traceItem.traceId === traceAnnotations.traceId)!,
                    json: json
                })
            })
        })

        let traces: IChartSeriesInfo[] = []

        if (traceType !== TraceType.FATIGUE)
            traces.push({
                name: 'SlEEP SCORE',
                seriesId: `over_time_sleep_score`,
                data: otSeries!
                    .filter(x => x.sleepScore)
                    .map((item, index) => {
                        const json: ITraceOverTimePointDataJson = {
                            type: "sleep score",
                            traceId: item.traceId,
                            subjectName: item.personName,
                            traceDate: item.traceDateTime
                        }
                        return {
                            x: item.traceDateTime,
                            y: item.sleepScore,
                            index,
                            json: json
                        }
                    }),
                pointMarks: pointMarks,
                color: colorHelper.getColor(0, "sleep score"),
                colorOpacity: colorHelper.getColorOpacity(0, "sleep score"),
                movingAverageId: undefined,
                parallelYAxisNumber: 4
            })

        traces.push({
            name: 'CVT',
            seriesId: `over_time_cvt`,
            data: otSeries!
                .filter(x => x.avgCvt)
                .map((item, index) => {
                    const json: ITraceOverTimePointDataJson = {
                        type: "cvt",
                        traceId: item.traceId,
                        subjectName: item.personName,
                        traceDate: item.traceDateTime
                    }
                    return {
                        x: item.traceDateTime,
                        y: item.avgCvt,
                        index,
                        json: json
                    }
                }),
            pointMarks: pointMarks,
            color: colorHelper.getColor(0, "cvt"),
            colorOpacity: colorHelper.getColorOpacity(0, "cvt"),
            showArea: true,
            movingAverageId: undefined,
        })

        if (traceType !== TraceType.FATIGUE)
            traces.push({
                name: 'HR',
                seriesId: `over_time_hr`,
                data: otSeries!
                    .filter(x => x.avgHeartRate)
                    .map((item, index) => {
                        const json: ITraceOverTimePointDataJson = {
                            type: "hr",
                            traceId: item.traceId,
                            subjectName: item.personName,
                            traceDate: item.traceDateTime
                        }
                        return {
                            x: item.traceDateTime,
                            y: item.avgHeartRate,
                            index,
                            json: json
                        }
                    }),
                pointMarks: pointMarks,
                color: colorHelper.getColor(0, "hr"),
                colorOpacity: colorHelper.getColorOpacity(0, "hr"),
                movingAverageId: undefined,
                parallelYAxisNumber: 1
            })

        if (traceType !== TraceType.FATIGUE)
            traces.push({
                name: 'RR',
                seriesId: `over_time_rr`,
                data: otSeries!
                    .filter(x => x.avgRespirationRate)
                    .map((item, index) => {
                        const json: ITraceOverTimePointDataJson = {
                            type: "rr",
                            traceId: item.traceId,
                            subjectName: item.personName,
                            traceDate: item.traceDateTime
                        }
                        return {
                            x: item.traceDateTime,
                            y: item.avgRespirationRate,
                            index,
                            json: json
                        }
                    }),
                pointMarks: pointMarks,
                color: colorHelper.getColor(0, "rr"),
                colorOpacity: colorHelper.getColorOpacity(0, "rr"),
                movingAverageId: undefined,
                parallelYAxisNumber: 2
            })

        if (traceType !== TraceType.FATIGUE)
            traces.push({
                name: 'TEMP',
                seriesId: `over_time_temp`,
                data: otSeries!
                    .filter(x => x.avgTemperature)
                    .map((item, index) => {
                        const json: ITraceOverTimePointDataJson = {
                            type: "temp",
                            traceId: item.traceId,
                            subjectName: item.personName,
                            traceDate: item.traceDateTime
                        }
                        return {
                            x: item.traceDateTime,
                            y: item.avgTemperature,
                            index,
                            json: json
                        }
                    }),
                pointMarks: pointMarks,
                color: colorHelper.getColor(0, "temp"),
                colorOpacity: colorHelper.getColorOpacity(0, "temp"),
                movingAverageId: undefined,
                parallelYAxisNumber: 3
            })
        return traces
    };

    mapSeries: (input: IMapSeriesInput) => IChartSeriesInfoCompareMode[]
        = (input: IMapSeriesInput) => {
        let {fullTraceSeries, compareTimeType, ignoreDay, annotations, validRrCountValue} = input

        const models = fullTraceSeries.map((series: IFullTraceSeriesDto) => ({
            ...series,
            data: series.data?.map(x => ({
                    ...x,
                    cvt: input.cvtToggle ? x.cvt : undefined,
                    heartRate: input.heartRateToggle ? x.heartRate : undefined,
                    respirationRate: input.respirationRateToggle ? x.respirationRate : undefined,
                    temperature: input.temperatureToggle ? x.temperature : undefined
                })
            )
        }))

        let traceSeriesList: IChartSeriesInfoCompareMode[] = []
        let hearRateList: IChartSeriesInfoCompareMode[] = []
        let respirationRateList: IChartSeriesInfoCompareMode[] = []
        let temperatureRateList: IChartSeriesInfoCompareMode[] = []

        models.forEach((model, modelIndex) => {
            if (!model.data?.length)
                return [];

            let annotationsForSeries: IChartPointMark[] = []
            let marks: IChartPointMark[] = []
            let invalidRRPoints: { start: number, end: number }[] = []
            let invalidRRItemStart: number | undefined = undefined
            let invalidRRPointItemEnd: number | undefined = undefined

            model.data?.forEach((pointData, pointDataIndex, pointDataSource) => {
                if ((pointData.validRrIntCnt ?? validRrCountValue) < validRrCountValue) {
                    if (invalidRRItemStart === undefined) {
                        invalidRRItemStart = pointData.sequence
                        invalidRRPointItemEnd = pointData.sequence
                    } else {
                        invalidRRPointItemEnd = pointData.sequence
                    }
                } else if (invalidRRItemStart !== undefined && invalidRRPointItemEnd !== undefined) {
                    invalidRRPoints.push({start: invalidRRItemStart, end: invalidRRPointItemEnd})
                    invalidRRItemStart = undefined
                    invalidRRPointItemEnd = undefined
                }
                // push if latest
                if (pointDataIndex === pointDataSource.length && invalidRRItemStart !== undefined && invalidRRPointItemEnd !== undefined) {
                    invalidRRPoints.push({start: invalidRRItemStart, end: invalidRRPointItemEnd})
                    invalidRRItemStart = undefined
                    invalidRRPointItemEnd = undefined
                }

                if (!!pointData.markNumber) {
                    const json: IChartPointMarkJson = {
                        sequence: pointData.sequence,
                        traceId: model.traceId,
                        isAnnotation: false
                    }
                    marks.push({
                        displayValue: pointData.markNumber.toString(),
                        markPointId: undefined,
                        dataIndex: pointDataIndex,
                        json: json
                    })
                }
            });

            annotations?.filter(traceAnnotations => traceAnnotations.traceId === model.traceId)?.forEach(traceAnnotations => {
                traceAnnotations.annotations?.forEach(parent => {
                    const json: IChartPointMarkJson = {
                        sequence: parent.sequence,
                        traceId: traceAnnotations.traceId,
                        isAnnotation: true
                    }
                    annotationsForSeries.push({
                        displayValue: dateTimeHelper.day_month_year(parent.createdDate),
                        markPointId: parent.annotationId,
                        dataIndex: model.data!.findIndex(x => x.sequence === parent.sequence),
                        json: json
                    })
                })
            });

            const lineMarkRedColors = {
                color: 'rgba(212,0,61,1)',
                colorOpacity: 'rgba(212,0,61,1)'
            }
            const lineMarkBlackColors = {
                color: 'rgb(2,0,0)',
                colorOpacity: 'rgba(2,0,0, 0.25)'
            }
            let lineMark: IChartLineMark[] | undefined = []

            // Mark rr less then threshold
            if (!!invalidRRPoints?.length)
                lineMark.push(...(
                    compareTimeType === "Actual"
                        ? invalidRRPoints.map(point => ({
                            start: (this.sequenceWhenElapsedOrDateWhenActual(model.traceDate, point.start, compareTimeType, ignoreDay) as Date).getTime(),
                            end: (this.sequenceWhenElapsedOrDateWhenActual(model.traceDate, point.end, compareTimeType, ignoreDay) as Date).getTime(),
                            ...lineMarkBlackColors
                        }))
                        : invalidRRPoints.map(point => ({
                            start: (this.sequenceWhenElapsedOrDateWhenActual(model.traceDate, point.start, compareTimeType, ignoreDay) as number),
                            end: (this.sequenceWhenElapsedOrDateWhenActual(model.traceDate, point.end, compareTimeType, ignoreDay) as number),
                            ...lineMarkBlackColors
                        }))
                ))

            // Mark ecg zone
            if (!!model.ecgs?.length)
                lineMark.push(...(
                    compareTimeType === "Actual"
                        ? model.ecgs.map(ecg => ({
                            start: (this.sequenceWhenElapsedOrDateWhenActual(model.traceDate, ecg.startSequence, compareTimeType, ignoreDay) as Date).getTime(),
                            end: (this.sequenceWhenElapsedOrDateWhenActual(model.traceDate, ecg.endSequence, compareTimeType, ignoreDay) as Date).getTime(),
                            ...lineMarkRedColors
                        }))
                        : model.ecgs.map(ecg => ({
                            start: (this.sequenceWhenElapsedOrDateWhenActual(model.traceDate, ecg.startSequence, compareTimeType, ignoreDay) as number),
                            end: (this.sequenceWhenElapsedOrDateWhenActual(model.traceDate, ecg.endSequence, compareTimeType, ignoreDay) as number),
                            ...lineMarkRedColors
                        }))
                ))

            // // Mark fatigue zone
            // if (!!model.fatigueDetails) {
            //     lineMark.push({
            //         ...(compareTimeType === "Actual" ? {
            //             start: (this.sequenceWhenElapsedOrDateWhenActual(model.traceDate, model.fatigueDetails!.startTimeInMsOut, compareTimeType, ignoreDay) as Date).getTime(),
            //             end: (this.sequenceWhenElapsedOrDateWhenActual(model.traceDate, model.fatigueDetails!.endTimeInMs, compareTimeType, ignoreDay) as Date).getTime()
            //         } : {
            //             start: (this.sequenceWhenElapsedOrDateWhenActual(model.traceDate, model.fatigueDetails!.startTimeInMsOut, compareTimeType, ignoreDay) as number),
            //             end: (this.sequenceWhenElapsedOrDateWhenActual(model.traceDate, model.fatigueDetails!.endTimeInMs, compareTimeType, ignoreDay) as number)
            //         }),
            //         ...lineMarkRedColors,
            //     })
            // }

            if (!lineMark.length)
                lineMark = undefined


            const cvtSeries: IChartSeriesInfoCompareMode | undefined = !model.data?.some(x => !!x.cvt) ? undefined : {
                name: `CVT (${dateTimeHelper.day_month_year_hour_24_min(model.traceDate)})`,
                traceSeriesType: 'cvt',
                traceId: model.traceId,
                traceDate: model.traceDate,
                traceType: model.traceType,
                traceDuration: model.traceDuration,
                subjectName: model.subjectName ?? '',
                data: model.data?.map((pointData, pointDataIndex) => {
                    const json: ITraceSeriesPointDataJson = {
                        type: "cvt",
                        traceId: model.traceId,
                        sequence: pointData.sequence,
                        subjectName: model.subjectName!,
                        traceDate: model.traceDate,
                        sequenceDate: moment(model.traceDate).add(pointData.sequence, 'ms').toDate(),
                        validRrIntCount: pointData.validRrIntCnt,
                    };
                    return {
                        x: this.sequenceWhenElapsedOrDateWhenActual(model.traceDate, pointData.sequence, compareTimeType, ignoreDay),
                        y: !!pointData.cvt ? pointData.cvt : 0,
                        index: pointDataIndex,
                        json: json
                    }
                }),
                pointMarks: annotationsForSeries.concat(marks),
                lineMark: lineMark,
                seriesId: model.traceId.toString(),
                color: colorHelper.getColor(modelIndex, "cvt"),
                colorOpacity: colorHelper.getColorOpacity(modelIndex, "cvt"),
                parallelYAxisNumber: undefined,
                movingAverageId: 0,
                showArea: true
            };

            const hearRate: IChartSeriesInfoCompareMode | undefined = !model.data?.some(x => !!x.heartRate) ? undefined : {
                name: `Heart rate (${dateTimeHelper.day_month_year_hour_24_min(model.traceDate)})`,
                traceSeriesType: `hr`,
                traceId: model.traceId,
                subjectName: model.subjectName ?? '',
                traceDate: model.traceDate,
                traceType: model.traceType,
                traceDuration: model.traceDuration,
                data: model.data.map((pointData, pointDataIndex) => {
                    const json: ITraceSeriesPointDataJson = {
                        type: "hr",
                        sequence: pointData.sequence,
                        traceId: model.traceId,
                        subjectName: model.subjectName!,
                        traceDate: model.traceDate,
                        sequenceDate: moment(model.traceDate).add(pointData.sequence, 'ms').toDate(),
                        validRrIntCount: pointData.validRrIntCnt,
                    };
                    return {
                        x: this.sequenceWhenElapsedOrDateWhenActual(model.traceDate, pointData.sequence!, compareTimeType, ignoreDay),
                        y: pointData.heartRate!,
                        index: pointDataIndex,
                        json: json
                    }
                }),
                seriesId: `${model.traceId.toString()}_hear_rate`,
                color: colorHelper.getColor(modelIndex, "hr"),
                colorOpacity: colorHelper.getColorOpacity(modelIndex, "hr"),
                parallelYAxisNumber: 1,
                movingAverageId: 1,
                showArea: false
            };

            const respirationRate: IChartSeriesInfoCompareMode | undefined = !model.data?.some(x => !!x.respirationRate) ? undefined : {
                name: `Respiration (${dateTimeHelper.day_month_year_hour_24_min(model.traceDate)})`,
                traceSeriesType: `rr`,
                traceId: model.traceId,
                subjectName: model.subjectName ?? '',
                traceDate: model.traceDate,
                traceType: model.traceType,
                traceDuration: model.traceDuration,
                data: model.data.map((pointData, pointDataIndex) => {
                    const json: ITraceSeriesPointDataJson = {
                        type: "rr",
                        sequence: pointData.sequence,
                        traceId: model.traceId,
                        subjectName: model.subjectName!,
                        traceDate: model.traceDate,
                        sequenceDate: moment(model.traceDate).add(pointData.sequence, 'ms').toDate(),
                        validRrIntCount: pointData.validRrIntCnt,
                    };
                    return {
                        x: this.sequenceWhenElapsedOrDateWhenActual(model.traceDate, pointData.sequence!, compareTimeType, ignoreDay),
                        y: pointData.respirationRate!,
                        index: pointDataIndex,
                        json: json
                    }
                }),
                seriesId: `${model.traceId.toString()}_respiration_rate`,
                color: colorHelper.getColor(modelIndex, "rr"),
                colorOpacity: colorHelper.getColorOpacity(modelIndex, "rr"),
                parallelYAxisNumber: 2,
                movingAverageId: 2,
                showArea: false
            };

            const temperatureRate: IChartSeriesInfoCompareMode | undefined = !model.data?.some(x => !!x.temperature) ? undefined : {
                name: `Temperature (${dateTimeHelper.day_month_year_hour_24_min(model.traceDate)})`,
                traceSeriesType: `temp`,
                traceId: model.traceId,
                subjectName: model.subjectName ?? '',
                traceDate: model.traceDate,
                traceType: model.traceType,
                traceDuration: model.traceDuration,
                data: model.data.map((pointData, pointDataIndex) => {
                    const json: ITraceSeriesPointDataJson = {
                        type: "temp",
                        traceId: model.traceId,
                        sequence: pointData.sequence,
                        subjectName: model.subjectName!,
                        traceDate: model.traceDate,
                        sequenceDate: moment(model.traceDate).add(pointData.sequence, 'ms').toDate(),
                        validRrIntCount: pointData.validRrIntCnt,
                    };
                    return {
                        x: this.sequenceWhenElapsedOrDateWhenActual(model.traceDate, pointData.sequence!, compareTimeType, ignoreDay),
                        y: pointData.temperature!,
                        index: pointDataIndex, json: json
                    }
                }),
                seriesId: `${model.traceId.toString()}_temperature_rate`,
                color: colorHelper.getColor(modelIndex, "temp"),
                colorOpacity: colorHelper.getColorOpacity(modelIndex, "temp"),
                parallelYAxisNumber: 3,
                movingAverageId: 3,
                showArea: false
            };

            if (!!cvtSeries?.data.length)
                traceSeriesList.push(cvtSeries)
            if (!!hearRate?.data.length)
                hearRateList.push(hearRate)
            if (!!respirationRate?.data.length)
                respirationRateList.push(respirationRate)
            if (!!temperatureRate?.data.length)
                temperatureRateList.push(temperatureRate)
        });

        return [...traceSeriesList, ...hearRateList, ...respirationRateList, ...temperatureRateList]
    };

    private getActualTraceDate = (traceDateTime: Date, ignoreDay: boolean) => {
        return ignoreDay ? this.dateWhenIgnoreDay(traceDateTime) : traceDateTime;
    }
    sequenceWhenElapsedOrDateWhenActual = (traceDateTime: Date, sequence: number, compareTimeType: CompareTimeType, ignoreDay: boolean) => {
        switch (compareTimeType) {
            case "Actual":
                return dateTimeHelper.durationMsToDate(sequence as number, this.getActualTraceDate(traceDateTime, ignoreDay));
            case "Elapsed":
                return sequence;
            default:
                throw new Error("Unknown compareTimeType")
        }
    }

    dateWhenIgnoreDay = (traceDateTime: Date) => {
        const date = new Date();
        date.setFullYear(2002);
        date.setMonth(1);
        date.setDate(traceDateTime.getHours() < 12 ? 2 : 1);
        date.setHours(traceDateTime.getHours());
        date.setMinutes(traceDateTime.getMinutes(), traceDateTime.getSeconds(), traceDateTime.getMilliseconds());
        return date;
    };

}

export const chartHelper = new ChartHelper();

export type TraceSeriesType = "cvt" | "hr" | "rr" | "temp" | string

export interface IBaseBatchPointDataJson {
    type: TraceSeriesType;
    traceId: number;
    traceDate: Date;
    subjectName: string;
}

export interface ITraceSeriesPointDataJson extends IBaseBatchPointDataJson {
    sequence: number;
    validRrIntCount?: number | undefined;
    sequenceDate: Date;
}

export interface ITraceOverTimePointDataJson extends IBaseBatchPointDataJson {
}

export interface IBaseRealTimePointDataJson {
    type: TraceSeriesType;
}

export interface IChartPointMarkJson {
    traceId: number;
    sequence?: number;
    isAnnotation: boolean;
}

export interface IChartSeriesInfoCompareMode extends IChartSeriesInfo {
    traceId: number;
    traceDate: Date;
    traceDuration?: string | undefined;
    traceType: TraceType;
    subjectName: string;
    traceSeriesType: TraceSeriesType;
}


export interface IMapSeriesInput {
    fullTraceSeries: IFullTraceSeriesDto[],
    compareTimeType: CompareTimeType,
    ignoreDay: boolean,
    annotations: ITraceAnnotationsDto[],
    validRrCountValue: number,
    cvtToggle: boolean,
    heartRateToggle: boolean,
    respirationRateToggle: boolean,
    temperatureToggle: boolean
}
