import React from "react";
import {RouteComponentProps} from "react-router";
import {TracesStore} from "../../stores/traces/tracesStore";
import {AnnotationStore} from "../../stores/annotationStore/annotationStore";
import {UserStore} from "../../stores/user/userStore";
import {ChartStore} from "../../stores/chartStore/chartStore";
import {InjectNames} from "../../stores/initializeStores";
import {inject, observer} from "mobx-react";
import {Chart} from "../Charts/Chart";
import {action, computed, observable} from "mobx";
import {IChartPointMark, IChartValue, IOnPointClickOptions, IToolTipParams} from "../Charts/IChartSeriesInfo";
import {errorUtils} from "../../services/utils/ErrorUtils";
import {Button, Card, Col, Popover, Row, Slider, Switch} from "antd";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";
import {
    chartHelper,
    IChartPointMarkJson,
    IChartSeriesInfoCompareMode,
    ITraceSeriesPointDataJson
} from "../../services/utils/ChartHelper";
import {dataUriIcons} from "../../constants/Icon";
import {AnnotationTraceList} from "../Annotations/AnnotationTraceList";
import {AnnotationCreateModal, InitAnnotation} from "../Annotations/AnnotationCreateModal";
import {ChartExport} from "../Charts/ChartExport";
import {SliderValue} from "antd/lib/slider";
import {CompareTracesStore} from "../../stores/traces/compareTracesStore";
import {CompareOptions} from "./CompareOptions";
import EcgViewer from "./EcgViewer";

interface IProps extends RouteComponentProps {
    tracesStore?: TracesStore
    compareTracesStore?: CompareTracesStore
    annotationStore?: AnnotationStore
    userStore?: UserStore
    chartStore?: ChartStore
}

@inject(InjectNames.tracesStore, InjectNames.userStore, InjectNames.chartStore, InjectNames.annotationStore, InjectNames.compareTracesStore)
@observer
export class CompareTraces extends React.Component<IProps> {
    chartRef?: Chart;
    @observable loading = true;
    @observable showAnnotationDialog = false;
    @observable initAnnotation?: InitAnnotation;

    async componentDidMount() {
        await this.load();
    }

    async componentWillUnmount() {
        this.props.tracesStore!.clearFullTraceSeries()
        this.props.compareTracesStore!.setDefaultValues()
    }

    get cmpStore() {
        return this.props.compareTracesStore!
    }

    load = async () => {
        await errorUtils.execute({context: this, loadingFieldName: 'loading'}, async () => {
            let loadSource: {
                traceId: number,
            }[] = this.props.tracesStore!.selectedTraces.slice()
                .map(x => ({
                    traceId: x.traceId,
                    bestWorstType: undefined
                }));
            await this.props.tracesStore!.loadFullTraceSeriesModel(loadSource
                .map(item => ({
                    traceId: item.traceId,
                })));
            await this.props.annotationStore!.load(this.props.tracesStore!.fullTraceSeriesDtoList.map(x => x.traceId))
            this.checkZoomPercentQuery()
        })
    };
    checkZoomPercentQuery = () => {
        // if (this.props.chartStore!.zoomPercentStart !== undefined && this.props.chartStore!.zoomPercentEnd !== undefined) {
        //     this.chartRef!.zoomPercentStart = this.props.chartStore!.zoomPercentStart
        //     this.chartRef!.zoomPercentEnd = this.props.chartStore!.zoomPercentEnd
        //     this.props.chartStore!.zoomPercentStart = undefined
        //     this.props.chartStore!.zoomPercentEnd = undefined
        // }
    }

    // CSV export - start
    @observable csvExporting = false
    exportToCsv = async () => {
        try {
            this.csvExporting = true
            await new ChartExport().exportToCsv(this.chartSeries, this.chartRef!)
        } finally {
            this.csvExporting = false
        }
    }
    // CSV export - end

    @computed get indexAxisFormatter() {
        if (this.cmpStore.compareTimeType === "Actual")
            return (value: Date | number) => dateTimeHelper.day_month_hour_min(dateTimeHelper.durationMsToDate(this.props.tracesStore!.fullTraceSeriesDtoList[0].data![value as number]!.sequence, this.props.tracesStore!.fullTraceSeriesDtoList[0].traceDate))
        else if (this.cmpStore.compareTimeType === "Elapsed")
            return (value: Date | number) => dateTimeHelper.msToDurationFormat(this.props.tracesStore!.fullTraceSeriesDtoList[0].data![value as number]!.sequence);
        else throw new Error('Unknown compareTimeType')
    }

    @computed get tooltipDateFormatter() {
        if (this.cmpStore.isHeartbeatMode)
            switch (this.cmpStore.compareTimeType) {
                case "Actual":
                    return this.cmpStore.ignoreDayToggle
                        ? (value: Date | number) => `${dateTimeHelper.hour_min_sec_a(this.chartSeries[0].data[(value as number)].x as Date)} - ${(value as number).toString()} beat`
                        : (value: Date | number) => `${dateTimeHelper.day_month_year_hour_min_sec(this.chartSeries[0].data[(value as number)].x as Date)} - ${(value as number).toString()} beat`
                case "Elapsed":
                    return (value: Date | number) => `${dateTimeHelper.msToDurationFormat(this.chartSeries[0].data[(value as number)].x as number)} - ${(value as number).toString()} beat`;
                default:
                    throw new Error('Unknown compareTimeType')
            }
        else
            switch (this.cmpStore.compareTimeType) {
                case "Actual":
                    return this.cmpStore.ignoreDayToggle
                        ? (value: Date | number) => dateTimeHelper.hour_min_sec_a(value as Date)
                        : (value: Date | number) => dateTimeHelper.day_month_year_hour_min_sec(value as Date)
                case "Elapsed":
                    return (value: Date | number) => dateTimeHelper.msToDurationFormat(value as number);
                default:
                    throw new Error('Unknown compareTimeType')
            }
    }

    @computed get tooltipFormatter() {
        return (values: IToolTipParams[]) => chartHelper.tooltipFormatterDefault(values, ((chartValue: IChartValue) => this.tooltipDateFormatter(chartValue.x as Date)))
    }

    @computed get xAxisFormatter() {
        if (this.cmpStore.isHeartbeatMode)
            return (value: Date | number) => `${(value as number)} beat`;
        else
            switch (this.cmpStore.compareTimeType) {
                case "Actual":
                    return this.cmpStore.ignoreDayToggle
                        ? (value: Date | number) => dateTimeHelper.hour_min_sec_a(value as Date)
                        : undefined
                case "Elapsed":
                    return (value: Date | number) => dateTimeHelper.msToDurationFormat(value as number)
                default:
                    throw new Error('Unknown compareTimeType')
            }
    }

    @computed get chartSeries() {
        return chartHelper.mapSeries({
            fullTraceSeries: this.props.tracesStore!.fullTraceSeriesDtoList,
            compareTimeType: this.cmpStore.compareTimeType,
            ignoreDay: this.cmpStore.ignoreDayToggle,
            annotations: this.props.annotationStore!.tracesList,
            validRrCountValue: this.cmpStore.validRrCountValue,
            cvtToggle: this.cmpStore.cvtToggle,
            heartRateToggle: this.cmpStore.heartRateToggle,
            respirationRateToggle: this.cmpStore.respirationRateToggle,
            temperatureToggle: this.cmpStore.temperatureToggle
        });
    }

    @action
    onSelectedSeries = (seriesId: string | undefined) => {
        //  Nothing. May sort/select hourly breakdown if need.
    }

    @action
    onDeletedLegend = (legendId: string) => {
        this.props.tracesStore!.selectedTraces = this.props.tracesStore!.selectedTraces.slice().filter(x => x.traceId?.toString() !== legendId)
        this.load()
    }

    @computed get onPointClickOptions() {
        const imageSize = 30;
        const actionsOptions: IOnPointClickOptions = {
            actions: [
                {
                    image: {
                        x: 0,
                        y: 0,
                        style: {
                            width: imageSize, height: imageSize,
                            image: dataUriIcons.Annotation
                        }
                    }, action: async (dataValue: IChartValue, seriesId: string) => {
                        const json = (dataValue.json as ITraceSeriesPointDataJson)
                        this.initAnnotation = {
                            traceId: json.traceId,
                            sequence: json.sequence,
                            traceInfo: {traceDate: json.traceDate, subjectName: json.subjectName}
                        }
                    }
                }],
        };
        return actionsOptions;
    }

    onMarkClick = async (dataValue: IChartPointMark, seriesId: string) => {
        const json: IChartPointMarkJson = dataValue.json
        if (json.isAnnotation) {
            this.props.annotationStore!.selectAnnotation({
                trace: json.traceId,
                annotation: dataValue.markPointId
            })
            this.showAnnotationDialog = true
        }
    };

    @observable movingAverageOption = [
        {
            step: 10,
            min: 0,
            max: 500,
            value: 200,
            title: 'CVT',
            movingAverageId: 0,
            disabled: !this.cmpStore.cvtMovingAvgAllowed
        },
        {
            step: 10,
            min: 0,
            max: 500,
            value: 200,
            title: 'Heart rate',
            movingAverageId: 1,
            disabled: !this.cmpStore.heartRateMovingAvgAllowed
        },
        {
            step: 10,
            min: 0,
            max: 500,
            value: 200,
            title: 'Respiration',
            movingAverageId: 2,
            disabled: !this.cmpStore.respirationRateMovingAvgAllowed
        },
        {
            step: 10,
            min: 0,
            max: 500,
            value: 200,
            title: 'Temperature',
            movingAverageId: 3,
            disabled: !this.cmpStore.temperatureMovingAvgAllowed
        },
    ]


    render() {
        const mvgFormatter = (movingAverageId: number, checked: boolean, toggleAllowed: boolean, avgAllowed: boolean, handleToggle: (checked: boolean) => void) => {
            return <Popover content={
                <Slider {...this.movingAverageOption.find(x => x.movingAverageId === movingAverageId)}
                        style={{width: 300}}
                        disabled={!avgAllowed}
                        value={this.movingAverageOption.find(x => x.movingAverageId === movingAverageId)!.value}
                        onChange={async (value: SliderValue) => {
                            this.movingAverageOption.find(x => x.movingAverageId === movingAverageId)!.value = value as number
                        }}/>
            }>
                <span>
                    <Switch disabled={!toggleAllowed} checked={checked} onChange={handleToggle}/>
                    <span
                        style={{paddingLeft: 15}}>{this.movingAverageOption.find(x => x.movingAverageId === movingAverageId)!.title}</span>
                </span>
            </Popover>
        }
        const extensions = () => {
            const rowStyle: React.CSSProperties = {marginBottom: 30, textAlign: 'left'}
            const row = {style: rowStyle}
            return (<div style={{marginTop: 15}}>
                <Row type={'flex'} justify={'space-between'}>
                    <Row {...row} type={'flex'} gutter={[15, 15]}>
                        <Col>{mvgFormatter(0, this.cmpStore.cvtToggle, this.cmpStore.cvtToggleAllowed, this.cmpStore.cvtMovingAvgAllowed, this.cmpStore.handleToggleCvt)}</Col>
                        <Col>{mvgFormatter(1, this.cmpStore.heartRateToggle, this.cmpStore.heartRateToggleAllowed, this.cmpStore.heartRateMovingAvgAllowed, this.cmpStore.handleToggleHeartRate)}</Col>
                        <Col>{mvgFormatter(2, this.cmpStore.respirationRateToggle, this.cmpStore.respirationRateToggleAllowed, this.cmpStore.respirationRateMovingAvgAllowed, this.cmpStore.handleToggleRespirationRate)}</Col>
                        <Col>{mvgFormatter(3, this.cmpStore.temperatureToggle, this.cmpStore.temperatureToggleAllowed, this.cmpStore.temperatureMovingAvgAllowed, this.cmpStore.handleToggleTemperature)}</Col>
                    </Row>
                    <Row gutter={[15, 15]} type={'flex'} justify={'end'}>
                        <Col style={{textAlign: 'right'}}>
                            <Button loading={this.csvExporting} onClick={this.exportToCsv} icon={'download'}
                                    style={{background: "red", borderColor: "red", color: 'white'}}>Download</Button>
                        </Col>
                        <Col style={{textAlign: "right", paddingBottom: 15}}><Button icon={'message'}
                                                                                     onClick={async () => {
                                                                                         await errorUtils.execute({
                                                                                             context: this,
                                                                                             loadingFieldName: 'annotationDialogLoading'
                                                                                         }, async () => {
                                                                                             this.showAnnotationDialog = true
                                                                                             await this.props.annotationStore!.load(this.props.tracesStore!.fullTraceSeriesDtoList.map(x => x.traceId))
                                                                                         })
                                                                                     }}>Annotations</Button></Col>
                        <Col>
                            <CompareOptions/>
                        </Col>
                    </Row>
                </Row>
            </div>)
        }

        const chart = () => <Card>
            <Chart ref={(ref: Chart) => this.chartRef = ref}
                   rerenderKey={`${this.cmpStore.ignoreDayToggle}`}
                   parallelYAxis={[
                       {
                           parallelYAxisNumber: undefined,
                           yAxisName: "CVT",
                           position: "left",
                           offset: 0,
                           hideAll: !this.chartSeries.some(x => x.parallelYAxisNumber === undefined && !!x.data?.length)
                       },
                       {
                           parallelYAxisNumber: 1,
                           yAxisName: "HR",
                           position: "right",
                           offset: 0,
                           hideAll: !this.chartSeries.some(x => x.parallelYAxisNumber === 1 && !!x.data?.length)
                       },
                       {
                           parallelYAxisNumber: 2,
                           yAxisName: "RESP",
                           position: "right",
                           offset: this.cmpStore.heartRateToggle ? 45 : 0,
                           hideAll: !this.chartSeries.some(x => x.parallelYAxisNumber === 2 && !!x.data?.length)
                       },
                       {
                           parallelYAxisNumber: 3,
                           yAxisName: "TEMP",
                           min: 30,
                           max: 46,
                           position: "left",
                           offset: this.cmpStore.cvtToggle ? 45 : 0,
                           hideAll: !this.chartSeries.some(x => x.parallelYAxisNumber === 3 && !!x.data?.length)
                       }
                   ]}
                   movingAverageOption={this.movingAverageOption}
                   indexAxisFormatter={this.indexAxisFormatter}
                   displayIndex={this.cmpStore.isHeartbeatMode}
                   xAxisType={this.cmpStore.xAxisType}
                   loading={this.loading}
                   tooltipFormatter={this.tooltipFormatter}
                   xAxisFormatter={this.xAxisFormatter}
                   chartSeries={this.chartSeries}
                   onSelectedSeries={this.onSelectedSeries}
                   onPointClickOptions={this.onPointClickOptions}
                   onMarkClick={this.onMarkClick}
                   hideToolbox={false}
                   showSymbols={this.cmpStore.showSymbols}
                   onDeletedSeries={this.onDeletedLegend}
                   onZoomChanged={this.cmpStore.viewEcg}
                   legendFormatter={chartInfoList => chartHelper.legendFormatterCompareMode(chartInfoList as IChartSeriesInfoCompareMode[], this.chartRef?.selectedSeriesId)}
            />

            {this.cmpStore.viewEcgEnabled && <EcgViewer ref={ref => this.cmpStore.ecgViewerRef = ref!} chartRef={() => this.chartRef!}/>}
        </Card>

        return <React.Fragment>
            {extensions()}
            {chart()}
            <AnnotationTraceList annotations={this.props.annotationStore!.tracesList}
                                 onClose={() => this.showAnnotationDialog = false} visible={this.showAnnotationDialog}/>
            <AnnotationCreateModal onClose={() => this.initAnnotation = undefined}
                                   onAdded={(annotationId: number) => {
                                       this.props.annotationStore!.selectAnnotation({
                                           annotation: annotationId,
                                           trace: this.initAnnotation!.traceId
                                       });
                                       this.initAnnotation = undefined
                                       this.showAnnotationDialog = true
                                   }} model={this.initAnnotation}/>
        </React.Fragment>

    }
}
