import {RouteComponentProps} from "react-router-dom";
import 'mobx-react-lite/batchingForReactDom'
import {Button, Form, Input, Modal, Radio, Row, Select, Slider, TimePicker} from "antd";
import {
    AnswersSettingsJsonDto,
    QuestionInputType,
    QuestionJsonDto,
    QuestionType
} from "../../../services/api/ProCvtClient";
import React, {useState} from "react";
import {SliderValue} from "antd/es/slider";
import moment from "moment-timezone";
import CheckboxGroup from "antd/es/checkbox/Group";
import {hooks} from 'botframework-webchat-component';
import {inject, observer} from "mobx-react";
import {UserStore} from "../../../stores/user/userStore";
import {SurveyBotStore} from "../../../stores/surveyBotStore/surveyBotStore";
import {InjectNames} from "../../../stores/initializeStores";

const defaultValidation = (valueToSend: any, setError: (errorMsg: string) => void) => {
    let isValid = true
    if (valueToSend === null || valueToSend === undefined) {
        setError('Required')
        isValid = false
    }
    if (valueToSend instanceof Array && !valueToSend?.length) {
        setError('Required')
        isValid = false
    }
    return {isValid: isValid, value: valueToSend}
}
const {useSendPostBack} = hooks;

const WebChatCompleted = (props: RouteComponentProps) => {
    const route = () => props.history.push('/residents')
    setTimeout(() => Modal.success({
        content: 'Survey completed',
        onOk: route,
        onCancel: route,
        cancelButtonProps: {hidden: true}
    }), 1)
    return <div style={{margin: 15}}>Completed</div>
}
const WebChatSlider = (question: QuestionJsonDto) => {
    const [error, setError] = useState('');
    const form = (value: any, setValue: (newValue: any) => void) =>
        (<Form.Item validateStatus={!!error ? "error" : undefined} help={error}>
            <Slider value={value as any} min={question.answers_bundle?.range_settings?.min_value}
                    max={question.answers_bundle?.range_settings?.max_value}
                    onChange={(value: SliderValue) => {
                        setValue(value);
                        setError('')
                    }}/>
        </Form.Item>)
    const defaultValue = question.answers_bundle?.range_settings?.min_value
    const processValue = (valueToSend: number) => {
        return defaultValidation(valueToSend, setError)
    }
    return <WebChatComponentBase question={question} defaultValue={defaultValue} processValue={processValue} form={form}/>
}
const WebChatDropDown = (question: QuestionJsonDto) => {
    const [error, setError] = useState('');
    const form = (value: any, setValue: (newValue: any) => void) =>
        (<Form.Item validateStatus={!!error ? "error" : undefined} help={error}>
            <Select value={value} onChange={(value: any) => {
                setValue(value);
                setError('')
            }}>
                {question.answers_bundle?.answers?.map((answer: AnswersSettingsJsonDto) => <Select.Option
                    key={answer.id} value={answer.text}>{answer.text}</Select.Option>)}
            </Select>
        </Form.Item>)
    const defaultValue = null
    const processValue = (valueToSend: any) => {
        return defaultValidation(valueToSend, setError)
    }
    return <WebChatComponentBase question={question} defaultValue={defaultValue} processValue={processValue} form={form}/>
}
const WebChatRadioButtons = (question: QuestionJsonDto) => {
    const rangeSettings = question.answers_bundle?.range_settings
    const [error, setError] = useState('');
    const buttonStyle = {margin: '15px'}
    const form = (value: any, setValue: (newValue: any) => void) =>
        (<Form.Item validateStatus={!!error ? "error" : undefined} help={error}>
            <Radio.Group>
                {!!rangeSettings
                    ? Array.from(new Array(rangeSettings!.max_value + 1 - rangeSettings!.min_value).keys()).map(x =>
                        <Radio key={x} value={x + 1} style={buttonStyle} onChange={event => {
                            setValue(event.target.value);
                            setError('')
                        }}>{x + 1}</Radio>)
                    : question.answers_bundle?.answers?.map((answer: AnswersSettingsJsonDto) => (
                        <Radio key={answer.id}
                               style={buttonStyle}
                               onChange={event => {
                                   setValue(event.target.value);
                                   setError('')
                               }}
                               value={answer.text}>{answer.text}</Radio>
                    ))
                }
            </Radio.Group>
        </Form.Item>)
    const defaultValue = null
    const processValue = (valueToSend: any) => {
        return defaultValidation(valueToSend, setError)
    }
    return <WebChatComponentBase question={question} defaultValue={defaultValue} processValue={processValue} form={form}/>
}
const WebChatTextInput = (question: QuestionJsonDto) => {
    const inputType: { [key: string]: string } = {
        [QuestionType.Float_1dp]: 'number',
        [QuestionType.Integer_interval]: 'number',
        [QuestionType.Text]: 'text',
        [QuestionType.Enumeration]: 'text',
    }
    const rangeSettings = question.answers_bundle?.range_settings
    const [error, setError] = useState('');
    const form = (value: any, setValue: (newValue: any) => void) =>
        (<Form.Item validateStatus={!!error ? "error" : undefined} help={error}>
            <Input type={inputType[question.answers_bundle!.type]} value={value}
                   onChange={event => {
                       setValue(event.target.value);
                       setError('')
                   }}/>
        </Form.Item>)
    const defaultValue = null
    const processValue = (valueToSend: any) => {
        const defaultValidated = defaultValidation(valueToSend, setError)
        if (!defaultValidated.isValid)
            return defaultValidated
        valueToSend = valueToSend as number
        let isValid = true
        if (!!rangeSettings) {
            isValid = (valueToSend) >= question.answers_bundle?.range_settings!.min_value && (valueToSend as number) <= question.answers_bundle?.range_settings!.max_value
            if (!isValid)
                setError(`Value shout be between ${rangeSettings?.min_value} and ${rangeSettings?.max_value}`)

        }
        return {isValid: isValid, value: valueToSend}
    }
    return <WebChatComponentBase question={question} defaultValue={defaultValue} processValue={processValue} form={form}/>
}
const WebChatTimeSelector = (question: QuestionJsonDto) => {
    const [error, setError] = useState('');
    const form = (value: any, setValue: (newValue: any) => void) =>
        (<Form.Item validateStatus={!!error ? "error" : undefined} help={error}>
            <TimePicker format={'HH:mm'} onChange={(time, timeString) => {
                setValue(time);
                setError('')
            }} value={value}/>
        </Form.Item>)
    const defaultValue = moment(new Date(1, 1, 1, 21))
    const processValue = (valueToSend: any) => {
        valueToSend = valueToSend?.format('HH:mm')
        return defaultValidation(valueToSend, setError)
    }
    return <WebChatComponentBase question={question} defaultValue={defaultValue} processValue={processValue} form={form}/>
}
const WebChatCheckBox = (question: QuestionJsonDto) => {
    const rangeSettings = question.answers_bundle?.range_settings
    const [error, setError] = useState('');
    const form = (value: any, setValue: (newValue: any) => void) =>
        (<Form.Item validateStatus={!!error ? "error" : undefined} help={error}>{
            !!question.answers_bundle?.range_settings
                ? Array.from(new Array(rangeSettings!.max_value + 1 - rangeSettings!.min_value).keys()).map(x =>
                    <Radio key={x} value={x + 1} onChange={event => {
                        setValue(event.target.value);
                        setError('')
                    }}>{x + 1}</Radio>)
                : <CheckboxGroup value={value} onChange={(checked) => {
                    setValue(checked);
                    setError('')
                }}
                                 options={question.answers_bundle?.answers?.map((answer) => answer.text!)}/>
        }
        </Form.Item>)
    const defaultValue: any[] = []
    const processValue = (valueToSend: any) => {
        return defaultValidation(valueToSend, setError)
    }
    return <WebChatComponentBase question={question} defaultValue={defaultValue} processValue={processValue} form={form}/>
}
interface IProps {
    userStore?:UserStore,
    surveyBotStore?:SurveyBotStore,
    question: QuestionJsonDto, defaultValue: any, processValue: (valueToSend: any) => { value?: any, isValid: boolean },
    form: (value: any, setValue: (newValue: any) => void) => any
}
const WebChatComponentBase = inject(InjectNames.userStore, InjectNames.surveyBotStore)(observer((props:IProps) => {
    const sendPostBack = useSendPostBack()
    const handleSubmit = () => {
        const processedValue = props.processValue(value)
        if (!processedValue.isValid) {
            return
        }
        sendPostBack({value: processedValue.value, questionId: props.question.id})
    };
    const [value, setValue] = useState(props.defaultValue);
    const isThirdUserSurvey = props.userStore?.userDetails?.personId !== props.surveyBotStore?.personId

    return (
        <div style={{margin: 15}}>
            <Form onSubmit={(event: any) => {
                event.preventDefault();
                handleSubmit()
            }}>
                <div>{isThirdUserSurvey ? props.question.text_third_person : props.question.text_first_person}</div>
                <div>{props.question.answers_bundle?.range_settings?.details}</div>
                {props.form(value, setValue)}
                <Row type={'flex'} justify={'end'}>
                    <Button loading={props.surveyBotStore!.loading.includes(props.question.id)} htmlType={'submit'}>Submit</Button>
                </Row>
            </Form>
        </div>
    )
}))


interface IDictionary {
    [key: string]: any
}


const webChatComponents: IDictionary = {
    [QuestionInputType.Slider]: WebChatSlider,
    [QuestionInputType.Drop_down_list]: WebChatDropDown,
    [QuestionInputType.Radio_buttons]: WebChatRadioButtons,
    [QuestionInputType.Textbox]: WebChatTextInput,
    [QuestionInputType.Time_selector]: WebChatTimeSelector,
    [QuestionInputType.Check_box]: WebChatCheckBox,
}

export {WebChatCompleted, webChatComponents}
