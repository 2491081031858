import {RoomsTable} from "../../../components/Tables/RoomsTable";
import {RouteComponentProps} from 'react-router-dom';
import React from "react";
import {Button, Card, Col, Row} from "antd";
import {action} from "mobx";

interface IProps extends RouteComponentProps {

}

export class RoomsTableScene extends React.Component<IProps, any> {
    @action routeToAddRoom = () => this.props.history.push('/rooms/add')
    render() {
        return <React.Fragment>
            <Row style={{paddingBottom:15}}>
                <Col><Button onClick={this.routeToAddRoom}>Add room</Button></Col>
            </Row>
            <Card title={'Rooms'}>
                <RoomsTable {...this.props}/>
            </Card>
        </React.Fragment>
    }
}
