import {action, observable} from "mobx";
import {
    ProCvtClient,
    ProcessTraceFileRequest,
    TraceFileProcessingStatus,
    TraceType,
    UploadTraceFileRequest,
} from "../../services/api/ProCvtClient";
import {IDisposable, RootStore} from "../initializeStores";

export class ManualUploadStore implements IDisposable {
    constructor(pbmSleepClient: ProCvtClient, rootStore: RootStore) {
        this.pbmSleepClient = pbmSleepClient!;
        this.rootStore = rootStore;
    }

    private readonly pbmSleepClient: ProCvtClient;
    private readonly rootStore: RootStore;
    private isDaemonStarted: boolean = false;
    private isPendingLatestProgressResponse: boolean = false;

    @observable subjectId?: number;
    @observable subjectName?: string;
    @observable processingList: IProcessingProgressDto[] = [];
    @observable completedList: IProcessedTraceDto[] = [];

    @action
    uploadTraceFile: (input: IUploadTraceFileInput) => Promise<IUploadedTraceFileDto> = async (input: IUploadTraceFileInput) => {
        return (await this.pbmSleepClient.manualUpload_UploadTraceFile(new UploadTraceFileRequest(input)))!
    }

    @action
    processTraceFile: (input: IProcessTraceFileInput, uploadInput: IUploadTraceFileInput) => Promise<void> = async (input: IProcessTraceFileInput, uploadInput: IUploadTraceFileInput) => {
        await this.pbmSleepClient.manualUpload_ProcessTraceFile(new ProcessTraceFileRequest(input))
        const progress = await this.getProgressProcessing(input.traceFileId)
        // let subjectName: string;
        // if (this.rootStore.userStore.isSubject) {
        //     subjectName = this.rootStore.userStore.userName
        // } else {
        //     const details = (await this.rootStore.subjectStore.getSubjectDetails(uploadInput.subjectId!))
        //     subjectName = details.subjectName!
        // }
        this.processingList.push({
            ...progress,
            traceFileId: input.traceFileId,
            fileName: uploadInput.fileName!,
            processPercent: 0
        })
        this.runProgressDaemon()
    }

    @action
    cancelProcessing: (traceFileId: number) => Promise<void> = async (traceFileId: number) => {
        this.processingList = this.processingList.filter(x => x.traceFileId !== traceFileId)
        await this.pbmSleepClient.manualUpload_CancelProcessingTraceFile(traceFileId)
    }

    runProgressDaemon = () => {
        if (this.isDaemonStarted) return;
        else this.isDaemonStarted = true;

        const intervalID = setInterval(async () => {
            if (!this.processingList.length) {
                clearInterval(intervalID)
                this.isDaemonStarted = false;
            } else if (!this.isPendingLatestProgressResponse) {
                this.isPendingLatestProgressResponse = true;
                try {
                    const resultPromises = this.processingList.slice().map(async (currentProgress: IProcessingProgressDto) => {
                            const newProgress = await this.getProgressProcessing(currentProgress.traceFileId)
                            return {
                                traceFileId: currentProgress.traceFileId,
                                fileName: currentProgress.fileName,
                                status: newProgress.status,
                                queuePosition: newProgress.queuePosition,
                                processProgress: newProgress.processProgress,
                                processPercent: Math.ceil(newProgress.processProgress * 100 / 14)
                            }
                        }
                    );
                    this.processingList = await Promise.all(resultPromises);
                    this.processingList.forEach(currentProgress => {
                        if (currentProgress.status === TraceFileProcessingStatus.C || currentProgress.processPercent === 100) {
                            if ((currentProgress.status === TraceFileProcessingStatus.C) !== (currentProgress.processPercent === 100)) {
                                console.error(`Process percent is 100, but status is ${currentProgress.status}`)
                            }
                            this.makeCompleted(currentProgress.traceFileId)
                            if (this.rootStore.userStore.isSubject) {
                                /** Fix*/
                                throw new Error('Not implemented')
                                // this.rootStore.subjectStore.loadStats();
                            } else if (this.rootStore.userStore.isPractitioner) {
                                /** Fix*/
                                throw new Error('Not implemented')
                                // this.rootStore.practitionerStore.loadStats();
                            }
                            if (this.rootStore.tracesStore.lastLoadTracesInput)
                                this.rootStore.tracesStore.loadTraces(this.rootStore.tracesStore.lastLoadTracesInput)
                        }
                    })
                } finally {
                    this.isPendingLatestProgressResponse = false
                }
            }
        }, 2000)
    }

    @action
    getProgressProcessing: (traceFileId: number) => Promise<ITraceFileProcessingProgressDto> = async (traceFileId: number) => {
        return (await this.pbmSleepClient.manualUpload_ProgressProcessingTraceFile(traceFileId))!;

    };
    @action
    getTraceId: (traceFileId: number) => Promise<number> = async traceFileId => {
        return (await this.pbmSleepClient.manualUpload_GetTraceId(traceFileId))!
    };
    @action
    makeCompleted = async (traceFileId: number) => {
        const trace = this.processingList.find(item => item.traceFileId === traceFileId)!;
        this.processingList = this.processingList.filter(item => item.traceFileId !== trace.traceFileId);
        const traceId = await this.getTraceId(traceFileId)
        this.completedList.push(
            {
                fileName: trace.fileName, traceId: traceId
            })
    };
    @action
    removeFromCompletedList = (traceId: number) => {
        this.completedList = this.completedList.filter(item => item.traceId !== traceId)
    };

    @action
    dispose = async () => {
        this.subjectId = undefined;
        this.subjectName = undefined;
    };
}

export interface IUploadTraceFileInput {
    subjectId?: number;
    fileName?: string | undefined;
    base64File?: string | undefined;
}

export interface IUploadedTraceFileDto {
    traceFileId: number;
    traceDateTime: Date;
    traceDuration?: string | undefined;
    traceType: TraceType;
}

export interface IProcessTraceFileInput {
    traceFileId: number;
    traceType: TraceType;
}

export interface ITraceFileProcessingProgressDto {
    status: TraceFileProcessingStatus;
    queuePosition: number;
    processProgress: number;
}

export interface IProcessingProgressDto extends ITraceFileProcessingProgressDto {
    traceFileId: number;
    fileName: string;
    processPercent: number;
}

export interface IProcessedTraceDto {
    traceId: number;
    fileName: string;
}
