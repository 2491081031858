import React from "react";
import {action, observable} from "mobx";
import {Auth} from "aws-amplify";
import {Button, Card, Col, Form, Input, message, Row} from "antd";
import {RouteComponentProps} from "react-router-dom";
import {UserStore} from "../../../stores/user/userStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {AuthStore} from "../../../stores/auth/authStore";

interface IProps extends RouteComponentProps {
    userStore?: UserStore
    authStore?: AuthStore
}

@inject(InjectNames.userStore, InjectNames.authStore)
@observer
export class SignUp extends React.Component<IProps> {
    @observable loading = false;
    @observable password = "";
    @observable email = "";
    @observable firstName = "";
    @observable lastName = "";
    @observable error = "";

    @action clearError = () => {
        this.error = ""
    }

    validateForm = () => {
        let isValid = true
        return isValid
    }

    handleSubmit = async (e: any) => {
        e.preventDefault();
        this.clearError()
        if (!this.validateForm()) {
            return;
        }
        let options: any = {
            password: this.password,
            username: this.email,
            attributes: {
                family_name: this.lastName,
                given_name: this.firstName,
            },
            validationData: [],
            clientMetadata: {},
        };
        try {
            this.loading = true
            await Auth.signUp(options)
            message.success({type: "success", content: "Success"})
            this.props.history.push('/')
        } catch (e) {
            this.loading = false
            this.error = e.message
        }
    };

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const span = {xs: 22, sm: 20, md: 12, lg: 8}
        const signUpForm = <Row style={{height: '100vh', justifyContent: 'center'}} type={'flex'}>
            <Col {...span} style={{alignSelf: 'center'}}>
                <Card title={'Sign up'}>
                    <div style={{textAlign: 'center'}}>
                        <div style={{color: "red"}}>{this.error}</div>
                    </div>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item>
                            <Input type="text" value={this.email}
                                   required={true}
                                   onChange={event => {
                                       this.email = event.target.value;
                                       this.clearError()
                                   }}
                                   placeholder={"Enter email"}/>
                        </Form.Item>
                        <Form.Item>
                            <Input type="password" value={this.password}
                                   required={true}
                                   onChange={event => {
                                       this.password = event.target.value;
                                       this.clearError()
                                   }}
                                   placeholder={"Enter password"}/>
                        </Form.Item>
                        <Form.Item>
                            <Input type="text" value={this.firstName}
                                   required={true}
                                   onChange={event => {
                                       this.firstName = event.target.value;
                                       this.clearError()
                                   }}
                                   placeholder={"Enter first name"}/>
                        </Form.Item>
                        <Form.Item>
                            <Input type="text" value={this.lastName}
                                   required={true}
                                   onChange={event => {
                                       this.lastName = event.target.value;
                                       this.clearError()
                                   }}
                                   placeholder={"Enter last name"}/>
                        </Form.Item>
                        <Row type={'flex'} justify={'space-between'}>
                            <Button htmlType={'button'} type={"default"} onClick={() => this.props.history.push('/')}
                                    loading={this.loading}>Back</Button>
                            <Button htmlType={'submit'} type={"primary"}
                                    loading={this.loading}>Sign Up</Button>
                        </Row>
                    </Form>
                </Card>
            </Col>
        </Row>

        return (
            <div>
                {signUpForm}
            </div>
        );
    }
}
