import React from "react";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../stores/initializeStores";
import {AnnotationStore} from "../../stores/annotationStore/annotationStore";
import {computed} from "mobx";
import {CollapseView} from "./CollapseView";

interface IProps {
    annotationStore?: AnnotationStore
    annotationId: number;
    sequence?: number;
    collapseContent: any
    collapseTitle: any
}

@inject(InjectNames.annotationStore)
@observer
export class CollapseParentAnnotation extends React.Component<IProps> {
    @computed get collapsed() {
            const isAnnotationSelected = this.props.annotationStore!.selectedAnnotations.findIndex(annotationId => (annotationId === this.props.annotationId)) !== -1
        return !isAnnotationSelected;
    }

    handleCollapse = () => {
        if (this.collapsed) {
            this.props.annotationStore!.selectedAnnotations = [...this.props.annotationStore!.selectedAnnotations, this.props.annotationId]
        } else {
            this.props.annotationStore!.selectedAnnotations = this.props.annotationStore!.selectedAnnotations.filter(x => x !== this.props.annotationId)
        }
    }

    render() {
        return <CollapseView collapsed={this.collapsed} collapseContent={this.props.collapseContent}
                             collapseTitle={this.props.collapseTitle} handleCollapse={this.handleCollapse}/>
    }
}
