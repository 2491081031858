import {IDisposable, RootStore} from "../initializeStores";
import {
    AccountStatus,
    AddResidentModel, DeviceOnlineStatus, Gender, GenderSameAsBirth,
    GetResidentsRequest,
    ProCvtClient,
    UpdateResidentModel
} from "../../services/api/ProCvtClient";
import {action, observable} from "mobx";
import {dateTimeHelper} from "../../services/utils/DateTimeHelper";

export class ResidentsStore implements IDisposable {
    constructor(pbmSleepClient: ProCvtClient, rootStore: RootStore) {
        this.pbmSleepClient = pbmSleepClient!;
        this.rootStore = rootStore;
    }

    private readonly pbmSleepClient: ProCvtClient;
    private readonly rootStore: RootStore;

    @observable residentsList: IResidentDto[] = []

    @action getEthnicityList: () => Promise<IEthnicityDto[]> = async () => {
        return (await this.pbmSleepClient.residents_GetEthnicityList())!
    }

    @action loadResidents = async (input: IGetResidentsInput) => {
        this.residentsList = (await this.pbmSleepClient.residents_GetResidents(new GetResidentsRequest(input)))!
    }
    @action getResidents = async (input: IGetResidentsInput) => {
        return (await this.pbmSleepClient.residents_GetResidents(new GetResidentsRequest(input)))!
    }

    @action getResidentDetails: (personId: number) => Promise<IResidentDetailsDto> = async (personId: number) => {
        return (await this.pbmSleepClient.residents_GetResidentDetails(personId))!
    }

    @action updateResident = async (input: IUpdateResidentInput) => {
        const request = new UpdateResidentModel(input)
        dateTimeHelper.overrideToISOStringWithTimezone(request.dateOfBirth)
        dateTimeHelper.overrideToISOStringWithTimezone(request.modifiedTime)
        await this.pbmSleepClient.residents_UpdateResident(new UpdateResidentModel(input))
    }

    @action addResident = async (input: IAddResidentInput) => {
        const request = new AddResidentModel(input)
        dateTimeHelper.overrideToISOStringWithTimezone(request.dateOfBirth)
        await this.pbmSleepClient.residents_AddResident(request)
    }

    @action getResidentsSummary: () => Promise<IResidentSummaryDto[]> = async () => {
        return (await this.pbmSleepClient.residents_GetResidentsSummary())!
    }

    dispose = async () => {
        this.residentsList = []
    };
}

export interface IResidentDto {
    personId?: number | undefined;
    userId?: number | undefined;
    userName?: string | undefined;
    email?: string | undefined;
    accountStatus?: AccountStatus | undefined;
    orgUnitId: number;
    roomId?: number | undefined;
    roomName?: string | undefined;
    lifestyleAnswered: boolean;
    healthAnswered: boolean;
    lastTraceTime?: Date | undefined;
    lastTraceId?: number | undefined;
}

export interface IGetResidentsInput {
    search?: string | undefined;
    orgUnitId?: number | undefined;
    offset: number;
    rowCount: number;
}

export interface IResidentDetailsDto {
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    dateOfBirth?: Date | undefined;
    ethnicityId: number;
    ethnicity?: string | undefined;
    gender: Gender;
    genderSameAsBirth: GenderSameAsBirth;
    roomId: number;
    roomName?: string | undefined;
    healthAnswered: boolean;
    lifestyleAnswered: boolean;
    modifiedTime?: Date | undefined;
}

export interface IUpdateResidentInput {
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    dateOfBirth?: Date | undefined;
    ethnicityId: number;
    gender: Gender;
    genderSameAsBirth: GenderSameAsBirth;
    roomId: number;
    personId: number;
    modifiedTime?: Date | undefined;
}

export interface IAddResidentInput {
    orgUnitId?: number;
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    dateOfBirth: Date;
    ethnicityId: number;
    gender: Gender;
    genderSameAsBirth: GenderSameAsBirth;
    roomId: number;
}

export interface IEthnicityDto {
    ethnicityId: number;
    ethnicity?: string | undefined;
}

export interface IResidentSummaryDto {
    personId?: number | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    deviceName?: string | undefined;
    deviceOnlineStatus?: DeviceOnlineStatus | undefined;
    roomName?: string | undefined;
    roomOccupied: boolean;
}